import { useContext, useEffect, useState } from "react";
import { INewPersonalHistory, IPregnancy } from "../../../../../libs/data-access/types";
import { PatientContext } from "../../../PatientsContext";
import { useSearchParams } from "react-router-dom";
import { useDirectusApi } from "../../../../../libs/data-access";
import { showToast } from "../../../../../utils/utility";

const DefaultHistory: INewPersonalHistory = {
  user_id: "",
  visit_id: "",
  state_of_residence: "",
  birth_state: "",
  residence_during_childhood: "",
  lives_in: "",
  marital_status: "",
  religion: "",
  ethnicity: "",
  no_of_adults_family_member: '',
  no_of_child_family_member: '',
  patient_education: "",
  patient_occupation: "",
  kuppuswamy_scale_education_of_the_head: "",
  kuppuswamy_scale_occupation_of_the_head: "",
  kuppuswamy_scale_updated_monthly_family_income: "",
  Socio_economic_class: "",
  vehicle: "",
  reproductive_h_per_o: [
    {
      menarche: '',
      menopause: '',
      regular: false,
      age_at_marriage: '',
      age_at_first_child_birth: '',
      age_at_last_child_birth: '',
      total_no_of_children: '',
      total_no_of_abortions: '',
      breast_feed_months: '',
      gynaecological_surgery: false,
      which_surgery: "",
      age_at_which_surgery_done: ''
    },
  ],
  pregnancies_history: [],
  anthropometric_measurement: [
    {
      height_cm: "-cm",
      weight_kg: "",
      waist_circumference: "-cm",
      hip_circumference_cm: "-cm",
      bmi_kg_per_m_square: "",
      blood_pressure: "",
      tooth_count: "",
      mid_arm_circumference_cm: "-cm",
      weight_height_ratio_kg_m_sqaure: "",
    }
  ],
  nearest_health_care_center: [],
  recruitment_method: "",
  water_source: ""
};

const DefaultNewPregnancy: IPregnancy = {
  duration_of_pregnancy: "",
  how_did_the_pregnancy_end: "",
  type_of_delivery: "",
  complications: "",
};

interface IAnthropometricUnits {
  height: string;
  weight: string;
  waist: string;
  hip: string;
}
export default function usePersonalHistory() {

  const {
    activeTab,
    setIsBtnLoading,
    isBtnNextClick,
    handleNextTab,
    isFormUpdating,
    setIsFormUpdating,
  } = useContext(PatientContext);

  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");
  const { postDirectData, fetchDirectData, patchDirectData } = useDirectusApi();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isOpenModalPregnancies, setIsOpenModalPregnancies] = useState<boolean>(false);
  const [personalHistory, setPersonalHistory] = useState<INewPersonalHistory>(DefaultHistory);
  const [newPregnancies, setNewPregnancies] = useState<IPregnancy>(DefaultNewPregnancy);
  const [selectedPregnancyIndex, setSelectedPregnancyIndex] = useState<number | undefined>(undefined);
  const [anthropometricUnits, setAnthropometricUnits] = useState<IAnthropometricUnits>({
    height: "cm",
    weight: "cm",
    waist: "cm",
    hip: "cm",
  });



  useEffect(() => {
    if (activeTab == "personal_history") {
      getDetailsByVisitId()
    }
  }, [activeTab])

  useEffect(() => {
    if (isBtnNextClick && activeTab === "personal_history") {
      handelSubmitPersonalHistory()
    }
  }, [isBtnNextClick])





  useEffect(() => {
    setPersonalHistory((prev) => (
      {
        ...prev,
        Socio_economic_class:
          String(
            (personalHistory?.kuppuswamy_scale_education_of_the_head ? +personalHistory?.kuppuswamy_scale_education_of_the_head?.split("+")[1] : 0)
            +
            (personalHistory?.kuppuswamy_scale_education_of_the_head ? +personalHistory?.kuppuswamy_scale_occupation_of_the_head?.split("+")[1] : 0)
            +
            (personalHistory?.kuppuswamy_scale_updated_monthly_family_income ? +personalHistory?.kuppuswamy_scale_updated_monthly_family_income?.split("+")[1] : 0)
          )
      }));

  }, [personalHistory?.kuppuswamy_scale_education_of_the_head, personalHistory?.kuppuswamy_scale_occupation_of_the_head, personalHistory?.kuppuswamy_scale_updated_monthly_family_income])

  //    HANDLER FUNCTION

  const handleChangeUnits = (key: keyof IAnthropometricUnits, value: string) => {
    setAnthropometricUnits((prev) => ({ ...prev, [key]: value }));
  }

  const calculateValueByUnit = (value: number, unit: string) => {
    if (unit?.toLowerCase() === "cm") {
      return value
    }
    else if (unit?.toLowerCase() === "inches") {
      const finalValue = value * 0.3937008
      return finalValue.toFixed(2)
    }
    else if (unit?.toLowerCase() === "feet") {
      const finalValue = value * 0.0328084
      return finalValue.toFixed(2)
    }
    return 0
  }
  const handelChangeHistory = (
    key:
      | keyof INewPersonalHistory,
    value: number | string | boolean | any[] | null,
    subKey?: string,
    index?: number
  ) => {
    if (subKey) {
      const data: any[] = personalHistory?.[key] as any[];
      const firstIndex = index ? data[index] : data[0];
       data[index ?? 0] = { ...firstIndex, [subKey]: value };
      setPersonalHistory((prev) => ({ ...prev, [key]: data }));
    } else {
      setPersonalHistory((prev) => ({ ...prev, [key]: value }));
    }

  };

  const handelSubmitPersonalHistory = () => {
    if (patient_id && visit_id) {
      let payload: INewPersonalHistory = {
        ...personalHistory,
        user_id: patient_id,
        visit_id: visit_id,
      };
      // const valid = handleValidatePersonalHistory(payload);
      // if (valid) {
      savePersonalHistory(payload);
      // }
    }
  };

  const handleChangePregnancies = (
    key: keyof IPregnancy | "default",
    value: number | string
  ) => {
    if (key === "default") {
      setNewPregnancies(DefaultNewPregnancy);
    } else {
      setNewPregnancies((prev) => ({ ...prev, [key]: value }));
    }
  };

  function calculateBMI(weight_kg: number, height_cm: number) {
    // Convert height from centimeters to meters
    var height_m = height_cm / 100;

    // Calculate BMI
    var bmi = weight_kg / (height_m * height_m);

    // Return BMI value
    handelChangeHistory(
      "anthropometric_measurement",
      bmi?.toFixed(2) + "",
      "bmi_kg_per_m_square"
    )
    // setTextInputs({
    //   ...textInputs,
    //   bmi_kg_per_m_square: bmi?.toFixed(2) + "",
    // });
  }

  function calculateWeightToHeightRatio(waist_cm: number, hip_cm: number) {
    var ratio = waist_cm / hip_cm;

    handelChangeHistory(
      "anthropometric_measurement",
      ratio?.toFixed(2) + "",
      "weight_height_ratio_kg_m_sqaure"
    )
    // setTextInputs({
    //   ...textInputs,
    //   weight_height_ratio_kg_m_sqaure: ratio?.toFixed(2) + "",
    // });
  }

  // APIFunction
  const getDetailsByVisitId = async () => {
    setIsLoading(true)
    const res = await fetchDirectData(`items/demographic_information?filter[visit_id][_eq]=${visit_id}`)
    if (res.isSuccess) {
      if (res?.data?.length) {
        setIsFormUpdating(true)
        let data: INewPersonalHistory = res?.data[0]
        setPersonalHistory(data);
      }
      else {
        setIsFormUpdating(false)
      }
    }
    else {
      showToast({
        type: "error",
        message: "Something went wrong"
      })
    }
    setIsLoading(false)
  }

  const savePersonalHistory = async (payload: INewPersonalHistory) => {
    setIsBtnLoading(true);
    if (isFormUpdating) {
      const res = await patchDirectData(`items/demographic_information/${personalHistory?.id}`, payload);
      if (res.isSuccess) {
        showToast({
          type: "success",
          title: "Updated successfully",
          message: "Patient personal history updated",
        });
        handleNextTab();
      } else {
        showToast({
          type: "error",
          message: res?.message || "Failed to add personal history",
        });
      }
    }
    else {
      const res = await postDirectData(`items/demographic_information`, payload);
      if (res.isSuccess) {
        showToast({
          type: "success",
          title: "Uploaded successfully",
          message: "Patient personal history added",
        });
        handleNextTab();
      } else {
        showToast({
          type: "error",
          message: res?.message || "Failed to add personal history",
        });
      }
    }
    setIsBtnLoading(false);
  };
  return {
    personalHistory,
    handelChangeHistory,
    isOpenModalPregnancies,
    setIsOpenModalPregnancies,
    newPregnancies,
    handleChangePregnancies,
    calculateBMI,
    calculateWeightToHeightRatio,
    anthropometricUnits,
    handleChangeUnits,
    calculateValueByUnit,
    selectedPregnancyIndex,
    setSelectedPregnancyIndex
  };
}
