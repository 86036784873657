import { useCallback, useMemo, useRef, useState } from "react";
import PageLayout from "../../PageLayout";
import {
  EButton,
  ECheckBox,
  EMultiSelect,
  ENumberInput,
  EPageTitle,
  ESectionDivider,
  ESelect,
  ETextInput,
} from "../../libs/ui";
import {
  Badge,
  Button,
  Grid,
  Group,
  Modal,
  Radio,
  TextInput,
  Textarea,
} from "@mantine/core";
import usePatientInformation from "./hook/usePatientInformation";
import { useNavigate } from "react-router-dom";
import {
  BsArrowLeft,
  BsArrowRepeat,
  BsCamera,
  BsDiscFill,
  BsEye,
  BsFilePdf,
  BsImage,
  BsInfoCircle,
} from "react-icons/bs";
import { statesMaster } from "../../libs/data-access/utility";
import {
  FiAlertCircle,
  FiCheckCircle,
  FiDownload,
  FiUpload,
} from "react-icons/fi";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { IoDocumentOutline } from "react-icons/io5";
import { CiCircleRemove } from "react-icons/ci";
import EPhoneInput from "../../libs/ui/src/NumberInput/EPhoneInput";
import EAgeInput from "../../libs/ui/src/NumberInput/EAgeInput";
import { EBadge } from "../../libs/ui/src/EBadge/EBadge";
import Webcam from "react-webcam";
import { MdArrowBack, MdCamera, MdCheck, MdOutlineClose } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import EDateInput from "../../libs/ui/src/DateInput/EDateInput";
import moment from "moment";

type IProps = {
  viewOnly?: boolean;
};

// const MemoizedPDFComponent = useMemo(({pdf}: any) => (
//     <object
//       data={URL.createObjectURL(pdf as Blob)}
//       type="application/pdf"
//       // width="100%"
//       // height="500px"
//       style={{ overflow: "hidden" }}
//       className="mt-3 h-60 w-full overflow-y-auto"
//     >
//       <p>
//         Your browser does not support PDF previews. Please download
//         the PDF to view it.
//       </p>
//     </object>
// ));

export default function PatientInformation({ viewOnly }: IProps) {
  const navigate = useNavigate();

  const {
    isBtnLoading,
    patientInfo,
    ageEligibility,
    error,
    pdf,
    userDetail,
    handleError,
    handleChangeInfo,
    handelSubmit,
    handleDownloadPDF,
    isOpenInfoModal,
    setPdf,
    uniqueNessCheck,
    isLoadingPinData,
    getDataByPinCode,
    setUniqueNessCheck,
    checkUniqueNumber,
    centerDetails,
    isOpenCameraModal,
    setIsOpenCameraModal,
    setInclusionStatus,
    inclusionStatus,
    handleUpdateInclusionStatus
  } = usePatientInformation();

  const [selectedLanguage, setSelectedLanguage] = useState<string>("English");

  const webcamRef: any = useRef(null);
  const [url, setUrl] = useState("");
  const videoConstraints = {
    facingMode: "environment",
    width: 540,
    height: 380,
    // aspectRatio: 1
  };

  const handleUserMediaError = useCallback((error: any) => {
    if (
      error.name === "OverconstrainedError" ||
      error.name === "NotFoundError"
    ) {
      // If the back camera is not found, fallback to the front camera
      alert(error);
      webcamRef.current.videoConstraints = {
        facingMode: "user",
        // Use the front camera
      };
      webcamRef.current.forceUpdate();
    }
  }, []);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUrl(imageSrc);
    console.log(imageSrc);
  }, [webcamRef]);


  return (
    <PageLayout
      header={
        <EPageTitle
          title="Add New Patient"
          end={
            <EButton
              className="btn-blue"
              size="md"
              onClick={() => {
                navigate("/index");
              }}
            >
              <BsArrowLeft className="" /> Back
            </EButton>
          }
        />
      }
      footer={
        <div className="w-full flex justify-end  p-2">
          <EButton
            className="btn-blue"
            size="md"
            loading={isBtnLoading}
            onClick={() => handelSubmit()}
          >
            Submit
          </EButton>
        </div>
      }
    >
      <Grid>
        <Grid.Col span={8} className="border-r p-5">
          <ESectionDivider title="Personal Details" />
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                size="md"
                required
                readOnly={viewOnly}
                error={error?.name}
                placeholder="First Name"
                value={patientInfo?.first_name?.trim()}
                onChange={(e) => {
                  handleError("name", "");
                  handleChangeInfo("first_name", e?.target?.value?.trim());
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                size="md"
                placeholder="Last Name"
                readOnly={viewOnly}
                value={patientInfo?.last_name}
                onChange={(e) =>
                  handleChangeInfo("last_name", e?.target?.value)
                }
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Email"
                size="md"
                placeholder="Email"
                readOnly={viewOnly}
                value={patientInfo?.email}
                onChange={(e) => {
                  handleChangeInfo("email", e?.target?.value);
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Radio.Group
                label="Gender"
                size="md"
                required
                value={patientInfo?.gender}
                onChange={(e) => handleChangeInfo("gender", e)}
              >
                <Group>
                  <Radio readOnly={viewOnly} label="Male" value="male" />
                  <Radio readOnly={viewOnly} label="Female" value="female" />
                  <Radio readOnly={viewOnly} label="Others" value="others" />
                </Group>
              </Radio.Group>
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                size="md"
                label="Father Name"
                readOnly={viewOnly}
                placeholder="Father Name"
                value={patientInfo?.father_name}
                onChange={(e) =>
                  handleChangeInfo("father_name", e?.target?.value)
                }
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                size="md"
                label="Mother Name"
                readOnly={viewOnly}
                placeholder="Mother Name"
                value={patientInfo?.mother_name}
                onChange={(e) =>
                  handleChangeInfo("mother_name", e?.target?.value)
                }
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <EAgeInput
                size="md"
                label="Age"
                required
                min={0}
                readOnly={viewOnly}
                error={error?.age}
                maxLength={3}
                placeholder="Age"
                value={patientInfo?.age || ""}
                onChange={(e) => {
                  handleError("age", "");
                  handleChangeInfo("age", e);
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <EDateInput
                size="md"
                label="Date of Recruitment"
                placeholder="Select Recruitment date"
                value={
                  patientInfo?.recruitment_date ? new Date(patientInfo?.recruitment_date) : undefined
                }
                onChange={(value) => {
                  handleChangeInfo(
                    "recruitment_date",
                    moment(value).format("YYYY-MM-DD")
                  );
                }}
              />

            </Grid.Col>
          </Grid>

          <ESectionDivider classes="mt-8" title="Address Details" />
          <Textarea
            className="my-2"
            required
            label="Address"
            error={error?.address}
            readOnly={viewOnly}
            size="md"
            minRows={3}
            value={patientInfo?.address}
            onChange={(e) => {
              handleError("address", "");
              handleChangeInfo("address", e?.target?.value);
            }}
          />
          <Grid>
            <Grid.Col span={6}>
              <ETextInput
                size="md"
                label="House No."
                readOnly={viewOnly}
                error={error?.houseNo}
                required
                placeholder="House No."
                value={patientInfo?.house_no}
                onChange={(e) => {
                  handleError("houseNo", "");
                  handleChangeInfo("house_no", e?.target?.value);
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                size="md"
                label="Street Name"
                readOnly={viewOnly}
                placeholder="Locality"
                error={error?.locality}
                value={patientInfo?.locality}
                onChange={(e) => {
                  handleError("locality", "");
                  handleChangeInfo("locality", e?.target?.value);
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <ENumberInput
                size="md"
                label="PIN Code"
                readOnly={viewOnly}
                required
                hideControls
                maxLength={6}
                error={error?.postalCode}
                placeholder="PIN Code"
                value={patientInfo?.pin_code || ""}
                onChange={(e) => {
                  handleError("postalCode", "");
                  handleChangeInfo("pin_code", e);
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                size="md"
                label="Village/Town/City"
                readOnly={viewOnly}
                required
                placeholder="City"
                error={error?.city}
                value={patientInfo?.city}
                onChange={(e) => {
                  handleError("city", "");
                  handleChangeInfo("city", e?.target?.value);
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <ESelect
                size="md"
                label="State/UT"
                readOnly={viewOnly}
                data={statesMaster}
                required
                placeholder="State/UT"
                error={error?.state}
                value={patientInfo?.state}
                onChange={(e) => {
                  handleError("state", "");
                  handleChangeInfo("state", e);
                }}
              />
            </Grid.Col>
          </Grid>

          <ESectionDivider classes="mt-8" title="Contact Details" />
          <Grid>
            <Grid.Col span={6}>
              <EPhoneInput
                size="md"
                label="Mobile No."
                hideControls
                readOnly={viewOnly}
                required
                className="mt-2"
                error={error?.mobileNo}
                placeholder=" Mobile No."
                value={patientInfo?.primary_contact_no || ""}
                rightSection={
                  uniqueNessCheck?.mobileNo == "notUnique" ? (
                    <FiAlertCircle size={22} color="red" />
                  ) : uniqueNessCheck?.mobileNo?.toLowerCase() == "unique" ? (
                    <FiCheckCircle size={22} color="green" />
                  ) : (
                    <></>
                  )
                }
                // icon={"+91"}
                maxLength={10}
                onChange={(e) => {
                  handleChangeInfo("primary_contact_no", e);
                  if (String(e).length == 10) {
                    checkUniqueNumber(e, "mobileNo");
                  } else {
                    setUniqueNessCheck({
                      ...uniqueNessCheck,
                      mobileNo: "not_checked",
                    });
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <EPhoneInput
                hideControls
                size="md"
                label="Alternate Mobile No."
                readOnly={viewOnly}
                required
                className="mt-2"
                error={error?.alternateNo}
                placeholder="Alternate Mobile No."
                value={patientInfo?.alternate_contact_no || ""}
                rightSection={
                  uniqueNessCheck?.alternateNo == "notUnique" ? (
                    <FiAlertCircle size={22} color="red" />
                  ) : uniqueNessCheck?.alternateNo?.toLowerCase() ==
                    "unique" ? (
                    <FiCheckCircle size={22} color="green" />
                  ) : (
                    <></>
                  )
                }
                // icon={"+91"}
                maxLength={10}
                onChange={(e) => {
                  // handleError("alternateNo", "");
                  handleChangeInfo("alternate_contact_no", e);
                  if (String(e).length == 10) {
                    checkUniqueNumber(e, "alternateNo");
                  } else {
                    setUniqueNessCheck({
                      ...uniqueNessCheck,
                      alternateNo: "not_checked",
                    });
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <EPhoneInput
                hideControls
                // required
                size="md"
                label="Secondary Alternate Mobile No."
                readOnly={viewOnly}
                error={error?.secondaryNo}
                className="mt-2"
                placeholder=" Mobile No."
                value={patientInfo?.secondary_alternate_contact_no || ""}
                rightSection={
                  uniqueNessCheck?.secondaryNo == "notUnique" ? (
                    <FiAlertCircle size={22} />
                  ) : uniqueNessCheck?.secondaryNo?.toLowerCase() ==
                    "unique" ? (
                    <FiCheckCircle size={22} color="green" />
                  ) : (
                    <></>
                  )
                }
                maxLength={10}
                onChange={(e) => {
                  handleError("secondaryNo", "");
                  handleChangeInfo("secondary_alternate_contact_no", e);
                  if (String(e).length == 10) {
                    checkUniqueNumber(e, "secondaryNo");
                  } else {
                    setUniqueNessCheck({
                      ...uniqueNessCheck,
                      secondaryNo: "not_checked",
                    });
                  }
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <ETextInput
                size="md"
                label="Instant Message"
                readOnly={viewOnly}
                maxLength={10}
                className="mt-2"
                placeholder="eg. Whatsapp No."
                value={patientInfo?.whatsapp_no}
                onChange={(e) =>
                  handleChangeInfo("whatsapp_no", e.target.value)
                }
              />
            </Grid.Col>
          </Grid>

          <ESectionDivider classes="mt-8" title="Additional Details" />
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                size="md"
                label="Hospital No."
                readOnly={viewOnly}
                required
                error={error?.HospitalNo}
                placeholder="Hospital No."
                value={patientInfo?.hospital_no}
                onChange={(e) => {
                  handleError("HospitalNo", "");
                  handleChangeInfo("hospital_no", e?.target?.value);
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                size="md"
                readOnly={viewOnly}
                label="Admission No."
                placeholder="Admission No."
                value={patientInfo?.admission_no}
                onChange={(e) =>
                  handleChangeInfo("admission_no", e?.target?.value)
                }
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                size="md"
                label="Ward No."
                placeholder="Ward No."
                readOnly={viewOnly}
                value={patientInfo?.ward_no}
                onChange={(e) => handleChangeInfo("ward_no", e?.target?.value)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                size="md"
                label="Bed No."
                placeholder="Bed No."
                readOnly={viewOnly}
                value={patientInfo?.bed_no}
                onChange={(e) => handleChangeInfo("bed_no", e?.target?.value)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <ESelect
                size="md"
                label="Physician In-charge"
                placeholder="Select"
                nothingFoundMessage="No Doctor Found"
                data={
                  centerDetails?.physician_in_charge
                    ? centerDetails?.physician_in_charge.split(",")
                    : []
                }
                readOnly={viewOnly}
                value={patientInfo?.physician_in_charge}
                onChange={(e) => handleChangeInfo("physician_in_charge", e)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <ESelect
                size="md"
                label="Surgeon In-charge"
                nothingFoundMessage="No Doctor Found"
                placeholder="Select"
                data={
                  centerDetails?.surgeon
                    ? centerDetails?.surgeon?.split(",")
                    : []
                }
                readOnly={viewOnly}
                value={patientInfo?.surgeon_in_charge}
                onChange={(e) => handleChangeInfo("surgeon_in_charge", e)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <ESelect
                size="md"
                label="Radiology In-charge"
                nothingFoundMessage="No Doctor Found"
                placeholder="Select"
                data={
                  centerDetails?.radiologist
                    ? centerDetails?.radiologist?.split(",")
                    : []
                }
                readOnly={viewOnly}
                value={patientInfo?.radiology_in_charge}
                onChange={(e) => handleChangeInfo("radiology_in_charge", e)}
              />
            </Grid.Col>
          </Grid>

          <ESectionDivider
            classes="mt-8"
            title="Screening for Eligibility for GS"
          />

          <Grid>
            <Grid.Col span={6}>
              <Radio.Group
                label="Ultrasound evidence of GS"
                size="md"
                value={patientInfo?.ultrasound_evidence_of_gs}
                onChange={(e) =>
                  handleChangeInfo("ultrasound_evidence_of_gs", e)
                }
              >
                <Group>
                  <Radio readOnly={viewOnly} label="Yes" value="yes" />
                  <Radio readOnly={viewOnly} label="No" value="no" />
                </Group>
              </Radio.Group>
            </Grid.Col>
          </Grid>

          <ESectionDivider
            classes="mt-8"
            title="Screening for Eligibility for GBC"
          />

          <Grid>
            <Grid.Col span={6}>
              <Radio.Group
                label="Ultrasound evidence of GBC"
                size="md"
                value={patientInfo?.ultrasound_evidence_of_gbc}
                onChange={(e) =>
                  handleChangeInfo("ultrasound_evidence_of_gbc", e)
                }
              >
                <Group>
                  <Radio readOnly={viewOnly} label="Yes" value="yes" />
                  <Radio readOnly={viewOnly} label="No" value="no" />
                </Group>
              </Radio.Group>
            </Grid.Col>

            <Grid.Col span={6}>
              <Radio.Group
                label="Definitive Diagnosis"
                size="md"
                value={
                  patientInfo?.definitive_diagnosis === "no"
                    ? "no"
                    : patientInfo?.definitive_diagnosis
                      ? "yes"
                      : ""
                }
                onChange={(e) => {
                  if (e === "yes") {
                    if (!patientInfo?.definitive_diagnosis?.includes("yes")) {
                      handleChangeInfo("definitive_diagnosis", e);
                    }
                  } else if (e === "no") {
                    handleChangeInfo("definitive_diagnosis", e);
                  }
                }}
              >
                <Group>
                  <Radio readOnly={viewOnly} label="Yes" value="yes" />
                  <Radio readOnly={viewOnly} label="No" value="no" />
                </Group>
              </Radio.Group>
              {patientInfo?.definitive_diagnosis?.includes("yes") && (
                <Group className="mt-4">
                  <ECheckBox
                    label="Histology"
                    readOnly={viewOnly}
                    checked={patientInfo?.definitive_diagnosis?.includes(
                      "histology"
                    )}
                    onChange={(e) => {
                      // if (e.target.checked) {
                      if (
                        !patientInfo?.definitive_diagnosis?.includes(
                          "histology"
                        )
                      ) {
                        handleChangeInfo(
                          "definitive_diagnosis",
                          patientInfo?.definitive_diagnosis?.concat(
                            ",histology"
                          )
                        );
                      } else {
                        let data =
                          patientInfo?.definitive_diagnosis?.split(",");
                        handleChangeInfo(
                          "definitive_diagnosis",
                          data
                            ?.filter((item) => item !== "histology")
                            ?.join(",")
                        );
                      }
                      // }
                      // else{}
                    }}
                  />
                  <ECheckBox
                    label="Cytology"
                    readOnly={viewOnly}
                    checked={patientInfo?.definitive_diagnosis?.includes(
                      "cytology"
                    )}
                    onChange={(e) => {
                      if (
                        !patientInfo?.definitive_diagnosis?.includes("cytology")
                      ) {
                        handleChangeInfo(
                          "definitive_diagnosis",
                          patientInfo?.definitive_diagnosis?.concat(",cytology")
                        );
                      } else {
                        let data =
                          patientInfo?.definitive_diagnosis?.split(",");
                        handleChangeInfo(
                          "definitive_diagnosis",
                          data?.filter((item) => item !== "cytology")?.join(",")
                        );
                      }
                    }}
                  />
                  <ECheckBox
                    label="PET"
                    readOnly={viewOnly}
                    checked={patientInfo?.definitive_diagnosis?.includes("PET")}
                    onChange={(e) => {
                      if (!patientInfo?.definitive_diagnosis?.includes("PET")) {
                        handleChangeInfo(
                          "definitive_diagnosis",
                          patientInfo?.definitive_diagnosis?.concat(",PET")
                        );
                      } else {
                        let data =
                          patientInfo?.definitive_diagnosis?.split(",");
                        handleChangeInfo(
                          "definitive_diagnosis",
                          data?.filter((item) => item !== "PET")?.join(",")
                        );
                      }
                    }}
                  />
                  <ECheckBox
                    label="CECT"
                    readOnly={viewOnly}
                    checked={patientInfo?.definitive_diagnosis?.includes(
                      "CECT"
                    )}
                    onChange={(e) => {
                      if (
                        !patientInfo?.definitive_diagnosis?.includes("CECT")
                      ) {
                        handleChangeInfo(
                          "definitive_diagnosis",
                          patientInfo?.definitive_diagnosis?.concat(",CECT")
                        );
                      } else {
                        let data =
                          patientInfo?.definitive_diagnosis?.split(",");
                        handleChangeInfo(
                          "definitive_diagnosis",
                          data?.filter((item) => item !== "CECT")?.join(",")
                        );
                      }
                    }}
                  />
                  <ECheckBox
                    label="US"
                    readOnly={viewOnly}
                    checked={patientInfo?.definitive_diagnosis?.includes("US")}
                    onChange={(e) => {
                      if (!patientInfo?.definitive_diagnosis?.includes("US")) {
                        handleChangeInfo(
                          "definitive_diagnosis",
                          patientInfo?.definitive_diagnosis?.concat(",US")
                        );
                      } else {
                        let data =
                          patientInfo?.definitive_diagnosis?.split(",");
                        handleChangeInfo(
                          "definitive_diagnosis",
                          data?.filter((item) => item !== "US")?.join(",")
                        );
                      }
                    }}
                  />
                </Group>
              )}
            </Grid.Col>
          </Grid>

          <ESectionDivider classes="mt-8" title="Disease Details" />
          <Grid>
            <Grid.Col span={6}>
              <EMultiSelect
                label="Disease Type"
                placeholder="Select Disease"
                required
                error={error?.disease}
                size="md"
                data={[
                  {
                    label: "Asymptomatic Gallstone",
                    value: "asymptomatic_gallstone",
                  },
                  {
                    label: "Symptomatic Gallstone",
                    value: "symptomatic_gallstone",
                  },
                  { label: "Gallbladder Cancer", value: "gallbladder_cancer" },
                  {
                    label: "Non Gallstone Control",
                    value: "non_gallstone_control",
                  },
                ]}
                value={patientInfo?.disease ?? null}
                onChange={(e) => {
                  handleError("disease", "");
                  handleChangeInfo("disease", e);
                }}
              />
            </Grid.Col>

            <Grid.Col span={12}>
              <div className="flex flex-row gap-4 mt-4">
                <ECheckBox
                  label="Severe co-morbidities"
                  readOnly={viewOnly}
                  checked={patientInfo?.severe_comorbidities === "yes"}
                  className="mt-2"
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleChangeInfo("severe_comorbidities", "yes");
                    } else {
                      handleChangeInfo("severe_comorbidities", "no");
                    }
                  }}
                />
                <ECheckBox
                  label="Unwilling for FU"
                  readOnly={viewOnly}
                  className="mt-2"
                  checked={patientInfo?.unwilling_for_fu === "yes"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleChangeInfo("unwilling_for_fu", "yes");
                    } else {
                      handleChangeInfo("unwilling_for_fu", "no");
                    }
                  }}
                />
              </div>
            </Grid.Col>
          </Grid>
        </Grid.Col>

        <Grid.Col span={4} className="p-5">
          <ESectionDivider title="Consent Form" />
          <div className="bg-slate-100 m-2 p-3 mt-5 rounded-md">
            <div className="text-base text-slate-800 font-semibold">
              {" "}
              📥 Download Consent Form:
            </div>
            <div className="text-sm font-normal text-slate-500 my-2">
              Tap the button below to instantly download the consent form in
              your selected language.
            </div>

            <ESelect
              value={selectedLanguage}
              data={["English", "Hindi", "Urdu", "Bangla", "Malayalam"]}
              onChange={(e) => {
                setSelectedLanguage(e ?? "English");
              }}
            />
            <div className="mt-4 w-full flex justify-center">
              <EButton
                size="md"
                onClick={() => {
                  handleDownloadPDF(selectedLanguage);
                }}
              >
                <FiDownload /> Download
              </EButton>
            </div>
          </div>

          {pdf || url ? (
            <div className="flex justify-between rounded-lg bg-slate-100 p-4 m-2">
              <div className="flex gap-1">
                {pdf ? <BsFilePdf /> : <BsImage />}
                <p className="font-semibold">Consent Form Uploaded</p>
              </div>
              <Button
                variant="light"
                onClick={() => {
                  window.open(URL.createObjectURL(pdf), '_blank');
                }}
              >
                <BsEye size={16} className="mr-2" /> Preview
              </Button>
              <Button
                className="ml-2"
                variant="light"
                onClick={() => {
                  setPdf(null);
                }}
              >
                <CiCircleRemove size={16} className="mr-2" /> Clear
              </Button>
            </div>
          ) : (
            <>
              <Dropzone
                className="m-2 mt-4"
                accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                onDrop={function (files: FileWithPath[]): void {
                  setPdf(files[0]);
                }}
              >
                <div className="flex justify-center flex-col items-center text-center">
                  <FiUpload size={33} />

                  <p>Upload consent form</p>
                  <p className="text-xs text-slate-500 ">Image / PDF</p>
                </div>
              </Dropzone>
              <div></div>
            </>
          )}
        </Grid.Col>
      </Grid>

      <Modal
        title="Eligibility Status"
        opened={isOpenInfoModal}
        size="xl"
        onClose={() => {
          window.location.reload()
        }}
        closeOnClickOutside={false}
      >
        <div className="bg-slate-100 p-3 rounded-md">
          <div className="border-b border-slate-300 mb-4 pb-4">
            <div className="flex flex-row items-center justify-between mb-4">
              <div className="text-green-700 font-semibold text-2xl">
                Screening for Eligibility for GS
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <ECheckBox label="Informed consent" checked={pdf} disabled />
              <ECheckBox
                label="Ultrasound evidence of GS"
                checked={patientInfo?.ultrasound_evidence_of_gs === "yes"}
                disabled
              />
              <ECheckBox
                label="Age (18 - 80) years"
                checked={ageEligibility}
                disabled
              />
            </div>
          </div>
          <div>
            <div className="flex flex-row items-center justify-between mb-4">
              <div className="text-green-700 font-semibold text-2xl">
                Screening for Eligibility for GBC
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <ECheckBox label="Informed consent" checked={pdf} disabled />
              <ECheckBox
                label="Ultrasound evidence of GS"
                checked={patientInfo?.ultrasound_evidence_of_gs === "yes"}
                disabled
              />
              <ECheckBox
                label="Age (18 - 80) years"
                checked={ageEligibility}
                disabled
              />
              <ECheckBox
                label="Definitive Diagnosis"
                checked={patientInfo?.definitive_diagnosis?.includes("yes")}
                disabled
              />
              <ECheckBox
                label="Severe co-morbidities"
                checked={patientInfo?.severe_comorbidities === "yes"}
                disabled
              />
              <ECheckBox
                label="Unwilling for FU"
                checked={patientInfo?.unwilling_for_fu === "yes"}
                disabled
              />
            </div>
          </div>
        </div>

        <div className='flex justify-end gap-3 mt-3'>
          <ECheckBox
            label="Include this patient in the study"
            checked={inclusionStatus === "included"}
            onChange={(e) => {
              setInclusionStatus(inclusionStatus === "included" ? "" : "included");
            }}
          />


          <ECheckBox
            label="Exclude this patient from the study"
            checked={inclusionStatus === "excluded"}
            onChange={(e) => {
              setInclusionStatus(inclusionStatus === "excluded" ? "" : "excluded");
            }}
          />
        </div>

        <div className="flex justify-end mt-5 border-t pt-3">
          <EButton
            className="btn-blue"
            onClick={() => {
              handleUpdateInclusionStatus();
            }}
          >
            Continue
          </EButton>
        </div>
      </Modal>

    </PageLayout>
  );
}
