import { LoadingOverlay } from "@mantine/core";
import { ETextArea } from "../../../../../libs/ui";

import useClinicalHistoryForm from "../hooks/useClinicalHistoryForm";

export default function ClinicalHistoryForm() {
  const {
    newClinicalHistory,
    handleChangeClinicalHistory
  } = useClinicalHistoryForm()

  return (
    <>
      {/* <h3 className="mb-5 sticky">Clinical History</h3> */}
      {/* <LoadingOverlay visible={isLoading} 
          loaderProps={{ color: "blue", type: 'bars' }} /> */}
      <ETextArea
       label="Describe Remarks"
       className="w-1/2"
       size="md"
       rows={5}
       placeholder="Enter clinic history remarks"
       value={newClinicalHistory?.visit_remarks || ""}
       onChange={(e)=>handleChangeClinicalHistory("visit_remarks",e?.target?.value)}
      />
    </>
  );
}
