import { NumberInput, NumberInputProps, TextInput, TextInputProps } from '@mantine/core'
import React from 'react'

export function ENumberInput(props: NumberInputProps) {
    return (
        <NumberInput
            label={props.label}
            size={props.size}
            required={props.required}
            className={props.className}
            description={props.description}
            hideControls={props.hideControls ? props.hideControls : true }
            value={props.value || ""}
            max={props.max }
            min={props.min ? props.min :  0}
            maxLength={props.maxLength ? props.maxLength : 100}
            placeholder={props.placeholder}
            error={props.error}
            right={props.right}
            onChange={props.onChange}
            {...props}
        />
    )
}
