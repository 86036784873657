import { useContext, useEffect, useState } from "react";
import {
  IFoodConsumption,
  IFoodType,
  IMealType,
  IPayloadForPatientDietaryInformation,
  ITypesSource,
} from "../../../../../types";
import { useSearchParams } from "react-router-dom";
import { PatientContext } from "../../../PatientsContext";
import { showToast } from "../../../../../utils/utility";
import { useDirectusApi } from "../../../../../libs/data-access";
import { DefaultINewDiet } from "../../../../../DefaultValue";

export default function useDietaryDeterminants() {
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");
  const { postDirectData, fetchDirectData, patchDirectData } = useDirectusApi();

  const {
    isBtnNextClick,
    activeTab,
    setIsBtnLoading,
    handleNextTab,
    isFormUpdating,
    setIsFormUpdating,
  } = useContext(PatientContext);

  const [isOpenNewDietModal, setIsOpenNewDietModal] = useState<boolean>(false);
  const [newDiet, setNewDiet] = useState<IMealType>(DefaultINewDiet);
  const [dailyConsumptionOfFood, setDailyConsumptionOfFood] = useState<
    IMealType[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dietaryDeterminantsId, setDietaryDeterminantsId] = useState<
    number | undefined
  >(undefined);

  const [diet, setDiet] = useState<
    {
      label: string;
      value: number | null;
      selected: boolean;
      frequency: string;
      quantity: string;
      type?: string;
    }[]
  >([
    {
      label: "Vegan",
      value: 1,
      selected: false,
      frequency: "0",
      quantity: "0",
    },
    {
      label: "Lacto-Vegetarian",
      value: 2,
      selected: false,
      frequency: "0",
      quantity: "0",
    },
    {
      label: "Eggs",
      value: 3,
      selected: false,
      frequency: "0",
      quantity: "0",
    },
    {
      label: "Non-Vegetarian",
      value: 4,
      selected: false,
      frequency: "0",
      quantity: "0",
    },
  ]);

  const [stapleDiet, setStapleDiet] = useState<
    {
      label: string;
      value: number;
      selected: boolean;
      remarks: string;
    }[]
  >([
    { label: "Rice", value: 1, selected: false, remarks: "" },
    { label: "Wheat", value: 2, selected: false, remarks: "" },
    { label: "Both", value: 3, selected: false, remarks: "" },
    { label: "Others", value: 4, selected: false, remarks: "" },
  ]);

  const [typeOfFats, setTypeOfFats] = useState<
    {
      label: string;
      value: string;
      selected: boolean;
    }[]
  >([
    { label: "Mustard oil", value: "", selected: false },
    { label: "Butter", value: "", selected: false },
    { label: "Desi ghee", value: "", selected: false },
    { label: "Others", value: "", selected: false },
  ]);

  const [textInputs, setTextInputs] = useState({
    totalAmountOfOil: "0",
  });

  const [useOfRepeatedlyHeatedOil, setUseOfRepeatedlyHeatedOil] = useState<
    {
      label: string;
      value: number;
      selected: boolean;
    }[]
  >([
    { label: "Occasionally", value: 1, selected: false },
    { label: "Frequently", value: 2, selected: false },
    { label: "Rare", value: 3, selected: false },
    { label: "Never", value: 4, selected: false },
  ]);
  const [sourceOfFood, setSourceOfFood] = useState<
    {
      label: string;
      value: number;
      selected: boolean;
    }[]
  >([
    {
      label: "Supermarket",
      value: 1,
      selected: false,
    },
    {
      label: "Self-produced & stored at home",
      value: 2,
      selected: false,
    },
    {
      label: "Clean Kirana Store",
      value: 3,
      selected: false,
    },
    {
      label: "Farm fresh",
      value: 4,
      selected: false,
    },
    {
      label: "Poorly maintained Kirana Store",
      value: 5,
      selected: false,
    },
  ]);

  const [storage, setStorage] = useState<
    {
      label: string;
      value: number;
      selected: boolean;
    }[]
  >([
    {
      label: "Excellent",
      value: 1,
      selected: false,
    },
    {
      label: "Very Good",
      value: 2,
      selected: false,
    },
    {
      label: "Good",
      value: 3,
      selected: false,
    },
    {
      label: "Poor",
      value: 4,
      selected: false,
    },
    {
      label: "Very Poor",
      value: 5,
      selected: false,
    },
  ]);

  const [weeklyFoodConsumption, setWeeklyFoodConsumption] = useState<
    IFoodType[]
  >([
    {
      label: "Fruits",
      noOfTimes: "",
    },
    {
      label: "Biscuits",
      noOfTimes: "",
    },
    {
      label: "Namkeen",
      noOfTimes: "",
    },
    {
      label: "Fast food",
      noOfTimes: "",
    },
    {
      label: "Fried food",
      noOfTimes: "",
    },
  ]);

  const [types, setTypes] = useState<ITypesSource[]>([
    {
      label: "Source of Cereals",
      source: "",
      is_packed: "",
    },
    {
      label: "Source of Spices",
      source: "",
      is_packed: "",
    },
    {
      label: "Source of Oils",
      source: "",
      is_packed: "",
    },
    {
      label: "Source of Nuts",
      source: "",
      is_packed: "",
    },
    {
      label: "Source of Wheat",
      source: "",
      is_packed: "",
    },
  ]);

  {
    /* food Consumption state */
  }
  const [redChili, setRedChili] = useState<IFoodConsumption>(
    {} as IFoodConsumption
  );
  const [greenChili, setGreenChili] = useState<IFoodConsumption>(
    {} as IFoodConsumption
  );
  const [peanut, setPeanut] = useState<IFoodConsumption>(
    {} as IFoodConsumption
  );
  const [coffee, setCoffee] = useState<IFoodConsumption>(
    {} as IFoodConsumption
  );
  const [tea, setTea] = useState<IFoodConsumption>({} as IFoodConsumption);

  useEffect(() => {
    if (isBtnNextClick && activeTab === "dietary_determinate") {
      handleSubmitDietaryDeterminants();
    }
  }, [isBtnNextClick]);

  useEffect(() => {
    if (activeTab == "dietary_determinate") {
      getDetailsByVisitId();
    }
  }, [activeTab]);

  console.log("welkfnjnijnejfrjen f", weeklyFoodConsumption);
  const handlePayloadForDietaryDeterminantsForm = async (
    userId: string,
    visit_id: string
  ) => {
    let payload: IPayloadForPatientDietaryInformation = {
      user_id: userId,
      visit_id: visit_id,
      diet: diet
        .filter((item) => item.selected)
        .map((item) => ({
          diet: item.label,
          frequency: item.frequency,
          quantity: item.quantity,
          type_of_food: item.type ?? "",
        })),
      staple_diet:
        stapleDiet.find((item) => item.selected)?.label == "Others"
          ? stapleDiet.find((item) => item.selected)?.remarks ?? ""
          : stapleDiet.find((item) => item.selected)?.label || "",
      type_of_fats:
        typeOfFats
          .filter((item) => item.selected)
          .map((e) => ({ name: e?.label, quantity_ml_per_month: e?.value })) ||
        "",
      Total_amount_of_oil_per_person_per_month: textInputs.totalAmountOfOil,
      use_of_repeatedly_heated_oil:
        useOfRepeatedlyHeatedOil.find((item) => item.selected)?.label || "",
      daily_consumption_of_food: dailyConsumptionOfFood.map((item) => ({
        time: item.time ?? "",
        meal: item.meal,
        what: item.howMuch,
        how_much: item.howMuch,
        quantity: item.quantity,
      })),
      source_of_food:
        sourceOfFood
          .filter((item) => item.selected)
          .map((e) => e?.label)
          .join(",") || "",
      food_storage:
        storage
          .find((item) => item.selected)
          ?.label?.toLowerCase()
          ?.replaceAll(" ", "_") ?? "",
      weekly_food_consumption: weeklyFoodConsumption?.map((item) => ({
        food_items: item.label,
        no_of_times_a_week: +item.noOfTimes,
      })),
      types: types,
      food_red_chili: [redChili],
      food_green_chili: [greenChili],
      food_peanuts: [peanut],
      food_coffee: [coffee],
      food_tea: [tea],
    };
    return payload;
  };

  //  APi Function

  const getDetailsByVisitId = async () => {
    setIsLoading(true);
    const res = await fetchDirectData(
      `items/dietary_determinants?filter[visit_id][_eq]=${visit_id}`
    );
    if (res.isSuccess) {
      if (res?.data?.length) {
        setIsFormUpdating(true);
        let data: IPayloadForPatientDietaryInformation = res?.data[0];
        setDietaryDeterminantsId(data?.id);

        if (data?.diet?.length) {
          let stateData = diet;
          data?.diet?.forEach((e) => {
            const index = stateData.findIndex(
              (each) =>
                each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                e?.diet?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].selected = true;
            stateData[index].frequency = e?.frequency;
            stateData[index].quantity = e?.quantity;
            stateData[index].type = e?.type_of_food;
          });
          setDiet(stateData);
        }
        if (data?.staple_diet?.length) {
          let stateData = stapleDiet;
          const index = stateData.findIndex(
            (each) =>
              each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
              data?.staple_diet?.toLowerCase()?.replaceAll(" ", "_")
          );
          if (data?.staple_diet?.length && index == -1) {
            const otherIndex = stateData.findIndex(
              (each) =>
                each?.label?.toLowerCase()?.replaceAll(" ", "_") == "others"
            );
            stateData[otherIndex].selected = true;
            stateData[otherIndex].remarks = data?.staple_diet ?? "";
          } else {
            stateData[index].selected = true;
            stateData[index].remarks = data?.staple_diet ?? "";
          }
          setStapleDiet(stateData);
        }
        if (data?.type_of_fats?.length) {
          let stateData = typeOfFats;
          data?.type_of_fats?.forEach((e) => {
            const index = stateData.findIndex(
              (each) =>
                each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                e?.name?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].selected = true;
            stateData[index].value = e?.quantity_ml_per_month;
          });
          setTypeOfFats(stateData);
        }
        if (data?.use_of_repeatedly_heated_oil?.length) {
          let stateData = useOfRepeatedlyHeatedOil;
          const index = stateData.findIndex(
            (each) =>
              each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
              data?.use_of_repeatedly_heated_oil
                ?.toLowerCase()
                ?.replaceAll(" ", "_")
          );
          stateData[index].selected = true;
          setUseOfRepeatedlyHeatedOil(stateData);
        }

        setDailyConsumptionOfFood(
          data?.daily_consumption_of_food?.map((e) => ({
            meal: e?.meal,
            what: e?.what,
            howMuch: e?.how_much,
            quantity: e?.quantity,
            time: e?.time ?? "",
          }))
        );

        if (data?.source_of_food?.length) {
          let stateData = sourceOfFood;
          data?.source_of_food.split(",")?.forEach((e) => {
            const index = stateData.findIndex(
              (each) =>
                each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                e?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].selected = true;
          });
          setSourceOfFood(stateData);
        }
        if (data?.food_storage?.length) {
          let stateData = storage;
          const index = stateData.findIndex(
            (each) =>
              each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
              data?.food_storage?.toLowerCase()?.replaceAll(" ", "_")
          );
          stateData[index].selected = true;
          setStorage(stateData);
        }
        if (data?.weekly_food_consumption?.length) {
          let stateData = weeklyFoodConsumption;
          data?.weekly_food_consumption?.forEach((e) => {
            const index = stateData.findIndex(
              (each) =>
                each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                e?.food_items?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].noOfTimes = String(e?.no_of_times_a_week);
          });
          setWeeklyFoodConsumption(stateData);
        }
        if (data?.food_red_chili?.length) {
          setRedChili(data?.food_red_chili[0]);
        }
        if (data?.food_green_chili?.length) {
          setGreenChili(data?.food_green_chili[0]);
        }
        if (data?.food_peanuts?.length) {
          setPeanut(data?.food_peanuts[0]);
        }
        if (data?.food_tea?.length) {
          setTea(data?.food_tea[0]);
        }
        if (data?.food_coffee?.length) {
          setCoffee(data?.food_coffee[0]);
        }
        if (data?.types) {
          setTypes(data?.types);
        }
      } else {
        setIsFormUpdating(false);
      }
    } else {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
    }
    setIsLoading(false);
  };

  const handleSubmitDietaryDeterminants = async () => {
    if (patient_id && visit_id) {
      let payload: IPayloadForPatientDietaryInformation =
        await handlePayloadForDietaryDeterminantsForm(patient_id, visit_id);
      setIsBtnLoading(true);
      if (isFormUpdating) {
        const res = await patchDirectData(
          `items/dietary_determinants/${dietaryDeterminantsId}`,
          payload
        );
        if (res.isSuccess) {
          showToast({
            type: "success",
            title: "Added Dietary determinants successfully",
            message: " Dietary determinants",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            message: res?.message || "Failed to add Dietary determinants",
          });
        }
      } else {
        const res = await postDirectData(`items/dietary_determinants`, payload);
        if (res.isSuccess) {
          showToast({
            type: "success",
            title: "Added Dietary determinants successfully",
            message: " Dietary determinants",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            message: res?.message || "Failed to add Dietary determinants",
          });
        }
      }
      setIsBtnLoading(false);
    } else {
      showToast({
        type: "error",
        title: "Please try again",
        message: "User id not found !",
      });
    }
  };

  return {
    isOpenNewDietModal,
    setIsOpenNewDietModal,
    diet,
    setDiet,
    stapleDiet,
    setStapleDiet,
    typeOfFats,
    setTypeOfFats,
    textInputs,
    setTextInputs,
    useOfRepeatedlyHeatedOil,
    setUseOfRepeatedlyHeatedOil,
    dailyConsumptionOfFood,
    setDailyConsumptionOfFood,
    sourceOfFood,
    setSourceOfFood,
    storage,
    setStorage,
    weeklyFoodConsumption,
    setWeeklyFoodConsumption,
    handleSubmitDietaryDeterminants,
    newDiet,
    setNewDiet,
    redChili,
    greenChili,
    peanut,
    coffee,
    tea,
    setRedChili,
    setGreenChili,
    setPeanut,
    setCoffee,
    setTea,
    types,
    setTypes,
  };
}
