import { DateInput, DateInputProps } from '@mantine/dates'
import React from 'react'

export default function EDateInput(props:DateInputProps) {
  return (
    <DateInput
    label={props.label}
    size={props.size}
    className={props.className}
    description={props.description}
    value={props.value}
    placeholder={props.placeholder}
    error={props.error}
    onChange={props.onChange}
    />
  )
}
