import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  ICenterDetails,
  IPatientInformation,
  IUserDataFromNewPatientApi,
} from "../../../libs/data-access/types";
import moment from "moment";
import axios from "axios";
import { API } from "../../../libs/data-access/constant";
import { hasOnlyAlpha, isValidPhone, showToast } from "../../../utils/utility";
import { AppContext } from "../../../AppContext";
import { useNavigate } from "react-router-dom";
import { IPatientInformationError } from "../../../utils/typeForValidorError";
import aws from "aws-sdk";
import { Buffer } from "buffer";
import { useDirectusApi } from "../../../libs/data-access";
import useUploadFiles from "../../Hooks/useUploadFiles";
import { FileWithPath } from "@mantine/dropzone";

const DefaultInfo: IPatientInformation = {
  first_name: "",
  last_name: "",
  age: null,
  disease: [],
  primary_contact_no: null,
  alternate_contact_no: null,
  secondary_alternate_contact_no: null,
  email: "",
  gender: "male",
  father_name: "",
  mother_name: "",
  address: "",
  house_no: "",
  locality: "",
  city: "",
  state: "",
  pin_code: null,
  whatsapp_no: "",
  hospital_no: "",
  admission_no: "",
  ward_no: "",
  bed_no: "",
  physician_in_charge: "",
  surgeon_in_charge: "",
  radiology_in_charge: "",
  follow_ups: [],
  ultrasound_evidence_of_gs: "",
  ultrasound_evidence_of_gbc: "",
  definitive_diagnosis: "",
  severe_comorbidities: "no",
  unwilling_for_fu: "no",
  recruitment_date: "",
};

const DefaultError: IPatientInformationError = {
  name: "",
  gender: "",
  age: "",
  address: "",
  houseNo: "",
  city: "",
  locality: "",
  state: "",
  postalCode: "",
  mobileNo: "",
  secondaryNo: "",
  alternateNo: "",
  HospitalNo: "",
  disease: "",
};

const s3 = new aws.S3({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  signatureVersion: "v4",
});

export default function usePatientInformation() {
  const navigate = useNavigate();
  const { user, setColorScheme } = useContext(AppContext);

  const { fetchDirectData, patchDirectData } = useDirectusApi();
  const { handleUploadMedia } =
    useUploadFiles();

  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [isMediaBtnLoading, setIsMediaBtnLoading] = useState<boolean>(false);
  const [isBtnDisable, setIsBtnDisable] = useState<boolean>(true);
  const [isLoadingPinData, setIsLoadingPinData] = useState<boolean>(true);
  const [error, setError] = useState<IPatientInformationError>(DefaultError);
  const [patientInfo, setPatientInfo] =
    useState<IPatientInformation>(DefaultInfo);
  const [ageEligibility, setAgeEligibility] = useState<boolean | undefined>(
    undefined
  );
  const [isOpenInfoModal, setIsOpenInfoModal] = useState<boolean>(false);
  const [isOpenCameraModal, setIsOpenCameraModal] = useState<boolean>(false);

  const [pdf, setPdf] = useState<any>();
  const [userDetail, setUserDetails] = useState<IUserDataFromNewPatientApi>(
    {} as IUserDataFromNewPatientApi
  );
  const [centerDetails, setCenterDetails] = useState<ICenterDetails>(
    {} as ICenterDetails
  );

  const [uniqueNessCheck, setUniqueNessCheck] = useState<{
    mobileNo: string;
    alternateNo: string;
    secondaryNo: string;
  }>({
    mobileNo: "not_checked",
    alternateNo: "not_checked",
    secondaryNo: "not_checked",
  });

  const [inclusionStatus, setInclusionStatus] = useState<"included" | "excluded" | "">("");
  const [patientProfileId, setPatientProfileId] = useState<number | undefined>(undefined)

  useEffect(() => {
    setColorScheme({ head: "", body: "", text: "", footer: "" });
    getCenterDetails();
  }, []);

  useEffect(() => {
    if (patientInfo?.pin_code && patientInfo?.pin_code.toString().length == 6) {
      const res = getDataByPinCode(patientInfo?.pin_code.toString());
    }
  }, [patientInfo?.pin_code]);

  // HANDLER FUNCTION
  const getDataByPinCode = async (pin: string) => {
    setIsLoadingPinData(true);
    axios
      .get(`http://www.postalpincode.in/api/pincode/${pin}`)
      .then((res) => {
        return res.data[0].state;
      })
      .catch((err) => {
        // handleError("postalCode","Invalid Postal Code")
      });
    setIsLoadingPinData(false);
  };


  const handleChangeInfo = (
    key: keyof IPatientInformation,
    value: number | string | string[] | null
  ) => {
    if (key === "age") {
      if (Number(value) >= 18 && Number(value) <= 80) {
        setAgeEligibility(true);
      } else {
        setAgeEligibility(false);
      }
    }

    setPatientInfo((prev) => ({ ...prev, [key]: value }));

    let data = { ...patientInfo, [key]: value };
    if (
      data?.first_name &&
      data?.age &&
      data?.address &&
      data?.house_no &&
      data?.pin_code &&
      data?.city &&
      data?.state &&
      data?.primary_contact_no &&
      data?.alternate_contact_no &&
      data?.hospital_no &&
      data?.disease?.length
    ) {
      setIsBtnDisable(false);
      setColorScheme({ head: "", body: "#c9ebc3", text: "", footer: "" });
    } else {
      setIsBtnDisable(true);
      setColorScheme({ head: "", body: "", text: "", footer: "" });
    }
  };

  const handleError = (key: keyof IPatientInformationError, value: string) => {
    setError((prev) => ({ ...prev, [key]: value }));
  };

  const handleValidPatientInformation = (patientInfo: IPatientInformation) => {
    let isValidated = true;

    if (
      !patientInfo.first_name.trim() ||
      !hasOnlyAlpha(patientInfo?.first_name)
    ) {
      handleError("name", "Enter valid name");
      isValidated = false;
    }
    if (!patientInfo.age) {
      handleError("age", "Enter Valid age");
      isValidated = false;
    }
    if (!patientInfo.address.trim()) {
      handleError("address", "Invalid address");
      isValidated = false;
    }
    if (!patientInfo.primary_contact_no) {
      handleError("mobileNo", "Required field mobile");
      isValidated = false;
    }
    if (!isValidPhone(String(patientInfo.primary_contact_no))) {
      handleError("mobileNo", "Invalid mobile no.");
      isValidated = false;
    }
    if (!isValidPhone(String(patientInfo.alternate_contact_no))) {
      handleError("alternateNo", "Invalid mobile no.");
      isValidated = false;
    }
    if (patientInfo.secondary_alternate_contact_no && !isValidPhone(String(patientInfo.secondary_alternate_contact_no))) {
      handleError("secondaryNo", "Invalid mobile no.");
      isValidated = false;
    }
    if (!patientInfo.hospital_no) {
      handleError("HospitalNo", "Required field");
      isValidated = false;
    }
    if (!patientInfo.disease) {
      handleError("disease", "Required field");
      isValidated = false;
    }

    if (!isValidated) {
      // Display the generic error message
      showToast({
        type: "warning",
        message: " Please fill all required fields",
      });
    }
    return isValidated;
  };

  const handelSubmit = () => {
    if (
      uniqueNessCheck.mobileNo === "notUnique" ||
      uniqueNessCheck.alternateNo === "notUnique" ||
      (uniqueNessCheck.secondaryNo && uniqueNessCheck.secondaryNo === "notUnique")
    ) {
      showToast({
        type: "warning",
        message: " Please fill all required fields",
      });
      return;
    }

    let payload: IPatientInformation = {
      ...patientInfo,
      // center_id: user?.role?.id ?? "",
    };
    /* TODO: Center id 3 for center PGIMER */
    const valid = handleValidPatientInformation(payload);

    if (!pdf) {
      const confirmUpload = window.confirm("Consent form not uploaded. Do you want to continue?");
      if (!confirmUpload) {
        return; // Exit the function if the user presses "Cancel"
      }
    }

    if (valid) {
      postPatientInfo(payload);
    }
  };

  const handleUploadFiles = async (files: FileWithPath[], patientId: string) => {
    setIsMediaBtnLoading(true);
    console.log("Files to be uploaded", files)
    const uploadedFiles = await handleUploadMedia(
      "base_line",
      patientId + "",
      "consent_form",
      files
    );
    if (uploadedFiles?.length > 0) {
      handleUploadFileNamesInDb(patientId, uploadedFiles.join(""));
      showToast({
        type: "success",
        title: "Success",
        message: "PDF uploaded successfully and user created",
      });
      setIsOpenInfoModal(true);
      setIsBtnLoading(false);
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: "Error uploading media files",
      });
      setIsBtnLoading(false);
    }
  };


  // API FUNCTION

  const getCenterDetails = async () => {
    const res = await fetchDirectData(
      `items/center_profile?filter[user_id][_eq]=${user?.id}`
    );
    if (res?.isSuccess) {
      setCenterDetails(res?.data[0]);
    }
  };

  const postPatientInfo = async (payload: IPatientInformation) => {
    setIsBtnLoading(true);
    axios
      .post(`${API}custom/add-patient`, payload)
      .then((res) => {
        // showToast({
        //   type: "success",
        //   message: "New Patient Added",
        // });
        if (ageEligibility) {
          localStorage.setItem(
            "patientData",
            JSON.stringify({
              ...payload,
              patientId: res?.data?.data?.patient_id,
            })
          );
          setPatientProfileId(res?.data?.data?.patient_profile_id);
          if (pdf) {
            handleUploadFiles(
              [pdf],
              res?.data?.data?.patient_profile_id
            )
            // handleUploadPdf(res?.data?.data?.patient_id);
          } else {
            setIsBtnLoading(false);
            setIsOpenInfoModal(true);
          }
          setUserDetails(res?.data?.data);
        } else {
          setIsBtnLoading(false);
          setIsOpenInfoModal(true);
        }
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Please try again.",
          message: err?.response?.data?.message || "Unable to add new patient.",
        });
        setIsBtnLoading(false);
      });
  };

  const handleDownloadPDF = async (language: string) => {
    const filePath = require("../../../assets/pdf/" +
      language.toLowerCase() +
      "_consent.pdf");
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + filePath;
    link.download = `consent_${language}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const checkUniqueNumber = async (
    number: string | number,
    type: "mobileNo" | "alternateNo" | "secondaryNo"
  ) => {
    if (!isValidPhone(String(number))) {
      handleError(type, "Invalid mobile no.");
      return;
    } else {
      handleError(type, "");
    }
    const res = await fetchDirectData(
      `items/patiant_profile?filter={ "_or": [ { "primary_contact_no": { "_eq": "${number}" } }, { "alternate_contact_no": { "_eq": ${number} } }, { "secondary_alternate_contact_no": { "_eq": "${number}" } } ] }`
    );
    if (res.isSuccess) {
      if (res?.data?.length > 0) {
        handleError(type, "Mobile no. already exists.");
        setUniqueNessCheck({ ...uniqueNessCheck, [type]: "notUnique" });
      } else {
        setUniqueNessCheck({ ...uniqueNessCheck, [type]: "unique" });
      }
    } else {
      handleError(type, "Something went wrong. Please try again.");
      setUniqueNessCheck({ ...uniqueNessCheck, [type]: "error" });
    }
  };

  const handleUploadFileNamesInDb = async (patientId: string, uploadedFilesUrl: string) => {
    let payload = {
      consent_from_url: uploadedFilesUrl
    }
    const res = await patchDirectData(`items/patiant_profile/${patientId}`, payload);
    if (res?.isSuccess) {
      console.log("uploaded media file", res?.data)
    }
    else {
      console.log("uploaded media file error", res?.message)
    }
  }

  const handleUpdateInclusionStatus = async () => {
    let payload = {
      inclusion_status: inclusionStatus || null
    }
    const res = await patchDirectData(`items/patiant_profile/${patientProfileId}`, payload);
    if (res?.isSuccess) {
      navigate(
        `/index/patient/visit?patient_id=${userDetail?.patient_id}&visit_id=${userDetail?.visitId}`
      );
      window.location.reload();
    }
    else {
      console.log("uploaded media file error", res?.message)
    }
  }


  return {
    pdf,
    error,
    userDetail,
    patientInfo,
    isBtnLoading,
    isBtnDisable,
    centerDetails,
    ageEligibility,
    uniqueNessCheck,
    isOpenInfoModal,
    inclusionStatus,
    isLoadingPinData,
    setInclusionStatus,
    setUniqueNessCheck,
    setPdf,
    handleError,
    handelSubmit,
    handleChangeInfo,
    getDataByPinCode,
    handleDownloadPDF,
    checkUniqueNumber,
    isOpenCameraModal,
    setIsOpenCameraModal,
    handleUpdateInclusionStatus
  };
}
