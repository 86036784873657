import { useContext } from "react";
import usePatientsDetails from "./hooks/usePatientsDetails";
import PageLayout from "../../PageLayout";
import { EButton, ECheckBox, EEmpty, ELabelView, EPageTitle, ETable, ETextInput } from "../../libs/ui";
import { Avatar, Badge, Button, Grid, Modal, Table } from "@mantine/core";
import moment from "moment";
import { FaUserPlus } from "react-icons/fa";
import { BsArrowLeft, BsCheck2, BsPen } from "react-icons/bs";
import EPhoneInput from "../../libs/ui/src/NumberInput/EPhoneInput";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";
import { AppContext } from "../../AppContext";
import useEditPatientInformation from "../EditPatient/hook/useEditPatientInformation";

export default function PatientsDetails() {

  const { role } = useContext(AppContext);
  const {
    isBtnLoading,
    isChecked,
    setIsChecked,
    isOpenVisitModal,
    setIsOpenVisitModal,
    patientDetails,
    visitList,
    postStartVisit,
    patient_id,
    error,
    navigate,
    handleChangeInfo,
    editProfile,
    uniqueNessCheck,
    checkUniqueNumber,
    setUniqueNessCheck,
    updatePatientProfile,
    setIsOpenInfoModal,
    isOpenInfoModal,
    setUpdateInclusionStatus,
    updateInclusionStatus,
    consentFormUrl,
    inclusionStatus,
    handleUpdateInclusionStatus
  } = usePatientsDetails();

  return (
    <PageLayout
      header={
        <EPageTitle
          title="Patient Details"
          end={
            <>

              <EButton
                className="btn-blue"
                size="md"
                onClick={() => {
                  navigate("/index");
                }}
              >
                <BsArrowLeft className="" /> Back
              </EButton>
            </>
          }
        />
      }
    >
      <Grid style={{ overflowWrap: "break-word" }}>


        {/* Details section */}

        <Grid.Col span={4}>
          <div className="border rounded-md h-full p-4 pt-1 m-1 bg-slate-50 overflow-hidden">
            <div className="flex justify-between items-center">
              <EPageTitle title=" Details" />
              {role?.includes("Center") ? <EButton
                variant={"secondary"}
                size="md"
                onClick={() => {
                  navigate("/index/patient/edit-patient?patient_id=" + patient_id);
                }}
              >
                <BsPen className="" /> View / Edit
              </EButton> : <></>}
            </div>

            <div className="flex gap-2 mt-4 items-center justify-between flex-wrap">
             <div className="flex gap-2 mt-4 items-center">
             <Avatar size={"lg"} radius={"md"} />
              <div>
                <div className="font-bold">
                  {patientDetails?.first_name + " " + patientDetails?.last_name}
                </div>
                <div className="font-medium text-slate-600">
                  {patientDetails?.email}
                </div>
              </div>
             </div>

              <div className='cursor-pointer'
                onClick={() => {
                  setUpdateInclusionStatus(inclusionStatus);
                  setIsOpenInfoModal(true)
                }}
              >
                <Badge

                  size='lg'
                  color={inclusionStatus === "included"
                    ? "green"
                    : inclusionStatus === "excluded"
                      ? "yellow"
                      : "gray"
                  }
                //    onClick={() => handleUpdateInclusionStatus()}
                >
                  {inclusionStatus === "included" ? "Included" : inclusionStatus === "excluded" ? "Excluded" : "Pending"}

                </Badge>
              </div>
            </div>
            <Grid>
              <Grid.Col span={6} className="mt-4">
                <ELabelView
                  label="Mobile no."
                  value={String(patientDetails?.primary_contact_no ?? "-")}
                />
              </Grid.Col>

              <Grid.Col span={6} className="mt-4">
                <ELabelView
                  label="Alternate Mobile no."
                  value={String(patientDetails?.alternate_contact_no ?? "-")}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <ELabelView
                  label="Hospital Number"
                  value={String(
                    patientDetails?.hospital_no ?? "-"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <ELabelView
                  label="Recruitment Date"
                  value={patientDetails?.recruitment_date ?? "-"}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <ELabelView
                  label="Address"
                  value={String(patientDetails?.address ?? "-")}
                />
              </Grid.Col>
              {patientDetails?.father_name ? <Grid.Col span={6}>
                <ELabelView
                  label="Father Name"
                  value={String(patientDetails?.father_name || "-")}
                />
              </Grid.Col> : <></>}
              {patientDetails?.mother_name ? <Grid.Col span={6}>
                <ELabelView
                  label="Mother Name"
                  value={String(patientDetails?.mother_name || "-")}
                />
              </Grid.Col> : <></>}
              <Grid.Col span={6}>
                <ELabelView
                  label="Age"
                  value={String(patientDetails?.age || "-")}
                />
              </Grid.Col>
            </Grid>
          </div>
        </Grid.Col>

        {/* Visit Section */}


        {role?.includes("Center") ? <Grid.Col span={8}>
          <div className="border rounded-md p-6 pt-1 h-full m-1">
            <div className="flex justify-between">
              <EPageTitle title="Visit List" />
              <EButton
                className="btn-blue mr-4 mt-4"
                size="md"
                onClick={() => {
                  setIsOpenVisitModal(true);
                }}
              >
                <FaUserPlus className="" /> Create New Visit
              </EButton>
            </div>
            <div className="mt-4">
              {visitList?.length ? (
                <ETable>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th className="whitespace-nowrap">Visit No.</Table.Th>
                      <Table.Th>Visit Date</Table.Th>
                      {/* <Table.Th>center State</Table.Th>
                      <Table.Th>center Address</Table.Th> */}
                      {/* <Table.Th>Personal History</Table.Th> */}
                      <Table.Th>Clinical History</Table.Th>
                      <Table.Th>Gall Stone</Table.Th>
                      <Table.Th>Anthropometric Measurement</Table.Th>
                      {/* <Table.Th>Dietary Determinants</Table.Th> */}
                      <Table.Th>Investigations</Table.Th>
                      <Table.Th>Radiology</Table.Th>
                      {/* <Table.Th>Surgery</Table.Th> */}
                      {/* <Table.Th>Histology</Table.Th> */}
                      {/* <Table.Th>Stone Analysis</Table.Th> */}
                      <Table.Th>Cost Effective Analysis</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody className="text-center">
                    {visitList?.map((visit, index) => (
                      <Table.Tr key={visit?.id}>
                        <Table.Td width={40}>
                          <p
                            className="text-blue-600 whitespace-nowrap cursor-pointer"
                            onClick={() => {
                              if (index == 0) {
                                navigate(
                                  `/index/patient/visit?patient_id=${patient_id}&visit_id=${visit?.id}`
                                );
                                // window.location.reload();
                              } else {
                                navigate(
                                  `/index/patient/new-visit?patient_id=${patient_id}&visit_id=${visit?.id}`
                                );
                                // window.location.reload();
                              }
                            }}
                          >
                            {index == 0 ? "Base Line" : "Follow Up " + (index)}
                          </p>
                        </Table.Td>
                        <Table.Td>
                          {moment(visit?.date_created).format("DD-MM-YYYY")}
                        </Table.Td>
                        {/* <Table.Td>
                          {visit?.demographic_information ? (
                            <div className="flex justify-center">
                            <BsCheck2 size={28} fontWeight={900} color="green"/>
                            </div>
                          ) : (
                            <p className="font-bold text-2xl">__</p>
                          )}
                        </Table.Td> */}
                        <Table.Td>
                          {visit?.clinical_history ? (
                            <div className="flex justify-center">
                              <BsCheck2
                                size={28}
                                fontWeight={900}
                                color="green"
                              />
                            </div>
                          ) : (
                            <p className="text-2xl">__</p>
                          )}
                        </Table.Td>
                        <Table.Td>
                          {visit?.gallstones_history ? (
                            <div className="flex justify-center">
                              <BsCheck2
                                size={28}
                                fontWeight={900}
                                color="green"
                              />
                            </div>
                          ) : (
                            <p className="text-2xl">__</p>
                          )}
                        </Table.Td>
                        <Table.Td>
                          {visit?.anthropometric_measurements ? (
                            <div className="flex justify-center">
                              <BsCheck2
                                size={28}
                                fontWeight={900}
                                color="green"
                              />
                            </div>
                          ) : (
                            <p className="text-2xl">__</p>
                          )}
                        </Table.Td>
                        {/* <Table.Td>
                          {visit?.dietary_determinants ? (
                            <div className="flex justify-center">
                            <BsCheck2 size={28} fontWeight={900} color="green"/>
                            </div>
                          ) : (
                            <p className="text-2xl">__</p>
                          )}
                        </Table.Td> */}
                        <Table.Td>
                          {visit?.investigations ? (
                            <div className="flex justify-center">
                              <BsCheck2
                                size={28}
                                fontWeight={900}
                                color="green"
                              />
                            </div>
                          ) : (
                            <p className="text-2xl">__</p>
                          )}
                        </Table.Td>
                        <Table.Td>
                          {visit?.radiology ? (
                            <div className="flex justify-center">
                              <BsCheck2
                                size={28}
                                fontWeight={900}
                                color="green"
                              />
                            </div>
                          ) : (
                            <p className="text-2xl">__</p>
                          )}
                        </Table.Td>
                        {/* <Table.Td>
                          {visit?.surgery_gs_patients ? (
                            <div className="flex justify-center">
                            <BsCheck2 size={28} fontWeight={900} color="green"/>
                            </div>
                          ) : (
                            <p className="text-2xl">__</p>
                          )}
                        </Table.Td> */}
                        {/* <Table.Td>
                          {visit?.histology_gb_tissue ? (
                            <div className="flex justify-center">
                            <BsCheck2 size={28} fontWeight={900} color="green"/>
                            </div>
                          ) : (
                            <p className="text-2xl">__</p>
                          )}
                        </Table.Td> */}
                        {/* <Table.Td>
                          {visit?.stone_analysis ? (
                            <div className="flex justify-center">
                            <BsCheck2 size={28} fontWeight={900} color="green"/>
                            </div>
                          ) : (
                            <p className="text-2xl">__</p>
                          )}
                        </Table.Td> */}

                        <Table.Td>
                          {visit?.cost_effective_analysis ? (
                            <div className="flex justify-center">
                              <BsCheck2
                                size={28}
                                fontWeight={900}
                                color="green"
                              />
                            </div>
                          ) : (
                            <p className="text-2xl">__</p>
                          )}
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </ETable>
              ) : (
                <EEmpty title="Patient visit list is empty - no data available" />
              )}
            </div>
          </div>
        </Grid.Col> : <></>}
      </Grid>

      {/* Visit start confirmation Modal */}
      <Modal
        opened={isOpenVisitModal}
        onClose={() => setIsOpenVisitModal(false)}
        size={"lg"}
      >
        <Grid>
          <Grid.Col span={6}>
            <ETextInput
              required
              size="md"
              label="First Name"
              error={error?.name}
              value={editProfile?.first_name}
              onChange={(e) => {
                handleChangeInfo("first_name", e?.target?.value);
              }}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <ETextInput
              required
              size="md"
              label="Last Name"
              value={editProfile?.last_name}
              onChange={(e) => handleChangeInfo("last_name", e?.target?.value)}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <EPhoneInput
              required
              size="md"
              label="Mobile no."
              error={error?.mobileNo}
              value={editProfile?.primary_contact_no}
              rightSection={
                uniqueNessCheck?.mobileNo == "notUnique" ? (
                  <FiAlertCircle size={22} color="red" />
                ) : uniqueNessCheck?.mobileNo?.toLowerCase() == "unique" ? (
                  <FiCheckCircle size={22} color="green" />
                ) : (
                  <></>
                )
              }
              // icon={"+91"}
              maxLength={10}
              onChange={(e) => {
                handleChangeInfo("primary_contact_no", e);
                if (String(e).length == 10) {
                  checkUniqueNumber(e, "mobileNo");
                } else {
                  setUniqueNessCheck({
                    ...uniqueNessCheck,
                    mobileNo: "not_checked",
                  });
                }
              }}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <EPhoneInput
              hideControls
              size="md"
              label="Alternate Mobile No."
              required
              className="mt-2"
              error={error?.alternateNo}
              placeholder="Alternate Mobile No."
              value={editProfile?.alternate_contact_no}
              rightSection={
                uniqueNessCheck?.alternateNo == "notUnique" ? (
                  <FiAlertCircle size={22} color="red" />
                ) : uniqueNessCheck?.alternateNo?.toLowerCase() ==
                  "unique" ? (
                  <FiCheckCircle size={22} color="green" />
                ) : (
                  <></>
                )
              }
              // icon={"+91"}
              maxLength={10}
              onChange={(e) => {
                // handleError("alternateNo", "");
                handleChangeInfo("alternate_contact_no", e);
                if (String(e).length == 10) {
                  checkUniqueNumber(e, "alternateNo");
                } else {
                  setUniqueNessCheck({
                    ...uniqueNessCheck,
                    alternateNo: "not_checked",
                  });
                }
              }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <EPhoneInput
              hideControls
              size="md"
              label="Secondary Alternate Mobile No."
              error={error?.secondaryNo}
              className="mt-2"
              placeholder=" Mobile No."
              value={editProfile?.secondary_alternate_contact_no}
              rightSection={
                uniqueNessCheck?.secondaryNo == "notUnique" ? (
                  <FiAlertCircle size={22} />
                ) : uniqueNessCheck?.secondaryNo?.toLowerCase() ==
                  "unique" ? (
                  <FiCheckCircle size={22} color="green" />
                ) : (
                  <></>
                )
              }
              // icon={"+91"}
              maxLength={10}
              onChange={(e) => {
                // handleError("secondaryNo", "");
                handleChangeInfo("secondary_alternate_contact_no", e);
                if (String(e).length == 10) {
                  checkUniqueNumber(e, "secondaryNo");
                } else {
                  setUniqueNessCheck({
                    ...uniqueNessCheck,
                    secondaryNo: "not_checked",
                  });
                }
              }}
            />
          </Grid.Col>
          {/* <Grid.Col span={6}>
              <ELabelView
                label="Email"
                value={patientDetails?.email ?? "-"}
                />
            </Grid.Col>
            <Grid.Col span={6}>
              <ELabelView
                label="Address"
                value={String(patientDetails?.address ?? "-")}
              />
            </Grid.Col> */}
        </Grid>

        <div className="flex justify-between items-center border-t pt-3 mt-8">
          <ECheckBox
            checked={isChecked}
            onChange={(e) => setIsChecked(e?.target.checked)}
            label="Are you ready, to start a new visit with these details ?"
          />
          <Button
            className="btn-primary"
            disabled={!isChecked}
            loading={isBtnLoading}
            onClick={() => updatePatientProfile()}
          >
            Start Visit
          </Button>
        </div>
      </Modal>

      <Modal
        title="Eligibility Status"
        opened={isOpenInfoModal}
        size="xl"
        onClose={() => {
          setIsOpenInfoModal(false);
        }}
        closeOnClickOutside={false}
      >
        <div className="bg-slate-100 p-3 rounded-md">
          <div className="border-b border-slate-300 mb-4 pb-4">
            <div className="flex flex-row items-center justify-between mb-4">
              <div className="text-green-700 font-semibold text-2xl">
                Screening for Eligibility for GS
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <ECheckBox label="Informed consent" checked={consentFormUrl ? true : false} disabled />
              <ECheckBox
                label="Ultrasound evidence of GS"
                checked={patientDetails?.ultrasound_evidence_of_gs === "yes"}
                disabled
              />
              <ECheckBox
                label="Age (18 - 80) years"
                checked={patientDetails?.age ? patientDetails?.age >= 18 && patientDetails?.age <= 80 : false}
                disabled
              />
            </div>
          </div>
          <div>
            <div className="flex flex-row items-center justify-between mb-4">
              <div className="text-green-700 font-semibold text-2xl">
                Screening for Eligibility for GBC
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <ECheckBox label="Informed consent" checked={consentFormUrl ? true : false} disabled />
              <ECheckBox
                label="Ultrasound evidence of GS"
                checked={patientDetails?.ultrasound_evidence_of_gs === "yes"}
                disabled
              />
              <ECheckBox
                label="Age (18 - 80) years"
                checked={patientDetails?.age ? patientDetails?.age >= 18 && patientDetails?.age <= 80 : false}
                disabled
              />
              <ECheckBox
                label="Definitive Diagnosis"
                checked={patientDetails?.definitive_diagnosis?.includes("yes")}
                disabled
              />
              <ECheckBox
                label="Severe co-morbidities"
                checked={patientDetails?.severe_comorbidities === "yes"}
                disabled
              />
              <ECheckBox
                label="Unwilling for FU"
                checked={patientDetails?.unwilling_for_fu === "yes"}
                disabled
              />
            </div>
          </div>
        </div>

        <div className='flex justify-end gap-3 mt-3'>
          <ECheckBox
            label="Include this patient in the study"
            checked={updateInclusionStatus === "included"}
            onChange={(e) => {
              setUpdateInclusionStatus(updateInclusionStatus === "included" ? "" : "included");
            }}
          />


          <ECheckBox
            label="Exclude this patient from the study"
            checked={updateInclusionStatus === "excluded"}
            onChange={(e) => {
              setUpdateInclusionStatus(updateInclusionStatus === "excluded" ? "" : "excluded");
            }}
          />
        </div>

        <div className="flex justify-end mt-5 border-t pt-3">
          <EButton
            className="btn-blue"
            onClick={() => {
              handleUpdateInclusionStatus();
            }}
            loading={isBtnLoading}
          >
            Continue
          </EButton>
        </div>
      </Modal>
    </PageLayout>
  );
}
