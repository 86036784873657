import {
  EButton,
  EModal,
  ENumberInput,
  ESectionDivider,
  ETextArea,
  ETextInput,
  FormSectionDivider,
} from "../../../../../libs/ui";
import { Checkbox } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { MdOutlinePermMedia } from "react-icons/md";
import { useHistology } from "../hooks/useHistology";
import { HiOutlineArrowUpTray } from "react-icons/hi2";
import moment from "moment";
import EMediaItem from "../../../../../libs/ui/src/EMediaItem";
import EDateInput from "../../../../../libs/ui/src/DateInput/EDateInput";

export default function Histology() {
  const {
    files,
    mediaFiles,
    setFiles,
    newPayloadForHistology,
    setNewPayloadForHistology,
    handleUploadFiles,
    uploadFileModalIsOpen,
    setUploadFileModalIsOpen,
    isMediaBtnLoading,
    handleOpenMediaInNewTab,
    handleDeleteImageFromAws,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal,
    setGrowthPolypUlcer,
    growthPolypUlcer,
  } = useHistology();

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-9 border-r pr-3">
        <div className="flex justify-between">
          <EDateInput
            size="md"
            label="Date & Time"
            placeholder="Select date"
            value={
              newPayloadForHistology?.date_time
                ? new Date(newPayloadForHistology?.date_time)
                : undefined
            }
            onChange={(value) =>
              setNewPayloadForHistology((prev) => ({
                ...prev,
                date_time: moment(value).format("YYYY-MM-DD HH:mm"),
              }))
            }
          />
          <ETextInput
            label="Ref-Number"
            size="md"
            value={newPayloadForHistology?.ref_number}
            placeholder="Enter ref-number"
            onChange={(e) =>
              setNewPayloadForHistology((prev) => ({
                ...prev,
                ref_number: e.target.value,
              }))
            }
          />
        </div>

        <div className="mt-2">
          <ENumberInput
            className="w-1/4"
            label={"Length of GB (cm)"}
            placeholder={"Length of GB (cm)"}
            maxLength={10}
            // value={newPayloadForHistology?.length_of_gb_cm}
            // onChange={(event) =>
            //   setNewPayloadForHistology((prev) => ({
            //     ...prev,
            //     length_of_gb_cm: event.target.value,
            //   }))
            // }
            rightSectionWidth={"auto"}
            rightSection={
              <select
                value={
                  newPayloadForHistology?.length_of_gb_cm
                    ?.toString()
                    .split("-")
                    ? newPayloadForHistology?.length_of_gb_cm
                      ?.toString()
                      .split("-")[1]
                    : "cm"
                }
                style={{ height: "100%", borderRadius: "4px" }}
                onChange={(e) =>
                  setNewPayloadForHistology((prev) => ({
                    ...prev,
                    length_of_gb_cm: `${newPayloadForHistology?.length_of_gb_cm?.split(
                      "-"
                    )
                      ? newPayloadForHistology?.length_of_gb_cm?.split(
                        "-"
                      )[0]
                      : ""
                      }-${e?.target?.value}`,
                  }))
                }
              >
                <option value="cm">
                  <p className="p-2">cm</p>
                </option>
                <option value="inches">
                  <p className="p-2 ">inch</p>
                </option>
              </select>
            }
            value={
              newPayloadForHistology?.length_of_gb_cm
                ?.toString()
                .split("-")
                ? newPayloadForHistology?.length_of_gb_cm
                  ?.toString()
                  .split("-")[0]
                : ""
            }
            onChange={(e) =>
              setNewPayloadForHistology((prev) => ({
                ...prev,
                length_of_gb_cm:
                  `${newPayloadForHistology?.length_of_gb_cm
                    ?.toString()
                    ?.split("-")
                    ? `${e}-${newPayloadForHistology?.length_of_gb_cm
                      ?.toString()
                      ?.split("-")[1] ?? "cm"
                    }`
                    : e + "-cm"
                  }`,
              }))
            }
          />
          <FormSectionDivider
            title={"Wall thickness (range in mm)"}
            classes="mb-3"
          />
          <div className="histology-checkbox">
            <Checkbox
              label={"Normal"}
              checked={newPayloadForHistology?.wall_thickness == "Normal"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  wall_thickness: event.target.checked ? "Normal" : "",
                }))
              }
            />
            <Checkbox
              label={"Thickened"}
              checked={newPayloadForHistology?.wall_thickness?.includes(
                "Thickened"
              )}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  wall_thickness: event.target.checked ? "Thickened" : "",
                }))
              }
            />
            {newPayloadForHistology?.wall_thickness?.includes("Thickened") ? (
              <div>
                <Checkbox
                  label={"Symmetrical"}
                  checked={
                    newPayloadForHistology?.wall_thickness ==
                    "Thickened-Symmetrical"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      wall_thickness: event.target.checked ? "Thickened-Symmetrical" : "",
                    }))
                  }
                />
                <Checkbox
                  className="mt-2"
                  label={"Asymmetrical"}
                  checked={
                    newPayloadForHistology?.wall_thickness ==
                    "Thickened-Asymmetrical"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      wall_thickness: event.target.checked ? "Thickened-Asymmetrical" : '',
                    }))
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="flex gap-4 mt-4">
            <ETextInput
              className=""
              label={"Fundus"}
              value={newPayloadForHistology?.fundus}
              placeholder={"Fundus"}
              maxLength={10}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  fundus: event.target.value,
                }))
              }
            />
            <ETextInput
              className=""
              label={"Body"}
              value={newPayloadForHistology?.body}
              placeholder={"Body"}
              maxLength={10}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  body: event.target.value,
                }))
              }
            />
            <ETextInput
              className=""
              label={"Neck"}
              value={newPayloadForHistology?.neck}
              placeholder={"Neck"}
              maxLength={10}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  neck: event.target.value,
                }))
              }
            />
          </div>

          <ESectionDivider title="Growth/polyp/ulcer" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.growth_polyp_ulcer == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  growth_polyp_ulcer: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.growth_polyp_ulcer == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  growth_polyp_ulcer: event.target.checked ? "Yes" : "",
                }))
              }
            />
            {newPayloadForHistology?.growth_polyp_ulcer === "Yes" && (
              <ETextInput
                className=""
                value={growthPolypUlcer}
                placeholder={"Number"}
                maxLength={10}
                onChange={(event) => {
                  setGrowthPolypUlcer(event.target.value);
                }}
              />
            )}
          </div>

          <ESectionDivider title="Inflammation" />
          <div className="histology-checkbox">
            <Checkbox
              label={"Mild"}
              checked={newPayloadForHistology?.inflammation == "Mild"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  inflammation: event.target.checked ? "Mild" : "",
                }))
              }
            />
            <Checkbox
              label={"Moderate"}
              checked={newPayloadForHistology?.inflammation == "Moderate"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  inflammation: event.target.checked ? "Moderate" : "",
                }))
              }
            />
            <Checkbox
              label={"Severe"}
              checked={newPayloadForHistology?.inflammation == "Severe"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  inflammation: event.target.checked ? "Severe" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Type of inflammation" />
          <div className="histology-checkbox">
            <ETextInput
              className=""
              label={"N"}
              value={newPayloadForHistology?.type_inflammation_n}
              placeholder={"N (neutrophils)"}
              maxLength={10}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  type_inflammation_n: event.target.value,
                }))
              }
            />
            <ETextInput
              className=""
              label={"L"}
              value={newPayloadForHistology?.type_inflammation_l}
              placeholder={"L (lymphocytes)"}
              maxLength={10}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  type_inflammation_l: event.target.value,
                }))
              }
            />
            <ETextInput
              className=""
              label={"E"}
              value={newPayloadForHistology?.type_inflammation_e}
              placeholder={"E (eosinophils)"}
              maxLength={10}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  type_inflammation_e: event.target.value,
                }))
              }
            />
            <ETextInput
              className=""
              label={"M"}
              value={newPayloadForHistology?.type_inflammation_m}
              placeholder={"M (macrophages)"}
              maxLength={10}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  type_inflammation_m: event.target.value,
                }))
              }
            />
            <ETextInput
              className=""
              label={"PC"}
              value={newPayloadForHistology?.type_inflammation_pc}
              placeholder={"PC (plasma cells)"}
              maxLength={10}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  type_inflammation_pc: event.target.value,
                }))
              }
            />
          </div>

          <ESectionDivider title="Depth of inflammation" />
          <div className="histology-checkbox">
            <Checkbox
              label={"Superficial"}
              checked={
                newPayloadForHistology?.depth_of_inflammation == "Superficial"
              }
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  depth_of_inflammation: event.target.checked ? "Superficial" : "",
                }))
              }
            />
            <Checkbox
              label={"Deep"}
              checked={newPayloadForHistology?.depth_of_inflammation == "Deep"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  depth_of_inflammation: event.target.checked ? "Deep" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Pattern of inflammation" />
          <div className="histology-checkbox">
            <Checkbox
              label={"Patchy"}
              checked={
                newPayloadForHistology?.pattern_of_inflammation == "Patchy"
              }
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  pattern_of_inflammation: event.target.checked ? "Patchy" : "",
                }))
              }
            />
            <Checkbox
              label={"Diffuse"}
              checked={
                newPayloadForHistology?.pattern_of_inflammation == "Diffuse"
              }
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  pattern_of_inflammation: event.target.checked ? "Diffuse" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Granuloma" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.granuloma == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  granuloma: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.granuloma == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  granuloma: event.target.checked ? "Yes" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Giant cells" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.giant_cells == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  giant_cells: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.giant_cells == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  giant_cells: event.target.checked ? "Yes" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Reactive lymphoid follicles" />
          <div className="histology-checkbox">
            <Checkbox
              label={"Absent"}
              checked={
                newPayloadForHistology?.reactive_lymphoid_follicles == "Absent"
              }
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  reactive_lymphoid_follicles: event.target.checked ? "Absent" : "",
                }))
              }
            />
            <Checkbox
              label={"Few"}
              checked={
                newPayloadForHistology?.reactive_lymphoid_follicles == "Few"
              }
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  reactive_lymphoid_follicles: event.target.checked ? "Few" : "",
                }))
              }
            />
            <Checkbox
              label={"Many"}
              checked={
                newPayloadForHistology?.reactive_lymphoid_follicles == "Many"
              }
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  reactive_lymphoid_follicles: event.target.checked ? "Many" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Mucosal denudation" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.mucosal_denudation == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  mucosal_denudation: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.mucosal_denudation == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  mucosal_denudation: event.target.checked ? "Yes" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Mucosal ulceration" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.mucosal_ulceration == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  mucosal_ulceration: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.mucosal_ulceration == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  mucosal_ulceration: event.target.checked ? "Yes" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Mucosal hyperplasia" />
          <div className="histology-checkbox">
            {/* No Checkbox */}
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.mucosal_hyperplasia === "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  mucosal_hyperplasia: event.target.checked ? "No" : "",
                }))
              }
            />

            {/* Yes Checkbox */}
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.mucosal_hyperplasia?.includes("Yes")}
              onChange={(event) => {
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  mucosal_hyperplasia: event.target.checked ? "Yes" : "",
                }))
              }}
            />

            {/* Focal and Diffuse Checkboxes - only show if Yes is selected */}
            {newPayloadForHistology?.mucosal_hyperplasia?.includes("Yes") && (
              <div className="flex flex-col gap-2 ml-4">
                {["focal", "diffuse"].map((label) => (
                  <Checkbox
                    key={label}
                    label={label.charAt(0).toUpperCase() + label.slice(1)}
                    checked={newPayloadForHistology?.mucosal_hyperplasia?.includes(label)}
                    onChange={(event) => {
                      const currentValues = newPayloadForHistology?.mucosal_hyperplasia
                        .split(", ")
                        .filter(Boolean) || [];

                      let updatedValues = [...currentValues];

                      if (event.target.checked) {
                        // Add the new selection if not already included
                        if (!updatedValues.includes(label)) {
                          updatedValues.push(label);
                        }
                      } else {
                        // Remove the specific selection
                        updatedValues = updatedValues.filter((val) => val !== label);
                      }

                      setNewPayloadForHistology((prev) => ({
                        ...prev,
                        mucosal_hyperplasia: updatedValues.join(", ")
                      }));
                    }}
                  />
                ))}
              </div>
            )}
          </div>


          <ESectionDivider title="Fibrosis" />
          <div className="histology-checkbox">
            {/* Absent Checkbox */}
            <Checkbox
              label={"Absent"}
              checked={newPayloadForHistology?.fibrosis === "Absent"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  fibrosis: event.target.checked ? "Absent" : "",
                }))
              }
            />

            {/* Present Checkbox */}
            <Checkbox
              label={"Present (mild/moderate/severe)"}
              checked={newPayloadForHistology?.fibrosis?.includes("Present")}
              onChange={(event) => {
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  fibrosis: event.target.checked ? "Present" : "",
                }));
              }}
            />

            {/* Mild, Moderate, Severe Checkboxes - only show if Present is selected */}
            {newPayloadForHistology?.fibrosis?.includes("Present") ? (
              <div className="flex flex-col gap-2 ml-4">
                {["mild", "moderate", "severe"].map((label) => (
                  <Checkbox
                    key={label}
                    label={label.charAt(0).toUpperCase() + label.slice(1)}
                    checked={newPayloadForHistology?.fibrosis?.includes(label)}
                    onChange={(event) => {
                      // Only set the selected label as the fibrosis value
                      setNewPayloadForHistology((prev) => ({
                        ...prev,
                        fibrosis: event.target.checked ? "Present, " + label : "Present",
                      }));
                    }}
                  />
                ))}
              </div>
            ) : <></>}
          </div>


          <ESectionDivider title="Muscle hypertrophy" />
          <div className="histology-checkbox">
            {/* No Checkbox */}
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.muscle_hypertrophy === "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  muscle_hypertrophy: event.target.checked ? "No" : "",
                }))
              }
            />

            {/* Yes Checkbox */}
            <Checkbox
              label={"Yes (mild/moderate/severe)"}
              checked={newPayloadForHistology?.muscle_hypertrophy?.includes("Yes")}
              onChange={(event) => {

                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  muscle_hypertrophy: event.target.checked ? "Yes" : "",
                }));
              }}
            />

            {/* Mild, Moderate, Severe Checkboxes - only show if Yes is selected */}
            {newPayloadForHistology?.muscle_hypertrophy?.includes("Yes") ? (
              <div className="flex flex-col gap-2 ml-4">
                {["mild", "moderate", "severe"].map((label) => (
                  <Checkbox
                    key={label}
                    label={label.charAt(0).toUpperCase() + label.slice(1)}
                    checked={newPayloadForHistology?.muscle_hypertrophy?.includes(label)}
                    onChange={(event) => {
                      setNewPayloadForHistology((prev) => ({
                        ...prev,
                        muscle_hypertrophy: event.target.checked ? "Yes, " + label : "Yes",
                      }));
                    }}
                  />
                ))}
              </div>
            ) : <></>}
          </div>



          <ESectionDivider title="Myocyte vacuolation" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.myocyte_vacuolation == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  myocyte_vacuolation: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.myocyte_vacuolation == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  myocyte_vacuolation: event.target.checked ? "Yes" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="RAS" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.ras == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  ras: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.ras == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  ras: event.target.checked ? "Yes" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Hyalinisation" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.hyalinisation == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  hyalinisation: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.hyalinisation == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  hyalinisation: event.target.checked ? "Yes" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Calcification" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.calcification == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  calcification: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.calcification == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  calcification: event.target.checked ? "Yes" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Cholesterolosis" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.cholesterolosis == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  cholesterolosis: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.cholesterolosis == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  cholesterolosis: event.target.checked ? "Yes" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Metaplasia (AB-PAS stain)" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.metaplasia == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  metaplasia: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.metaplasia?.includes("Yes")}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  metaplasia: event.target.checked ? "Yes" : "",
                }))
              }
            />

            {newPayloadForHistology?.metaplasia?.includes("Yes") ? (
              <div className="flex flex-col gap-2">
                <Checkbox
                  label={"Pyloric (Focal / diffuse)"}
                  checked={
                    newPayloadForHistology?.metaplasia ==
                    "Yes, Pyloric (Focal / diffuse)"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      metaplasia: event.target.checked ? "Yes, Pyloric (Focal / diffuse)" : "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"Incomplete intestinal (Focal / diffuse)"}
                  checked={
                    newPayloadForHistology?.metaplasia ==
                    "Yes, Incomplete intestinal (Focal / diffuse)"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      metaplasia:
                        event.target.checked ? "Yes, Incomplete intestinal (Focal / diffuse)" : "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"Complete intestinal (Focal / diffuse)"}
                  checked={
                    newPayloadForHistology?.metaplasia ==
                    "Yes, Complete intestinal (Focal / diffuse)"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      metaplasia: event.target.checked ? "Yes, Complete intestinal (Focal / diffuse)" : "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"Mixed"}
                  checked={newPayloadForHistology?.metaplasia == "Yes, Mixed"}
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      metaplasia: event.target.checked ? "Yes, Mixed" : "Yes",
                    }))
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>

          <ESectionDivider title="Dysplasia" />
          <div className="histology-checkbox">
            <Checkbox
              label={"Absent"}
              checked={newPayloadForHistology?.dysplasia == "Absent"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  dysplasia: event.target.checked ? "Absent" : "",
                }))
              }
            />
            <Checkbox
              label={"Low grade"}
              checked={newPayloadForHistology?.dysplasia == "Low grade"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  dysplasia: event.target.checked ? "Low grade" : "",
                }))
              }
            />
            <Checkbox
              label={"High grade"}
              checked={newPayloadForHistology?.dysplasia == "High grade"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  dysplasia: event.target.checked ? "High grade" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Atypia NOS" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.atypia_nos == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  atypia_nos: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.atypia_nos == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  atypia_nos: event.target.checked ? "Yes" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Cholecystitis" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.cholecystitis == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  cholecystitis: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.cholecystitis?.includes("Yes")}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  cholecystitis: event.target.checked ? "Yes" : "",
                }))
              }
            />

            {newPayloadForHistology?.cholecystitis?.includes("Yes") ? (
              <div className="flex flex-col gap-2">
                <Checkbox
                  label={"Chronic cholecystitis [NOS]"}
                  checked={
                    newPayloadForHistology?.cholecystitis ==
                    "Yes, Chronic cholecystitis [NOS]"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      cholecystitis: event.target.checked ? "Yes, Chronic cholecystitis [NOS]" : "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"Follicular cholecystitis"}
                  checked={
                    newPayloadForHistology?.cholecystitis ==
                    "Yes, Follicular cholecystitis"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      cholecystitis: event.target.checked ? "Yes, Follicular cholecystitis" : "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"Hyalinising cholecystitis"}
                  checked={
                    newPayloadForHistology?.cholecystitis ==
                    "Yes, Hyalinising cholecystitis"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      cholecystitis: event.target.checked ? "Yes, Hyalinising cholecystitis" : "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"Xanthogranulomatous cholecystitis"}
                  checked={
                    newPayloadForHistology?.cholecystitis ==
                    "Yes, Xanthogranulomatous cholecystitis"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      cholecystitis: event.target.checked ? "Yes, Xanthogranulomatous cholecystitis" : "Yes",
                    }))
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>

          <ESectionDivider title="Incidental GBC" />
          <div className="histology-checkbox">
            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.incidental_gbc == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  incidental_gbc: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.incidental_gbc == "Yes"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  incidental_gbc: event.target.checked ? "Yes" : "",
                }))
              }
            />
          </div>

          <ESectionDivider title="Invasive Carcinoma" />
          <div className="histology-checkbox">

            {/* {newPayloadForHistology?.invasive_carcinoma?.includes("Yes") ? (
              <div className="flex flex-col gap-2">
                <Checkbox
                  label={"Grade"}
                  checked={
                    newPayloadForHistology?.invasive_carcinoma == "Yes, Grade"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      invasive_carcinoma: event.target.checked ? "Yes, Grade": "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"Size"}
                  checked={
                    newPayloadForHistology?.invasive_carcinoma == "Yes, Size"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      invasive_carcinoma: event.target.checked ? "Yes, Size": "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"Depth"}
                  checked={
                    newPayloadForHistology?.invasive_carcinoma == "Yes, Depth"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      invasive_carcinoma: event.target.checked ? "Yes, Depth": "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"LVI"}
                  checked={
                    newPayloadForHistology?.invasive_carcinoma == "Yes, LVI"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      invasive_carcinoma: event.target.checked ? "Yes, LVI": "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"PNI"}
                  checked={
                    newPayloadForHistology?.invasive_carcinoma == "Yes, PNI"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      invasive_carcinoma: event.target.checked ? "Yes, PNI": "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"Necrosis"}
                  checked={
                    newPayloadForHistology?.invasive_carcinoma ==
                    "Yes, Necrosis"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      invasive_carcinoma: event.target.checked ? "Yes, Necrosis": "Yes",
                    }))
                  }
                />
                <Checkbox
                  label={"LNs"}
                  checked={
                    newPayloadForHistology?.invasive_carcinoma == "Yes, LNs"
                  }
                  onChange={(event) =>
                    setNewPayloadForHistology((prev) => ({
                      ...prev,
                      invasive_carcinoma: event.target.checked ? "Yes, LNs": "Yes",
                    }))
                  }
                />
              </div>
            ) : (
              <></>
            )} */}

            <Checkbox
              label={"No"}
              checked={newPayloadForHistology?.invasive_carcinoma == "No"}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  invasive_carcinoma: event.target.checked ? "No" : "",
                }))
              }
            />
            <Checkbox
              label={"Yes"}
              checked={newPayloadForHistology?.invasive_carcinoma?.includes(
                "Yes"
              )}
              onChange={(event) =>
                setNewPayloadForHistology((prev) => ({
                  ...prev,
                  invasive_carcinoma: event.target.checked ? "Yes" : "",
                }))
              }
            />
            {newPayloadForHistology?.invasive_carcinoma?.includes("Yes") ? (
              <div className="flex flex-col gap-2">
                {["Grade", "Size", "Depth", "LVI", "PNI", "Necrosis", "LNs"].map((label) => (
                  <Checkbox
                    key={label}
                    label={label}
                    checked={newPayloadForHistology?.invasive_carcinoma?.includes(label)}
                    onChange={(event) => {
                      const currentValues = newPayloadForHistology?.invasive_carcinoma
                        .split(", ")
                        .filter(Boolean) || [];

                      let updatedValues: string[] = currentValues;

                      if (event.target.checked) {
                        // Add the new selection if not already included
                        if (!updatedValues.includes(label)) {
                          updatedValues.push(label);
                        }
                      } else {
                        // Remove the specific selection
                        updatedValues = updatedValues.filter((val) => val !== label);
                      }

                      setNewPayloadForHistology((prev) => ({
                        ...prev,
                        invasive_carcinoma: updatedValues.join(", "),
                      }));
                    }}
                  />
                ))}
              </div>
            ) : null}


          </div>

          <ESectionDivider title="Any additional findings (not listed above)" />
          <ETextArea
            className=""
            value={newPayloadForHistology?.any_additional_findings}
            placeholder={"Any additional findings (not listed above)"}
            maxLength={10}
            onChange={(e) =>
              setNewPayloadForHistology((prev) => ({
                ...prev,
                any_additional_findings: e.target.value,
              }))
            }
          />
        </div>

        {/* </div>
        ))} */}
      </div>
      <div className="col-span-3 px-3 ">
        <div
          className="mx-5 p-5 w-full flex flex-col items-center justify-center border border-slate-400 border-dashed  rounded-md cursor-pointer"
          onClick={() => {
            setUploadFileModalIsOpen(true);
          }}
        >
          <MdOutlinePermMedia size={33} />
          <div className="mt-2 text-lg font-semibold">Upload Image / PDF</div>
        </div>

        <div className="mt-5">
          {mediaFiles.length > 0 ? (
            <ESectionDivider title="Uploaded Media" />
          ) : (
            <></>
          )}
          {mediaFiles?.map((item, index) => {
            return (
              <EMediaItem
                key={index + "media"}
                item={item}
                handleOpenMediaInNewTab={handleOpenMediaInNewTab}
                setIsOpenDeleteConfirmModal={setIsOpenDeleteConfirmModal}
                isOpenDeleteConfirmModal={isOpenDeleteConfirmModal}
                handleDeleteImageFromAws={handleDeleteImageFromAws}
              />
            );
          })}
        </div>
      </div>

      <EModal
        isOpen={uploadFileModalIsOpen}
        title={"Upload Media"}
        size="sm"
        setIsOpen={() => {
          setUploadFileModalIsOpen(false);
        }}
        onClose={()=>{
          setFiles([]);
        }}
      >
        <div className="flex justify-end">
          <div className="flex-row">
            <Dropzone
              className="w-80"
              onDrop={(file) => {
                setFiles(file);
              }}
              accept={[
                MIME_TYPES.png,
                MIME_TYPES.jpeg,
                MIME_TYPES.webp,
                MIME_TYPES.pdf,
                MIME_TYPES.heic
              ]}
            >
              <div className="flex justify-center flex-col items-center text-center">
                <MdOutlinePermMedia size={33} />
                {files?.length > 0 ? (
                  <p>Upload Again Picture / pdf</p>
                ) : (
                  <p>Upload Picture / pdf</p>
                )}
              </div>
            </Dropzone>

            <div className="mt-4 max-h-44 overflow-y-scroll">
              {files?.map((file, index) => {
                return (
                  <div
                    key={index + "files"}
                    className="gap-2 lowercase text-sm font-medium"
                  >
                    {index + 1 + ". " + file.name}
                  </div>
                );
              })}
            </div>

            {!files?.length ? (
              <></>
            ) : (
              <div className=" flex gap-3 justify-around mt-8">
                <EButton
                  variant="secondary"
                  className="mt-4"
                  onClick={() => {
                    setFiles([]);
                    setUploadFileModalIsOpen(false);
                  }}
                >
                  Cancel
                </EButton>
                <EButton
                  className="mt-4"
                  loading={isMediaBtnLoading}
                  onClick={() => handleUploadFiles(files)}
                >
                  <HiOutlineArrowUpTray className="mr-2" />
                  Upload Media
                </EButton>
              </div>
            )}
          </div>
        </div>
      </EModal>
    </div>
  );
}
