import React, { useContext, useEffect, useState } from "react";
import { IGallStonesFormDataPayload } from "../../../../../types";
import { PatientContext } from "../../../PatientsContext";
import { showToast } from "../../../../../utils/utility";
import { useDirectusApi } from "../../../../../libs/data-access";
import { useSearchParams } from "react-router-dom";

export default function useGallStonesForm() {
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");
  const { postDirectData, fetchDirectData, patchDirectData } = useDirectusApi();
  const {
    activeTab,
    isBtnNextClick,
    setIsBtnDisable,
    setIsBtnLoading,
    handleNextTab,
    isFormUpdating,
    setIsFormUpdating,
  } = useContext(PatientContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [gallStoneID, setGallStoneID] = useState<number | undefined>(undefined);

  const [documentedOn, setDocumentedOn] = React.useState<
    { label: string; value: number; selected: boolean }[]
  >([
    { label: "US", value: 1, selected: false },
    { label: "CT", value: 2, selected: false },
    { label: "MRI", value: 3, selected: false },
    { label: "Surgery", value: 4, selected: false },
  ]);

  const [symptomStatus, setSymptomStatus] = React.useState<
    { label: string; value: number; selected: boolean }[]
  >([
    { label: "None", value: 1, selected: false },
    { label: "Dyspepsia", value: 4, selected: false },
    { label: "Fat intolerance", value: 2, selected: false },
    {
      label: "Biliary colic (intense epigastric pain lasting >30 mins)",
      value: 7,
      selected: false,
    },
    { label: "Acute Cholecystitis", value: 8, selected: false },
    { label: "Biliary obstruction", value: 5, selected: false },
    { label: "Cholangitis", value: 3, selected: false },
    { label: "Acute pancreatitis", value: 6, selected: false },
    { label: "GBC", value: 9, selected: false },
  ]);

  const [severity, setSeverity] = React.useState<
    { label: string; value: number; selected: boolean }[]
  >([
    { label: "Mild", value: 1, selected: false },
    { label: "Moderate", value: 2, selected: false },
    { label: "Severe", value: 3, selected: false },
  ]);

  const [painDescription, setPainDescription] = React.useState<
    { label: string; value: number; selected: boolean }[]
  >([
    { label: "Acute", value: 1, selected: false },
    { label: "Insidious", value: 2, selected: false },
  ]);

  const [typeOfPain, setTypeOfPain] = React.useState<
    { label: string; value: number; selected: boolean; remarks: string }[]
  >([
    { label: "Constant", value: 1, selected: false, remarks: "" },
    { label: "Colic", value: 2, selected: false, remarks: "" },
  ]);

  const [typeOfPainRemarks, setTypeOfPainRemarks] = React.useState<string>("");

  const [painLocation, setPainLocation] = React.useState<
    { label: string; value: string; selected: boolean }[]
  >([
    { label: "Right hypochondrium", value: "", selected: false },
    { label: "Epigastric pain", value: "", selected: false },
    { label: "Upper abdomen", value: "", selected: false },
    { label: "Chest", value: "", selected: false },
    { label: "Upper back", value: "", selected: false },
    { label: "Others", value: "", selected: false },
  ]);

  const [painIntensity, setPainIntensity] = React.useState<
    { label: string; value: number; selected: boolean }[]
  >([
    { label: "Mild", value: 1, selected: false },
    { label: "Moderate", value: 2, selected: false },
    { label: "Severe", value: 3, selected: false },
  ]);

  const [painDuration, setPainDuration] = React.useState<
    { label: string; value: number; selected: boolean; remarks: string }[]
  >([
    { label: "<15 min", value: 1, selected: false, remarks: "" },
    { label: "15 min - 6 hrs", value: 2, selected: false, remarks: "" },
    { label: ">6 hrs", value: 3, selected: false, remarks: "" },
    { label: "Exact time", value: 4, selected: false, remarks: "" },
  ]);
  const [painRadiation, setPainRadiation] = React.useState<
    { label: string; value: string; selected: boolean }[]
  >([
    { label: "No radiation", value: "", selected: false },
    { label: "Back", value: "", selected: false },
    { label: "Interscapular", value: "", selected: false },
    { label: "Shoulder", value: "", selected: false },
    { label: "Others", value: "", selected: false },
  ]);

  const [precipitatedBy, setPrecipitatedBy] = React.useState<
    { label: string; value: string; selected: boolean }[]
  >([
    { label: "None", value: "", selected: false },
    { label: "Fatty meal", value: "", selected: false },
    { label: "Heavy meal", value: "", selected: false },
    { label: "Meal after prolonged fasting", value: "", selected: false },
    { label: "Others", value: "", selected: false },
  ]);

  const [nausea, setNausea] = React.useState<boolean>(false);
  const [vomiting, setVomiting] = React.useState<boolean>(false);

  const [painType, setPainType] = React.useState<
    { label: string; value: number; selected: boolean }[]
  >([
    { label: "Persistent", value: 1, selected: false },
    { label: "Intermittent", value: 2, selected: false },
  ]);

  const [descriptionOfSymptoms, setDescriptionOfSymptoms] =
    React.useState<string>("");

  const [frequency, setFrequency] = React.useState<
    { label: string; value: number; selected: boolean }[]
  >([
    { label: "Daily", value: 1, selected: false },
    { label: "Weekly", value: 2, selected: false },
    { label: "Monthly", value: 3, selected: false },
    { label: "Yearly", value: 4, selected: false },
    { label: "More than annual", value: 5, selected: false },
  ]);

  const [frequencyMoreThanAnnualDetails, setFrequencyMoreThanAnnualDetails] =
    React.useState<string>("");

  const [reliefOfPain, setReliefOfPain] = React.useState<
    { label: string; value: string; selected: boolean }[]
  >([
    { label: "Spontaneous", value: "spontaneous", selected: false },
    { label: "Home remedies", value: "home_remedies", selected: false },
    { label: "Oral medication", value: "oral_medication", selected: false },
    { label: "IV drugs", value: "iv_drugs", selected: false },
  ]);

  const [hospitalization, setHospitalization] = React.useState<"Required" | "Not Required" | "">("");
  const [totalNoOfHospitalization, setTotalNoOfHospitalization] =
    useState<string>("");

  const [associatedSymptoms, setAssociatedSymptoms] = React.useState<
    { label: string; value: number; selected: boolean }[]
  >([
    { label: "Jaundice", value: 5, selected: false },
    { label: "Pruritis", value: 10, selected: false },
    { label: "Fever/Rigors", value: 7, selected: false },
    { label: "Clay colored stools", value: 2, selected: false },
  ]);

  const [stoolFreq, setStoolFreq] = React.useState<string>("");
  const [bristolStoolScore, setBristolStoolScore] = React.useState<{
    usual: string;
    worst: string;
  }>({
    usual: "",
    worst: "",
  });

  const [textInputs, setTextInputs] = useState({
    whenSurgeryDetected: "",
    durationOfStones: "",
    howWasItDetected: "",
    howWasItDetectedRemarks: "",
    durationOfSymptoms: "",
    frequencyOfSymptomsPerMonth: "",
  });

  const [wasSurgeryAdvised, setWasSurgeryAdvised] = useState<{
    status: boolean | null;
    when: string;
  }>({ status: false, when: "" });
  const [wasSurgeryDone, setWasSurgeryDone] = useState<{
    status: boolean | null;
    when: string;
  }>({ status: false, when: "" });
  const [gallstoneStatus, setGallstoneStatus] = useState<string>("");

  const [symptomsOfDyspepsia, setSymptomsOfDyspepsia] = React.useState<
    { label: string; selected: boolean; remarks: string; value: number }[]
  >([
    {
      label: "Bothersome epigastric pain",
      selected: false,
      remarks: "",
      value: 1,
    },
    {
      label: "Bothersome epigastric burning",
      selected: false,
      remarks: "",
      value: 2,
    },
    {
      label: "Bothersome early satiety",
      selected: false,
      remarks: "",
      value: 3,
    },
    {
      label: "Bothersome postprandial fullness",
      selected: false,
      remarks: "",
      value: 4,
    },
    {
      label: "Overall duration of symptoms ≥ 3m",
      selected: false,
      remarks: "",
      value: 5,
    },
    {
      label: "Criteria fulfilled for ≥ 6m",
      selected: false,
      remarks: "",
      value: 6,
    },
  ]);

  const [dyspepsiaScoringIndex, setDyspepsiaScoringIndex] = React.useState<
    { label: string; selected: boolean; score: string }[]
  >([
    { label: "Epigastric pain", selected: false, score: "" },
    { label: "Retrosternal pain", selected: false, score: "" },
    { label: "Regurgitation", selected: false, score: "" },
    { label: "Nausea", selected: false, score: "" },
    {
      label: "Vomiting",
      selected: false,
      score: "",
    },
    { label: "Belching", selected: false, score: "" },
    { label: "Early Satiety", selected: false, score: "" },
    { label: "Dysphagia", selected: false, score: "" },
  ]);

  const [finalLeadScoreForDyspepsia, setFinalLeadScoreForDyspepsia] =
    useState<string>("");

  const [scoringIndex, setScoringIndex] = React.useState<
    { label: string; selected: boolean; score: string }[]
  >([
    { label: "Very mild", selected: false, score: "1" },
    { label: "Mild", selected: false, score: "2" },
    { label: "Moderate", selected: false, score: "3" },
    { label: "Severe", selected: false, score: "4" },
    { label: "Very severe", selected: false, score: "5" },
  ]);

  const [painAtNight, setPainAtNight] = useState<string>("");
  const [painAtExertion, setPainAtExertion] = useState<string>("");
  const [gallstoneFrequency, setGallstoneFrequency] = useState<{
    day: string;
    week: string;
    month: string;
    year: string;
    inFrequent: string;
  }>({
    day: "",
    week: "",
    month: "",
    year: "",
    inFrequent: "",
  });

  useEffect(() => {
    setIsBtnDisable(false);
    if (isBtnNextClick && activeTab == "gallstone") {
      handleSubmitGallstone();
    }
  }, [isBtnNextClick]);

  useEffect(() => {
    if (activeTab == "gallstone") {
      getDetailsByVisitId();
    }
  }, [activeTab]);

  useEffect(() => {
    let count = 0;
    let dataArr = dyspepsiaScoringIndex.filter((e) => e?.selected);
    dataArr?.forEach((each) => {
      count = each?.score ? count + +each?.score : count;
    });
    setFinalLeadScoreForDyspepsia(
      count ? String(Math.round(count)) : ""
    );
  }, [dyspepsiaScoringIndex]);

  const handlePayloadForGallStonesForm = async (
    userId: string,
    visit_id: string
  ) => {
    let payload: IGallStonesFormDataPayload = {
      user_id: userId,
      visit_id: visit_id,
      gs_first_detected: textInputs?.whenSurgeryDetected,
      duration_of_stone: textInputs?.durationOfStones,
      how_was_it_detected: textInputs?.howWasItDetected,
      how_was_it_detected_remarks: textInputs?.howWasItDetectedRemarks,
      duration_of_symptoms: textInputs?.durationOfSymptoms,
      frequency_of_symptoms_per_month: textInputs?.frequencyOfSymptomsPerMonth,
      pain_at_exertion: painAtExertion,
      nausea: nausea,
      vomiting: vomiting,
      pain_at_night: painAtNight,
      documented_on: documentedOn
        ?.filter((item) => item.selected)
        .map((item) => item?.label?.toLowerCase()?.replaceAll(" ", "_")),
      symptom_status: symptomStatus
        ?.filter((item) => item.selected)
        .map((item) => item?.label?.toLowerCase()?.replaceAll(" ", "_")),
      type_of_pain: typeOfPain
        ?.filter((item) => item.selected)
        .map((item) => item?.label?.toLowerCase()?.replaceAll(" ", "_")),
      type_of_pain_remarks: typeOfPainRemarks,
      description_of_symptoms: descriptionOfSymptoms,
      duration: painDuration
        ?.filter((item) => item.selected)
        .map((item) =>
          item.label == "Exact time"
            ? item?.remarks
              ? "Others-" + item?.remarks
              : ""
            : item?.label
        ),
      associated_symptoms: associatedSymptoms
        ?.filter((item) => item.selected)
        .map((item) => item?.label?.toLowerCase()?.replaceAll(" ", "_")),
      severity: severity
        ?.filter((item) => item.selected)[0]
        ?.label?.toLowerCase()
        ?.replaceAll(" ", "_"),
      location: painLocation
        ?.filter((item) => item.selected)
        .map((item) =>
          item.label == "Others"
            ? item.value
              ? "Others-" + item?.value
              : ""
            : item.label
        ),
      pain_description: painDescription
        ?.filter((item) => item.selected)
        .map((item) => item?.label?.toLowerCase()?.replaceAll(" ", "_")),
      intensity: [
        painIntensity
          ?.filter((item) => item.selected)[0]
          ?.label?.toLowerCase()
          ?.replaceAll(" ", "_"),
      ],
      radiation: painRadiation
        ?.filter((item) => item.selected)
        .map((item) =>
          item.label == "Others"
            ? item.value
              ? "Others-" + item?.value
              : ""
            : item?.label
        ),
      precipitated_by: precipitatedBy
        ?.filter((item) => item.selected)
        .map((item) =>
          item.label == "Others"
            ? item.value
              ? "Others-" + item?.value
              : ""
            : item?.label
        ),
      pain: painType
        ?.filter((item) => item.selected)[0]
        ?.label?.toLowerCase()
        ?.replaceAll(" ", "_"),
      frequency: gallstoneFrequency?.inFrequent
        ? "infrequent, " + gallstoneFrequency?.inFrequent
        : gallstoneFrequency?.day +
          "times/day, " +
          gallstoneFrequency?.week +
          "times/week, " +
          gallstoneFrequency?.month +
          "times/month, " +
          gallstoneFrequency?.year +
          "times/year",
      frequency_more_than_annual_details: frequencyMoreThanAnnualDetails,
      relief_of_pain: reliefOfPain
        ?.filter((item) => item.selected)
        .map((item) => item?.label?.toLowerCase()?.replaceAll(" ", "_")),
      when_was_surgey_advised: wasSurgeryAdvised?.when,
      why_not_done_surgery: wasSurgeryDone.when,
      is_require_hospitalization: hospitalization,
      total_no_of_hospitalizations: totalNoOfHospitalization,
      stool_frequency: stoolFreq,
      bristol_stool_usual_score: bristolStoolScore?.usual,
      bristol_stool_worst_score: bristolStoolScore?.worst,
      gallstone_status: gallstoneStatus,
      symptoms_of_dyspepsia: symptomsOfDyspepsia
        ?.filter((e) => e?.selected)
        ?.map((e) => ({
          symptoms_name: e?.label,
          is_symptom: e?.selected,
          remarks: e?.remarks,
        })),
      dyspepsia_scoring_index: dyspepsiaScoringIndex
        ?.filter((e) => e?.selected)
        .map((e) => ({
          dyspepsia_name: e?.label,
          status: e?.selected,
          score: e?.score,
        })),
      dyspepsia_final_score: finalLeadScoreForDyspepsia ?? "",
    };
    return payload;
  };

  //  APi Function

  const getDetailsByVisitId = async () => {
    setIsLoading(true);
    const res = await fetchDirectData(
      `items/gallstones_history?filter[visit_id][_eq]=${visit_id}`
    );
    if (res.isSuccess) {
      if (res?.data?.length) {
        setIsFormUpdating(true);
        let data: IGallStonesFormDataPayload = res?.data[0];
        setGallStoneID(data?.id);
        setPainAtNight(data?.pain_at_night);
        setPainAtExertion(data?.pain_at_exertion);

        let inFrequentFrequency = data?.frequency?.includes("infrequent")
          ? data?.frequency?.split(",")?.slice(1).join(",")
          : "";

        let frequentFrequency = inFrequentFrequency
          ? []
          : data?.frequency?.split(",");

        let frequency = {
          day: frequentFrequency?.[0] ? frequentFrequency?.[0] : "0",
          week: frequentFrequency?.[1] ? frequentFrequency?.[1] : "0",
          month: frequentFrequency?.[2] ? frequentFrequency?.[2] : "0",
          year: frequentFrequency?.[3] ? frequentFrequency?.[3] : "0",
          inFrequent: inFrequentFrequency ? inFrequentFrequency : "",
        };

        setGallstoneFrequency(frequency);
        setTextInputs({
          durationOfSymptoms: data?.duration_of_symptoms,
          frequencyOfSymptomsPerMonth: data?.frequency_of_symptoms_per_month,
          whenSurgeryDetected: data?.gs_first_detected,
          howWasItDetectedRemarks: data?.how_was_it_detected_remarks,
          howWasItDetected: data?.how_was_it_detected,
          durationOfStones: data?.duration_of_stone,
        });
        setStoolFreq(data?.stool_frequency);
        setBristolStoolScore({
          usual: data?.bristol_stool_usual_score,
          worst: data?.bristol_stool_worst_score,
        });
        setGallstoneStatus(data?.gallstone_status);
        if (data?.documented_on?.length) {
          let documented = documentedOn;
          data?.documented_on.forEach((e) => {
            const index = documented.findIndex(
              (each) => each?.label.toLowerCase() === e
            );
            documented[index].selected = true;
          });
          setDocumentedOn(documented);
        }
        if (data?.symptom_status?.length) {
          let stateData = symptomStatus;
          data?.symptom_status?.forEach((e) => {
            const index = stateData.findIndex(
              (each) => each?.label.toLowerCase().replaceAll(" ", "_") == e
            );
            stateData[index].selected = true;
          });
          setSymptomStatus(stateData);
        }
        if (data?.severity?.length) {
          let stateData = severity;
          const index = stateData.findIndex(
            (each) =>
              each?.label.toLowerCase().replaceAll(" ", "_") == data?.severity
          );
          stateData[index].selected = true;
          setSeverity(stateData);
        }
        if (data?.pain_description?.length) {
          let stateData = painDescription;
          data?.pain_description?.forEach((e) => {
            const index = stateData.findIndex(
              (each) => each?.label.toLowerCase().replaceAll(" ", "_") == e
            );
            stateData[index].selected = true;
          });
          setPainDescription(stateData);
        }
        if (data?.type_of_pain?.length) {
          let stateData = typeOfPain;
          data?.type_of_pain?.forEach((e) => {
            const index = stateData.findIndex(
              (each) => each?.label.toLowerCase().replaceAll(" ", "_") == e
            );
            stateData[index].selected = true;
          });
          setTypeOfPain(stateData);
        }

        setTypeOfPainRemarks(data?.type_of_pain_remarks);

        setDescriptionOfSymptoms(data?.description_of_symptoms);

        if (data?.location?.length) {
          let stateData = painLocation;
          const parseData: string[] = JSON.parse(data?.location as any);
          parseData?.forEach((e: any) => {
            if (e?.includes("Other")) {
              stateData[stateData.length - 1].selected = true;
              stateData[stateData.length - 1].value = e.split("-")[1];
            } else {
              const index = stateData.findIndex(
                (each) =>
                  each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                  e?.toLowerCase()?.replaceAll(" ", "_")
              );
              stateData[index].selected = true;
            }
          });
          setPainLocation(stateData);
        }
        if (data?.intensity?.length) {
          let stateData = painIntensity;
          data?.intensity?.forEach((e: string) => {
            const index = stateData.findIndex(
              (each) =>
                each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                e?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].selected = true;
          });
          setPainIntensity(stateData);
        }
        if (data?.duration?.length) {
          let stateData = painDuration;
          let parseData: string[] = JSON.parse(data?.duration as any);
          parseData?.forEach((e: string) => {
            if (e?.includes("Other")) {
              stateData[stateData.length - 1].selected = true;
              stateData[stateData.length - 1].remarks = e.split("-")[1];
            } else {
              const index = stateData.findIndex(
                (each) =>
                  each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                  e?.toLowerCase()?.replaceAll(" ", "_")
              );
              stateData[index].selected = true;
            }
          });
          setPainDuration(stateData);
        }
        if (data?.radiation?.length) {
          let stateData = [...painRadiation];
          const parseData: string[] = JSON.parse(data?.radiation as any);
          parseData.forEach((e: string) => {
            if (e?.includes("Others")) {
              stateData[stateData.length - 1].selected = true;
              stateData[stateData.length - 1].value = e?.split("-")[1];
            } else {
              const index = stateData.findIndex(
                (each) =>
                  each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                  e?.toLowerCase()?.replaceAll(" ", "_")
              );
              stateData[index].selected = true;
            }
          });
          setPainRadiation(stateData);
        }

        setNausea(data?.nausea ?? false);
        setVomiting(data?.vomiting ?? false);

        if (data?.precipitated_by?.length) {
          let stateData = precipitatedBy;
          const parseData: string[] = JSON.parse(data?.precipitated_by as any);

          parseData?.forEach((e: string) => {
            if (e?.includes("Other")) {
              stateData[stateData.length - 1].selected = true;
              stateData[stateData.length - 1].value = e.split("-")[1];
            } else {
              const index = stateData.findIndex(
                (each) =>
                  each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                  e?.toLowerCase()?.replaceAll(" ", "_")
              );
              stateData[index].selected = true;
            }
          });
          setPrecipitatedBy(stateData);
        }

        setFrequencyMoreThanAnnualDetails(
          data?.frequency_more_than_annual_details ?? ""
        );

        if (data?.relief_of_pain?.length) {
          let stateData = reliefOfPain;
          data?.relief_of_pain?.forEach((e: string) => {
            const index = stateData.findIndex(
              (each) =>
                each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                e?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].selected = true;
          });
          setReliefOfPain(stateData);
        }

        setHospitalization(data?.is_require_hospitalization as any ?? "");
        setTotalNoOfHospitalization(data?.total_no_of_hospitalizations ?? 0);

        if (data?.associated_symptoms?.length) {
          let stateData = associatedSymptoms;
          data?.associated_symptoms?.forEach((e: string) => {
            const index = stateData.findIndex(
              (each) =>
                each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                e?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].selected = true;
          });
          setAssociatedSymptoms(stateData);
        }
        if (data?.symptoms_of_dyspepsia?.length) {
          let stateData = symptomsOfDyspepsia;
          data?.symptoms_of_dyspepsia?.forEach((e) => {
            const index = stateData.findIndex(
              (each) =>
                each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                e?.symptoms_name?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].selected = true;
            stateData[index].remarks = e?.remarks;
          });
          setSymptomsOfDyspepsia(stateData);
        }
        setFinalLeadScoreForDyspepsia(data?.dyspepsia_final_score ?? 0);

        if (data?.dyspepsia_scoring_index?.length) {
          let stateData = dyspepsiaScoringIndex;
          data?.dyspepsia_scoring_index?.forEach((e) => {
            const index = stateData.findIndex(
              (each) =>
                each?.label?.toLowerCase()?.replaceAll(" ", "_") ==
                e?.dyspepsia_name?.toLowerCase()?.replaceAll(" ", "_")
            );
            stateData[index].selected = true;
            stateData[index].score = e?.score;
          });
          setDyspepsiaScoringIndex(stateData);
        }

        setWasSurgeryAdvised({
          status: data?.when_was_surgey_advised ? true : false,
          when: data?.when_was_surgey_advised ?? "",
        });
        setWasSurgeryDone({
          status: data?.why_not_done_surgery ? false : true,
          when: data?.why_not_done_surgery ?? "",
        });
      } else {
        setIsFormUpdating(false);
      }
    } else {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
    }
    setIsLoading(false);
  };
  const handleSubmitGallstone = async () => {
    if (patient_id && visit_id) {
      let payload: IGallStonesFormDataPayload =
        await handlePayloadForGallStonesForm(patient_id, visit_id);
      setIsBtnLoading(true);
      if (isFormUpdating) {
        const res = await patchDirectData(
          `items/gallstones_history/${gallStoneID}`,
          payload
        );
        if (res.isSuccess) {
          showToast({
            type: "success",
            title: "gallstones history updated successfully",
            message: "Gallstones history Updated",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            message: res?.message || "Failed to add gallstones history",
          });
        }
      } else {
        const res = await postDirectData(`items/gallstones_history`, payload);
        if (res.isSuccess) {
          showToast({
            type: "success",
            title: "Added gallstones history successfully",
            message: "Gallstones history added",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            message: res?.message || "Failed to add gallstones history",
          });
        }
      }
      setIsBtnLoading(false);
    } else {
      showToast({
        type: "error",
        title: "Please try again",
        message: "User id not found !",
      });
    }
  };

  return {
    documentedOn,
    setDocumentedOn,
    symptomStatus,
    setSymptomStatus,
    severity,
    setSeverity,
    painDescription,
    setPainDescription,
    typeOfPain,
    setTypeOfPain,
    typeOfPainRemarks,
    setTypeOfPainRemarks,
    painLocation,
    setPainLocation,
    painIntensity,
    setPainIntensity,
    painDuration,
    setPainDuration,
    painRadiation,
    setPainRadiation,
    precipitatedBy,
    setPrecipitatedBy,
    textInputs,
    setTextInputs,
    vomiting,
    setVomiting,
    nausea,
    setNausea,
    frequency,
    setFrequency,
    stoolFreq,
    setStoolFreq,
    bristolStoolScore,
    setBristolStoolScore,
    wasSurgeryAdvised,
    setWasSurgeryAdvised,
    setGallstoneStatus,
    gallstoneStatus,
    wasSurgeryDone,
    setWasSurgeryDone,
    reliefOfPain,
    setReliefOfPain,
    hospitalization,
    setHospitalization,
    totalNoOfHospitalization,
    setTotalNoOfHospitalization,
    associatedSymptoms,
    setAssociatedSymptoms,
    descriptionOfSymptoms,
    setDescriptionOfSymptoms,
    frequencyMoreThanAnnualDetails,
    setFrequencyMoreThanAnnualDetails,
    symptomsOfDyspepsia,
    setSymptomsOfDyspepsia,
    dyspepsiaScoringIndex,
    setDyspepsiaScoringIndex,
    finalLeadScoreForDyspepsia,
    scoringIndex,
    painAtNight,
    setPainAtNight,
    painAtExertion,
    setPainAtExertion,
    gallstoneFrequency,
    setGallstoneFrequency,
  };
}
