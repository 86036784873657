import { useEffect, useRef } from "react";
import PageLayout from "../../PageLayout";
import { EButton } from "../../libs/ui";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Avatar,
  BackgroundImage,
  Grid,
  Tabs,
} from "@mantine/core";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { PatientNewVisitContext } from "./PatientsContext";
import usePatientVisit from "./hook/usePatientVisit";
import ClinicalHistoryForm from "./Pages/ClinicalHistory/Components/ClinicalHistoryForm";
import GallstonesForm from "./Pages/ClinicalHistory/Components/GallstonesForm";
import InvestigationForm from "./Pages/Investigation/Components/InvestigationForm";
import CostEffectiveAnalysis from "./Pages/CostEffectiveAnalysis/CostEffectiveAnalysis";
import { bgBodyColor } from "../../DefaultValue";
import QualityOfLife from "./Pages/QualityOfLife/QualityOfLife";
import GBCClinicalPresentation from "./Pages/GBCClinicalPresentation/GBCClinicalPresentation";
import Radiology from "./Pages/Investigation/Components/Radiology";

export default function PatientVisit() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");
  
  const {
    isBtnLoading,
    setIsBtnLoading,
    isFormUpdating,
    setIsFormUpdating,
    isBtnBackClick,
    setIsBackBtnClick,
    isBtnNextClick,
    setIsNextBtnClick,
    progress,
    setProgress,
    activeTab,
    setActiveTab,
    setColorScheme,
    handleNextTab,
    handleBackTab,
    tabsValue,
    activeSubTab,
    setActiveSubTab,
    patientDetails,
    fetchPatientDetails,
  } = usePatientVisit();

  const viewport = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchPatientDetails();
  }, []);

  useEffect(() => {
    if (activeTab) {
      setColorScheme({ head: bgBodyColor[activeTab], body: bgBodyColor[activeTab] + 50, text: "", footer: "" })
    }
    else {
      setColorScheme({ head: "", body: "", text: "", footer: "" })
    }
  }, [activeTab])

  return (
    <PatientNewVisitContext.Provider
      value={{
        activeTab,
        setActiveTab,
        activeSubTab,
        setActiveSubTab,
        isBtnLoading,
        setIsBtnLoading,
        isFormUpdating,
        setIsFormUpdating,
        progress,
        setProgress,
        isBtnBackClick,
        isBtnNextClick,
        handleBackTab,
        handleNextTab,
      }}
    >
      <Tabs
        value={activeTab}
        variant="pills"
        onChange={(e) => {
          if (viewport.current && e == "investigation") {
            viewport.current.scrollTo({
              left: viewport.current.scrollWidth,
              behavior: "smooth",
            });
          }
          if (viewport.current && e == "anthropometric") {
            viewport.current.scrollTo({ left: 0, behavior: "smooth" });
          }
          setActiveTab(e ?? "");
          setActiveSubTab(1);
        }}
      >
        <PageLayout
          header={
            <BackgroundImage src={""} className="">
              <div className="flex justify-between items-center">
                <div className="flex flex-row items-end gap-4 p-4">
                  <Avatar size="xl" radius="sm" className="bg-white" />
                  <div className="flex flex-col">
                    <div className="text-2xl font-semibold">
                      {(patientDetails?.first_name || "") +
                        " " +
                        (patientDetails?.last_name || "")}
                    </div>
                    <div className="text-base font-medium text-slate-600">
                      {patientDetails?.primary_contact_no || "+91____________"}
                    </div>
                  </div>
                </div>
                <div className="self-start pt-4">
                  {/* <p className="text-slate-400 text-xl">You are in Visit: 1</p> */}
                  <EButton
                    size="md"
                    onClick={() => navigate(`/index/patient/details?patient_id=${patient_id}`)}
                  >
                    <BsArrowLeft /> Back
                  </EButton>
                </div>
              </div>
              <div className="flex gap-1 items-center">
                <FaChevronLeft
                  className="cursor-pointer"
                  onClick={() =>
                    viewport.current &&
                    viewport.current.scrollTo({ left: 0, behavior: "smooth" })
                  }
                />
                <Tabs.List
                  style={{
                    flexWrap: "nowrap",
                    overflowX: "scroll",
                    marginBottom: "-15px",
                  }}
                  ref={viewport}
                >
                  {/* HIDE PERSONAL HISTORY FOR VISIT FLOW */}
                  {/* if you want to uncomment then plz uncomment from the variable (defaultTabsValue) also. */}

                  {/* <Tabs.Tab
                    value="personal_history"
                    disabled={
                      tabsValue?.filter(
                        (e) => e?.label === "personal_history"
                      )?.[0]?.disable
                    }
                  >
                    Personal History
                  </Tabs.Tab> */}
                  <Tabs.Tab
                    value="clinical_history"
                    disabled={
                      tabsValue?.filter(
                        (e) => e?.label === "clinical_history"
                      )?.[0]?.disable
                    }
                  >
                    Clinical History
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="gallstone"
                    disabled={
                      tabsValue?.filter((e) => e?.label === "gallstone")?.[0]
                        ?.disable
                    }
                  >
                    Gall Stone
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="gbc_clinical_presentation"
                    disabled={
                      tabsValue?.filter(
                        (e) => e?.label === "gbc_clinical_presentation"
                      )?.[0]?.disable
                    }
                  >
                    GBC Clinical Presentation
                  </Tabs.Tab>

                  {/* HIDE PERSONAL HISTORY FOR VISIT FLOW */}

                  {/* <Tabs.Tab
                    value="dietary_determinate"
                    disabled={
                      tabsValue?.filter(
                        (e) => e?.label === "dietary_determinate"
                      )?.[0]?.disable
                    }
                  >
                    Dietary Determinate
                  </Tabs.Tab> */}

                  {/* <Tabs.Tab
                    value="anthropometric"
                    disabled={
                      tabsValue?.filter(
                        (e) => e?.label === "anthropometric"
                      )?.[0]?.disable
                    }
                  >
                    Clinical Examination
                  </Tabs.Tab> */}

                  <Tabs.Tab
                    value="investigation"
                    disabled={
                      tabsValue?.filter(
                        (e) => e?.label === "investigation"
                      )?.[0]?.disable
                    }
                  >
                    Investigation
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="radiology"
                    disabled={
                      tabsValue?.filter((e) => e?.label === "radiology")?.[0]
                        ?.disable
                    }
                  >
                    Radiology
                  </Tabs.Tab>

                  {/* HIDE PERSONAL HISTORY FOR VISIT FLOW ,
                 If you want to uncomment then plz uncomment from the variable (defaultTabsValue) also. */}

                  {/* <Tabs.Tab
                    value="surgery"
                    disabled={
                      tabsValue?.filter((e) => e?.label === "surgery")?.[0]
                        ?.disable
                    }
                  >
                    Surgery
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="histology"
                    disabled={
                      tabsValue?.filter((e) => e?.label === "histology")?.[0]
                        ?.disable
                    }
                  >
                    Histology
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="stone_analysis"
                    disabled={
                      tabsValue?.filter((e) => e?.label === "stone_analysis")?.[0]
                        ?.disable
                    }
                  >
                    Stone Analysis
                  </Tabs.Tab> */}
                  <Tabs.Tab
                    value="cost_effective_analysis"
                    disabled={
                      tabsValue?.filter((e) => e?.label === "cost_effective_analysis")?.[0]
                        ?.disable
                    }
                  >
                    Cost effective analysis
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="quality_of_life"
                    disabled={
                      tabsValue?.filter((e) => e?.label === "quality_of_life")?.[0]
                        ?.disable
                    }
                  >
                    Quality of Life
                  </Tabs.Tab>

                </Tabs.List>
                <FaChevronRight
                  className="cursor-pointer mx-2"
                  onClick={() =>
                    viewport.current &&
                    viewport.current.scrollTo({
                      left: viewport.current.scrollWidth,
                      behavior: "smooth",
                    })
                  }
                />
              </div>
            </BackgroundImage>
          }
          footer={
            <div className="p-2 flex items-center justify-end gap-2">
              {/* <EButton 
                className="btn-blue"
                size="md"
                disabled={activeTab === "personal_history" ? true : false}
                onClick={() => {
                  handleBackTab()
                  setIsBackBtnClick(!isBtnBackClick)
                }
                }
              >
                Back
              </EButton> */}
              <EButton
                className="btn-blue"
                size="md"
                // disabled={activeTab ==="clinical_history" || isBtnDisable}
                loading={isBtnLoading}
                onClick={() => {
                  setIsNextBtnClick(!isBtnNextClick);
                  setTimeout(() => {
                    setIsNextBtnClick(false);
                  }, 100);
                }}
              >
                {isFormUpdating ? "Update" : "Submit"}
              </EButton>
            </div>
          }
        >
          <Grid>
            <Grid.Col span={12} className="pr-5  mt-4">
              {/* <Tabs.Panel value="personal_history">
                <PersonalHistory
                  viewOnly={
                    tabsValue?.filter(
                      (e) => e?.label === "personal_history"
                    )?.[0]?.viewOnly
                  }
                />
              </Tabs.Panel> */}


              <Tabs.Panel value="clinical_history">
                <ClinicalHistoryForm />
              </Tabs.Panel>

              <Tabs.Panel value="gallstone">
                <GallstonesForm />
              </Tabs.Panel>

              <Tabs.Panel value="gbc_clinical_presentation">
                <GBCClinicalPresentation />
              </Tabs.Panel>

              {/* <Tabs.Panel value="anthropometric">
                <AnthropometricMeasurementForm />
              </Tabs.Panel> */}

              {/* <Tabs.Panel value="dietary_determinate">
                <DietaryDeterminants
                  viewOnly={
                    tabsValue?.filter(
                      (e) => e?.label === "dietary_determinate"
                    )?.[0]?.viewOnly
                  }
                />
              </Tabs.Panel> */}

              <Tabs.Panel value="investigation">
                <InvestigationForm />
              </Tabs.Panel>

              <Tabs.Panel value="radiology">
                {/* <ImageAndCharacteristicsOfGB /> */}
                <Radiology />
              </Tabs.Panel>

              {/* <Tabs.Panel value="surgery">
                <Surgery />
              </Tabs.Panel>

              <Tabs.Panel value="histology">
                <Histology />
              </Tabs.Panel>

              <Tabs.Panel value="stone_analysis">
                <StoneAnalysis />
              </Tabs.Panel> */}

              <Tabs.Panel value="cost_effective_analysis">
                <CostEffectiveAnalysis />
              </Tabs.Panel>
              <Tabs.Panel value="quality_of_life">
                <QualityOfLife />
              </Tabs.Panel>


            </Grid.Col>
            <Grid.Col span={3} className="p-5">
              {/* <div>
                <p className='text-slate-400 mt-4 pt-3 border-t-2'>last updated by</p>
                <p><span className='text-blue-600 font-semibold'>Dr. Ajay Sharma</span> on 20/11/2023 at 4:15 pm</p>
              </div> */}
            </Grid.Col>
          </Grid>
        </PageLayout>
      </Tabs>
    </PatientNewVisitContext.Provider>
  );
}
