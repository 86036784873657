import { Table } from "@mantine/core";
import "./ETable.scss";

type Props = {
  hasEndButtons?: boolean;
  children: JSX.Element | JSX.Element[];
};

export function ETable({ children, hasEndButtons }: Props) {
  return (
    <Table.ScrollContainer minWidth={800}>
      <Table
        highlightOnHover
        verticalSpacing={"xs"}
        withColumnBorders
        striped
        // withColumnBorders={true}
        className={"e-table " + (hasEndButtons ? "end-btns" : "")}
      >
        {children}
      </Table>
    </Table.ScrollContainer>
  );
}
