import { Select, TextInput, TextInputProps } from '@mantine/core'
import React from 'react'

interface ETextInputProps extends Omit<TextInputProps, 'value' | 'onChange'> {
    value: string | undefined
    onChange: (value: string) => void
    splitValue: string | "per " | "/ "
    options: {label: string, value: string}[];

}
export function ETextInputWithSelect(props:ETextInputProps) {
  return (
    <TextInput 
    label={props?.label +"/"+ (props.value ? props?.value?.split(props.splitValue)[1] || "" : "" )}
    size={props.size}
    rightSectionWidth={props.rightSectionWidth}
    className={props.className}
    type={props.type}
    maxLength={props.maxLength ? props.maxLength : 100}
    placeholder={props.placeholder}
    error={props.error}
    required={props.required}
    disabled={props.disabled}
    readOnly={props.readOnly}
    value={props.value?.split(props.splitValue)[0]?.replaceAll("undefined", "")}
    onChange={(event)=>   
    {   event?.target.value.length > 0 ?
        props?.value?.split(props.splitValue)[1] ?   
        props.onChange(event?.target.value + props.splitValue + (props?.value?.split(props.splitValue)[1])) :
        props.onChange(event?.target.value + props.options[0].value) :
        props.onChange("")
    }
    }
    rightSection={
        <select
        value={props.splitValue+props?.value?.split(props.splitValue)[1]?.replaceAll("undefined", "")}
        defaultValue={props.options[0].value}
        onChange={(e) => {
          props.onChange(props?.value?.split(props.splitValue)[0] + e.target.value)
        }}
        className="mr-12 bg-white border-l border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500   p-2.5 w-fit"
        >
          {props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
    }
    />
  )
}
