import React from "react";
import "./EStat.scss";
import { EBadge } from "../EBadge/EBadge";
import { CommaSeparator } from "../../../data-access/utility";
import { TbArrowDownSquare } from "react-icons/tb";
import { BsDownload } from "react-icons/bs";
import { Menu } from "@mantine/core";

interface IProps {
  iconSrc?: string;
  stat: number;
  label: string;
  text?: string;
  hideDownload?: boolean
  onBaselineDownload?: () => void
  onFollowupDownload?: () => void
}

export function EStat({ iconSrc, stat, label, text, hideDownload, onBaselineDownload, onFollowupDownload }: IProps) {
  return (
    <div className="stat-container">
      <div className=" flex gap-2 p-0.5 items-center">
        <div className={`icon`}>
          {iconSrc
            ?
            <img className="" src={iconSrc} alt="" />
            :
            <p>
              {text}
            </p>
          }

        </div>
        <div>
          <div className="flex flex-row gap-2 items-center">
            <div className="stat">{CommaSeparator(stat ?? 0)}</div>
          </div>
          <div className="label">{label}</div>
        </div>
        <div className="relative">
        </div>
      </div>
      {
        hideDownload ? <></>:<Menu>
        <Menu.Target>
          <div
            className="flex gap-2 items-center hover:text-blue-500 cursor-pointer border border-white rounded-md	p-2 hovr:bg-white/20">
            <BsDownload />
            Download
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => { onBaselineDownload && onBaselineDownload() }}>
            <div className="flex gap-2 items-center hover:text-blue-500 cursor-pointer rounded-md	p-2 hovr:bg-white/20">
              Baseline
              <BsDownload />
            </div>
          </Menu.Item>
          <Menu.Item onClick={() => { onFollowupDownload && onFollowupDownload() }}>
            <div className="flex gap-2 items-center hover:text-blue-500 cursor-pointer rounded-md	p-2 hovr:bg-white/20">
              Followup
              <BsDownload />
            </div>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>}
      {/* {
        onBaselineDownload && onFollowupDownload ?
          <div className="flex flex-row  gap-2 text-sm font-medium text-slate-600">
            <div
              onClick={() => {
                onBaselineDownload()
              }}
              className="flex flex-col items-center hover:text-blue-500 cursor-pointer border border-white rounded-md	p-1 hovr:bg-white/20">
              Baseline
              <BsDownload />
            </div>
            <div
              onClick={() => {
                onFollowupDownload()
              }}
              className="flex flex-col items-center hover:text-blue-500 cursor-pointer border border-white rounded-md	p-1 hovr:bg-white/20">
              Followup
              <BsDownload />
            </div>
          </div>
          : <></>
      } */}

    </div>
  );
}
