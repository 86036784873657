import { TimeInput, TimeInputProps } from '@mantine/dates'
import React from 'react'

export function ETimeInput(props:TimeInputProps) {
  return (
    <TimeInput
    label={props.label}
    size={props.size}
    className={props.className}
    type={props.type}
    description={props.description}
    value={props.value}
    placeholder={props.placeholder}
    error={props.error}
    onChange={props.onChange}
    />
  )
}
