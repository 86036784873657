import moment from "moment";
import { ENumberInput, ESectionDivider, ETextInput } from "../../../../libs/ui";
import EDateInput from "../../../../libs/ui/src/DateInput/EDateInput";
import useCostEffectiveAnalysis from "./hooks/useCostEffectiveAnalysis";
import { LoadingOverlay, TextInput } from "@mantine/core";

export default function CostEffectiveAnalysis() {
  const {
    opdVisit,
    ipdVisit,
    isLoading,
    ipdVisitDefaultValue,
    opdVisitDefaultValue,
    setOpdVisit,
    setIpdVisit,
    handleIpdVisitValue,
    handleOpdVisitValue,
  } = useCostEffectiveAnalysis();

  return (
    <div className="flex flex-col gap-3">
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ color: "blue", type: "bars" }}
      />
      <ESectionDivider
        title="Out of Pocket"
        subTitle="Expenditure (OPD visit)"
      />

      {opdVisit?.map((opd, index) => {
        return (
          <div
            className={`${opd?.bgColor} px-4 pb-4 rounded-md`}
            key={index + "opd"}
          >
            <ESectionDivider
              title="Out of Pocket"
              subTitle={"Expenditure (OPD)" + ` ${index + 1}`}
            />
            <EDateInput
              size="md"
              label="Date"
              placeholder="Select date"
              className="w-fit my-2"
              value={
                opdVisit[index]?.date
                  ? new Date(opdVisit[index]?.date)
                  : new Date()
              }
              onChange={(value) => {
                handleOpdVisitValue(
                  index,
                  "date",
                  moment(value).format("YYYY-MM-DD")
                );
              }}
            />
            <div className="flex flex-wrap gap-3">
              <ENumberInput
                maxLength={5}
                size="md"
                label="Travelling cost"
                placeholder="Travelling cost"
                min={0}
                value={opdVisit[index]?.travelling_cost}
                onChange={(e) => {
                  handleOpdVisitValue(index, "travelling_cost", e);
                }}
              />

              <ENumberInput
                size="md"
                min={0}
                maxLength={5}
                label="Distance (km)"
                placeholder="Enter Distance (km)"
                value={opdVisit[index]?.distance}
                onChange={(e) => {
                  handleOpdVisitValue(index, "distance", e);
                }}
              />

              <ETextInput
                size="md"
                label="Vehicle"
                placeholder="Enter Vehicle"
                value={opdVisit[index]?.vehicle}
                onChange={(e) => {
                  handleOpdVisitValue(index, "vehicle", e?.target?.value);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Medicines"
                placeholder="Medicines"
                // readOnly={viewOnly}
                value={opdVisit[index]?.medicines}
                onChange={(e) => {
                  handleOpdVisitValue(index, "medicines", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Lab tests/ Diagnostics"
                placeholder="Lab tests/ Diagnostics"
                // readOnly={viewOnly}
                value={opdVisit[index]?.lab_tests_diagnostics}
                onChange={(e) => {
                  handleOpdVisitValue(index, "lab_tests_diagnostics", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Procedure/Surgery"
                placeholder="Procedure/Surgery"
                // readOnly={viewOnly}
                value={opdVisit[index]?.procedure_surgery}
                onChange={(e) => {
                  handleOpdVisitValue(index, "procedure_surgery", e);
                }}
              />
            </div>
            <ENumberInput
              maxLength={5}
              size="md"
              className="w-1/2"
              label="User fees/Hospital charges (including bed charges, diagnostics etc.)"
              placeholder="User fees/Hospital charges (including bed charges, diagnostics etc.)"
              // readOnly={viewOnly}
              value={opdVisit[index]?.user_fees}
              onChange={(e) => {
                handleOpdVisitValue(index, "user_fees", e);
              }}
            />
            <div className="flex flex-wrap gap-3">
              <ENumberInput
                maxLength={5}
                size="md"
                label="Informal payment"
                placeholder="Informal payment"
                // readOnly={viewOnly}
                value={opdVisit[index]?.informal_payment}
                onChange={(e) => {
                  handleOpdVisitValue(index, "informal_payment", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Boarding/Lodging/Food"
                placeholder="Boarding/Lodging/Food"
                // readOnly={viewOnly}
                value={opdVisit[index]?.boarding_lodging_food}
                onChange={(e) => {
                  handleOpdVisitValue(index, "boarding_lodging_food", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Other1"
                placeholder="Other1"
                // readOnly={viewOnly}
                value={opdVisit[index]?.other1}
                onChange={(e) => {
                  handleOpdVisitValue(index, "other1", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Other2"
                placeholder="Other2"
                // readOnly={viewOnly}
                value={opdVisit[index]?.other2}
                onChange={(e) => {
                  handleOpdVisitValue(index, "other2", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Other3"
                placeholder="Other3"
                // readOnly={viewOnly}
                value={opdVisit[index]?.other3}
                onChange={(e) => {
                  handleOpdVisitValue(index, "other3", e);
                }}
              />
            </div>

            <ENumberInput
              maxLength={5}
              size="md"
              label="Total"
              className="w-1/4"
              placeholder="Total"
              readOnly
              value={Object.keys(opdVisit[index]).reduce((acc, curr) => {
                return curr !== "bgColor" &&
                  curr !== "date" &&
                  curr !== "distance" &&
                  curr !== "vehicle"
                  ? // @ts-ignore
                    acc + Number(opdVisit[index][curr])
                  : acc;
              }, 0)}
            />
          </div>
        );
      })}

      <div
        className="text-blue-500 underline cursor-pointer w-fit hover:text-blue-700"
        onClick={() => {
          setOpdVisit([
            ...opdVisit,
            {
              ...opdVisitDefaultValue,
            },
          ]);
        }}
      >
        Add New OPD Visit
      </div>

      {ipdVisit?.map((ipd, index) => {
        return (
          <div
            className={`${ipd?.bgColor} px-4 pb-4 rounded-md`}
            key={index + "ipd"}
          >
            <ESectionDivider
              title="Out of Pocket"
              subTitle={"Expenditure (IPD)" + ` ${index + 1}`}
            />
            <EDateInput
              size="md"
              label="Date"
              placeholder="Select date"
              className="w-fit my-2"
              value={
                ipdVisit[index]?.date
                  ? new Date(ipdVisit[index]?.date)
                  : new Date()
              }
              onChange={(value) => {
                handleIpdVisitValue(
                  index,
                  "date",
                  moment(value).format("YYYY-MM-DD")
                );
              }}
            />
            <div className="flex flex-wrap gap-3">
              <ENumberInput
                maxLength={5}
                size="md"
                label="Travelling cost"
                placeholder="Travelling cost"
                min={0}
                value={ipdVisit[index]?.travelling_cost}
                onChange={(e) => {
                  handleIpdVisitValue(index, "travelling_cost", e);
                }}
              />

              <ENumberInput
                size="md"
                min={0}
                maxLength={5}
                label="Distance (km)"
                placeholder="Enter Distance (km)"
                value={ipdVisit[index]?.distance}
                onChange={(e) => {
                  handleIpdVisitValue(index, "distance", e);
                }}
              />

              <ETextInput
                size="md"
                label="Vehicle"
                placeholder="Enter Vehicle"
                value={ipdVisit[index]?.vehicle}
                onChange={(e) => {
                  handleIpdVisitValue(index, "vehicle", e?.target?.value);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Medicines"
                placeholder="Medicines"
                // readOnly={viewOnly}
                value={ipdVisit[index]?.medicines}
                onChange={(e) => {
                  handleIpdVisitValue(index, "medicines", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Lab tests/ Diagnostics"
                placeholder="Lab tests/ Diagnostics"
                // readOnly={viewOnly}
                value={ipdVisit[index]?.lab_tests_diagnostics}
                onChange={(e) => {
                  handleIpdVisitValue(index, "lab_tests_diagnostics", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Procedure/Surgery"
                placeholder="Procedure/Surgery"
                // readOnly={viewOnly}
                value={ipdVisit[index]?.procedure_surgery}
                onChange={(e) => {
                  handleIpdVisitValue(index, "procedure_surgery", e);
                }}
              />
            </div>
            <ENumberInput
              maxLength={5}
              size="md"
              className="w-1/2"
              label="User fees/Hospital charges (including bed charges, diagnostics etc.)"
              placeholder="User fees/Hospital charges (including bed charges, diagnostics etc.)"
              // readOnly={viewOnly}
              value={ipdVisit[index]?.user_fees}
              onChange={(e) => {
                handleIpdVisitValue(index, "user_fees", e);
              }}
            />
            <div className="flex flex-wrap gap-3">
              <ENumberInput
                maxLength={5}
                size="md"
                label="Informal payment"
                placeholder="Informal payment"
                // readOnly={viewOnly}
                value={ipdVisit[index]?.informal_payment}
                onChange={(e) => {
                  handleIpdVisitValue(index, "informal_payment", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Boarding/Lodging/Food"
                placeholder="Boarding/Lodging/Food"
                // readOnly={viewOnly}
                value={ipdVisit[index]?.boarding_lodging_food}
                onChange={(e) => {
                  handleIpdVisitValue(index, "boarding_lodging_food", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Other1"
                placeholder="Other1"
                // readOnly={viewOnly}
                value={ipdVisit[index]?.other1}
                onChange={(e) => {
                  handleIpdVisitValue(index, "other1", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Other2"
                placeholder="Other2"
                // readOnly={viewOnly}
                value={ipdVisit[index]?.other2}
                onChange={(e) => {
                  handleIpdVisitValue(index, "other2", e);
                }}
              />

              <ENumberInput
                maxLength={5}
                size="md"
                label="Other3"
                placeholder="Other3"
                // readOnly={viewOnly}
                value={ipdVisit[index]?.other3}
                onChange={(e) => {
                  handleIpdVisitValue(index, "other3", e);
                }}
              />
            </div>

            <ENumberInput
              maxLength={5}
              size="md"
              label="Total"
              className="w-1/4"
              placeholder="Total"
              readOnly
              value={Object.keys(ipdVisit[index]).reduce((acc, curr) => {
                return curr !== "bgColor" &&
                  curr !== "date" &&
                  curr !== "distance" &&
                  curr !== "vehicle"
                  ? // @ts-ignore
                    acc + Number(ipdVisit[index][curr])
                  : acc;
              }, 0)}
            />
          </div>
        );
      })}

      <div
        className="text-blue-500 underline cursor-pointer w-fit hover:text-blue-700"
        onClick={() => {
          setIpdVisit([
            ...ipdVisit,
            {
              ...ipdVisitDefaultValue,
            },
          ]);
        }}
      >
        Add New IPD Visit
      </div>
    </div>
  );
}
