import { useContext, useEffect, useState } from "react"
import { AppContext } from "../../../AppContext";
import { useDirectusApi } from "../../../libs/data-access";
import { showToast } from "../../../utils/utility";
import { ICenterDetails } from "../../../libs/data-access/types";
 
export type IDoctorRole = {
  name:string;
  role:string;
};
export default function useUserManagement() {
  const { fetchDirectData ,patchDirectData } = useDirectusApi()
  const {
    user
  } = useContext(AppContext);

  useEffect(() => {
    getUserDetails()
  }, [])


  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
  const [isDeleteIconVisible, setIsDeleteIconVisible] = useState<boolean>(false);
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState<boolean>(false);
  const [isOpenNewDoctorModal, setIsOpenNewDoctorModal] = useState<boolean>(false);
  const [center, setCenter] = useState<ICenterDetails>()
  const [newDoctor, setNewDoctor] = useState<IDoctorRole>({name:"",role:""});
  //  API Function
   
 const  updateDoctorForCenter= async({role,name} : {role:string,name:string})=>{
     if(role){
       setIsBtnLoading(true)
       const res = await patchDirectData(`items/center_profile/${center?.id}`, {
       [role] : name
       })
       if(res?.isSuccess){
         showToast({
           type: "success",
           title: "Successfully",
           message: "Doctor modified successfully",
         })
         setIsOpenNewDoctorModal(false);
       }
       else{
         showToast({
           type: "error",
           title: "Something went wrong",
           message: "Please try again",
         })
       }
       getUserDetails();
       setNewDoctor({role:"",name:""})
       setIsBtnLoading(false)

     }
     else{
       showToast({
         type: "error",
         title: "Something went wrong",
         message: "Please select role",
       })
     }
 }
  const getUserDetails = async () => {
    setIsLoading(true)
    const res = await fetchDirectData(`items/center_profile?filter[user_id][_eq]=${user?.id}`);
    if(res?.isSuccess) {
      setCenter(res?.data[0])
    }
    else{
      showToast({
        type: "error",
        message: "Error encountered while fetching user details.",
      })
    }
    setIsLoading(false)
  }

  return {
    isLoading,
    isBtnLoading,
    setIsBtnLoading,
    isDeleteIconVisible,
    setIsDeleteIconVisible,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal,
    isOpenNewDoctorModal,
    setIsOpenNewDoctorModal,
    user,
    center,
    newDoctor,
    setNewDoctor,
    updateDoctorForCenter,
  }
}
