import { useContext, useEffect, useState } from "react";
import { useDirectusApi } from "../../../../../libs/data-access";
import { showToast } from "../../../../../utils/utility";
import { PatientNewVisitContext } from "../../../PatientsContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  ICostEffectiveAnalysis,
  IIpdVisit,
  IOpdVisit,
} from "../../../../../types";
import moment from "moment";

export default function useCostEffectiveAnalysis() {
  const { fetchDirectData, postDirectData, patchDirectData } = useDirectusApi();
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");
  const navigate = useNavigate();
  const location = useLocation();

  const {
    activeTab,
    setActiveTab,
    activeSubTab,
    setActiveSubTab,
    isFormUpdating,
    setIsFormUpdating,
    isBtnNextClick,
    handleBackTab,
    setIsBtnLoading,
    handleNextTab,
  } = useContext(PatientNewVisitContext);

  const handleGetRandomBackgroundColor = () => {
    const colors = [
      "bg-yellow-200",
      "bg-green-200",
      "bg-blue-200",
      "bg-red-200",
      "bg-purple-200",
      "bg-pink-200",
      "bg-indigo-200",
      "bg-teal-200",
      "bg-orange-200",
      "bg-gray-200",
      "bg-slate-200",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    console.log("Color:", colors[randomIndex]);
    return colors[randomIndex];
  };

  let ipdVisitDefaultValue = {
    travelling_cost: "",
    medicines: "",
    lab_tests_diagnostics: "",
    procedure_surgery: "",
    user_fees: "",
    informal_payment: "",
    boarding_lodging_food: "",
    other1: "",
    other2: "",
    other3: "",
    distance: "",
    vehicle: "",
    date: moment().format("YYYY-MM-DD"),
    bgColor: handleGetRandomBackgroundColor(),
  };
  let opdVisitDefaultValue = {
    travelling_cost: "",
    medicines: "",
    lab_tests_diagnostics: "",
    procedure_surgery: "",
    user_fees: "",
    informal_payment: "",
    boarding_lodging_food: "",
    other1: "",
    other2: "",
    other3: "",
    distance: "",
    vehicle: "",
    date: moment().format("YYYY-MM-DD"),
    bgColor: handleGetRandomBackgroundColor(),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [costEffectiveAnalysis, setCostEffectiveAnalysis] =
    useState<ICostEffectiveAnalysis>({} as ICostEffectiveAnalysis);
  const [ipdVisit, setIpdVisit] = useState<IIpdVisit[]>([
    { ...ipdVisitDefaultValue },
  ]);
  const [opdVisit, setOpdVisit] = useState<IOpdVisit[]>([
    { ...opdVisitDefaultValue },
  ]);

  useEffect(() => {
    if (activeTab == "cost_effective_analysis") {
      getCostAnalysis();
    }
  }, [activeTab]);

  useEffect(() => {
    if (isBtnNextClick && activeTab == "cost_effective_analysis") {
      postNewCostEffectiveAnalysis();
    }
  }, [isBtnNextClick]);
  const handleSetCostEffectiveAnalysisValue = (key: string, value: any) => {
    setCostEffectiveAnalysis({
      ...costEffectiveAnalysis,
      [key]: value,
    });
  };

  const postNewCostEffectiveAnalysis = async () => {
    setIsBtnLoading(true);
    const ipdVisitWithoutBgColor = ipdVisit.map(({ bgColor, ...rest }) => rest);
    const opdVisitWithoutBgColor = opdVisit.map(({ bgColor, ...rest }) => rest);
    let payload = {
      ...costEffectiveAnalysis,
      ipd_visit: ipdVisitWithoutBgColor,
      opd_visit: opdVisitWithoutBgColor,
      visit_id: visit_id,
    };
    if (isFormUpdating) {
      const res = await patchDirectData(
        `items/cost_effective_analysis/${costEffectiveAnalysis.id}`,
        payload
      );
      if (res?.isSuccess) {
        showToast({
          type: "success",
          title: "Successfully",
          message: "Cost Effective Analysis updated successfully",
        });
        handleNextTab();

        // navigate(`/index/patient/details?patient_id=${patient_id}`);
      } else {
        showToast({
          type: "error",
          title: "error",
          message:
            res.message || "Error encountered while updating clinical history.",
        });
      }
    } else {
      const res = await postDirectData(
        `items/cost_effective_analysis`,
        payload
      );
      if (res?.isSuccess) {
        showToast({
          type: "success",
          title: "Successfully",
          message: "Cost Effective Analysis updated successfully",
        });
        handleNextTab();
        // if (location?.pathname.includes("new-visit")) {
        //   navigate(`/index/patient/details?patient_id=${patient_id}`);
        // } else {
        //   navigate(`/index`);
        // }
      } else {
        showToast({
          type: "error",
          title: "error",
          message:
            res.message || "Error encountered while updating clinical history.",
        });
      }
    }
    setIsBtnLoading(false);
  };

  const getCostAnalysis = async () => {
    const res = await fetchDirectData(
      `items/cost_effective_analysis/?filter[visit_id][_eq]=${visit_id}`
    );
    setIsLoading(true);
    if (res.isSuccess) {
      if (res?.data?.length) {
        setCostEffectiveAnalysis(res.data[0]);
        let ipdVisit = res.data[0]?.ipd_visit
          ? res.data[0]?.ipd_visit?.map((item: IIpdVisit) => ({
              ...item,
              bgColor: handleGetRandomBackgroundColor(),
            }))
          : [ipdVisitDefaultValue];
        let opdVisit = res.data[0]?.opd_visit
          ? res.data[0]?.opd_visit?.map((item: IOpdVisit) => ({
              ...item,
              bgColor: handleGetRandomBackgroundColor(),
            }))
          : [opdVisitDefaultValue];

        setIpdVisit(ipdVisit);
        setOpdVisit(opdVisit);
        setIsFormUpdating(true);
      } else {
        setIsFormUpdating(false);
      }
    } else {
      showToast({
        type: "error",
        message: "Something went wrong",
      });
      setIsFormUpdating(false);
    }
    setIsLoading(false);
  };

  const handleIpdVisitValue = (
    index: number,
    key: string,
    value: number | string
  ) => {
    setIpdVisit((prev) => {
      const newIpdVisit = [...prev];
      newIpdVisit[index] = { ...newIpdVisit[index], [key]: value };
      return newIpdVisit;
    });
  };

  const handleOpdVisitValue = (
    index: number,
    key: string,
    value: number | string
  ) => {
    setOpdVisit((prev) => {
      const newOpdVisit = [...prev];
      newOpdVisit[index] = { ...newOpdVisit[index], [key]: value };
      return newOpdVisit;
    });
  };

  return {
    isLoading,
    ipdVisitDefaultValue,
    opdVisitDefaultValue,
    costEffectiveAnalysis,
    ipdVisit,
    setIpdVisit,
    opdVisit,
    setOpdVisit,
    handleIpdVisitValue,
    handleOpdVisitValue,
    postNewCostEffectiveAnalysis,
    handleGetRandomBackgroundColor,
    handleSetCostEffectiveAnalysisValue,
  };
}
