import { MdDelete, MdDeleteOutline } from "react-icons/md";
import PageLayout from "../../PageLayout";
import { EButton, EEmpty, ELabelView, EModal, EPageTitle, ESectionDivider, ESelect, ETextInput } from "../../libs/ui";
import useUserManagement from "./hooks/useUserManagement";
import { FaUserDoctor } from "react-icons/fa6";
import EMediaItem from "../../libs/ui/src/EMediaItem";
import { Button, Tooltip } from "@mantine/core";
import { ID } from "aws-sdk/clients/s3";
import { ICenterDetails } from "../../libs/data-access/types";

const DoctorListItem = ({ doctors, handleDelete }: { doctors: string | undefined; handleDelete : (remainingDoctors: string)=>void }) => {
  return (
    <div className="border-r">
      {
        !doctors?.split(",")?.length ?
          <div className="flex justify-between items-center m-3 ml-0 p-5 bg-emerald-100 rounded-md">
            <p className="font-semibold text-xs">No Doctor Allocated</p>
          </div> :
          doctors?.split(",")?.map((e, index) =>
            <div className="flex justify-between items-center m-3 ml-0 p-2 bg-emerald-200 rounded-md">
              <div className="flex gap-2 items-center">
                <FaUserDoctor size={28} color="green" />
                <div>
                  <p className="font-bold">{e}</p>
                  <p className="text-sm text-white" >---</p>
                </div>
              </div>
              <div>
                <MdDeleteOutline size={24} color="red" className="cursor-pointer" 
                onClick={()=>{
                  let remainingDoctor = doctors?.split(",");
                  remainingDoctor?.splice(index, 1);
                  handleDelete( remainingDoctor?.length ? remainingDoctor?.join(",") : "" )
                }}
                /></div>

            </div>
          )}
    </div>
  )
}
export default function UserManagement() {

  const {
    isLoading,
    isBtnLoading,
    user,
    center,
    newDoctor,
    setNewDoctor,
    updateDoctorForCenter,
    isDeleteIconVisible,
    setIsDeleteIconVisible,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal,
    isOpenNewDoctorModal,
    setIsOpenNewDoctorModal
  } = useUserManagement();
  return (
    <PageLayout
      header={
        <EPageTitle
          title="User Management"
          end={
            <EButton
              onClick={() => setIsOpenNewDoctorModal(true)}
            >
              Add New Doctor
            </EButton>
          }
        />
      }
    >
      <div className="border rounded-md ">
        <div className="p-5 text-emerald-800 bg-emerald-400 shadow">
          <h2 className="w-full text-center capitalize">{center?.center_name}</h2>
          <div className="mt-4 flex justify-between">
            <p className="text-lg font-semibold"><span className="text-base text-white">Contact  No. : </span>{center?.contact_no}</p>
            <p className="text-lg font-semibold"><span className="text-base text-white">Hospital No. : </span>{center?.hospital_no}</p>
            <p className="text-lg font-semibold"><span className="text-base text-white">Landline No. : </span>{center?.landline_no}</p>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-5 pt-0 p-12">
          <div>
            <ESectionDivider title={"Physician"} />
            <DoctorListItem 
            doctors={center?.physician_in_charge ? center?.physician_in_charge : undefined}
            handleDelete={(remainingDoctors) =>{
              const isConfirmed = window.confirm("Are you sure you want to delete?");
              if(isConfirmed)
              updateDoctorForCenter({role:"physician_in_charge", name: remainingDoctors})
            }
            } 
            />
          </div>
          <div>
            <ESectionDivider title={"Radiologist"} />
            <DoctorListItem 
            doctors={center?.radiologist ? center?.radiologist : undefined}
            handleDelete={(remainingDoctors) =>{
              const isConfirmed = window.confirm("Are you sure you want to delete?");
              if(isConfirmed)
              updateDoctorForCenter({role:"radiologist", name: remainingDoctors})
            }
            }
            />
          </div>
          <div>
            <ESectionDivider title={"Surgeon"} />
            <DoctorListItem 
            doctors={center?.surgeon ? center?.surgeon : undefined}
             handleDelete={(remainingDoctors) => {
              const isConfirmed = window.confirm("Are you sure you want to delete?");
              if(isConfirmed)
               updateDoctorForCenter({role:"surgeon", name: remainingDoctors})
             }
            }
            />
          </div>
        </div>
      </div>

      <EModal
        isOpen={isOpenNewDoctorModal}
        onClose={() => {
          setNewDoctor({ name: "", role: "" })
          setIsOpenNewDoctorModal(false)
        }}
        setIsOpen={() => { }}
        title="Details for new doctor"
      >
        <div>
          <ESelect
            label="Role"
            value={newDoctor?.role ?? null}
            size="md"
            placeholder="Select Role"
            data={[{ label: "Surgeon", value: "surgeon" }, { label: "Physician", value: "physician_in_charge" }, { label: "Radiologist", value: "radiologist" }]}
            onChange={(e => setNewDoctor(prev => ({ ...prev, role: e as any })))}
          />
          <ETextInput
            label="Name of the doctor"
            placeholder="Enter Name"
            size="md"
            value={newDoctor?.name}
            onChange={(e => setNewDoctor(prev => ({ ...prev, name: e?.target?.value })))}
          />
          <div className="flex justify-end mt-4">
            <EButton
              onClick={() => { updateDoctorForCenter({ role : newDoctor?.role , name: center ? center[newDoctor?.role as keyof ICenterDetails] ? center[newDoctor?.role as keyof ICenterDetails]+","+newDoctor?.name : newDoctor?.name : newDoctor?.name}) }}
              disabled={!newDoctor?.role || !newDoctor?.name?.trim()}
              isLoading={isBtnLoading}
            >
              Submit
            </EButton>
          </div>
        </div>
      </EModal>
    </PageLayout>
  )
}
