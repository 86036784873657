/*
 */
const API_URL = process.env.REACT_APP_PROD_URL;

// Production API URl
export const API = `https://admin.indiangallbladdernetwork.com/`;
export  const API_JAVA = `https://report.indiangallbladdernetwork.com/api/`

// Staging API URL
// export const API = `http://209.182.232.92:9055/`;

// Local APi
// export const API = `http://192.168.1.37:8055/`;
