/* API RESPONSE BODY */
export interface IResponse<T> {
  status: boolean;
  message: string;
  response: T;
}
export interface IDirectusResponse<T> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: any;
  request: any;
}

export interface IResponseFormat<T> {
  isSuccess: boolean;
  data: T;
  error: T;
  message?: string;
}

export const res: IResponseFormat<undefined> = {
  isSuccess: false,
  data: undefined,
  error: undefined,
  message: "",
};

/* LOGIN RESPONSE */
export interface ILoginDetails {
  user_Id: number;
  role_Id: number;
  role_Name: string;
  details?: ISchoolLoginDetails[] | IDepartmentUserDetails[];
}

export interface ISchoolLoginDetails {
  state_Name: string;
  district_Id: number;
  district_Name: string;
  block_Id: number;
  block_Name: string;
  cluster_School_Id: number;
  cluster_School_Name: string;
  cluster_School_Code: string;
  cluster_UDISE_Code: string;
  school_Id: number;
  school_Name: string;
  school_Code: string;
  udisE_Code: string;
  school_Contact: string;
  school_Address: string;
  medium: string;
  medium_Id: number;
  board_Id: number;
  board_Name: string | null;
  email_Id: string | null;
  school_Image_Url: string | null;
  phone_No: string | null;
  mobile_No: string | null;
  school_Longitude: string | null;
  school_Latitude: string | null;
}

export interface IMasterData {
  id: number;
  name: string;
}
// DEPARTMENT LOGIN
export interface IDepartmentUserDetails {
  state_Name: string | null;
  email_Id: any;
  mobile_No: any;
}
export interface IDistrictUserDetails {
  district_Id: number;
  district_Name: string;
  email_Id: string | null;
  mobile_No: string | null;
  state_Name: string;
}

export interface IHelpDeskUserDetails {
  full_Name: string;
  state_Name: string;
  district_Id: number | null;
  district_Name: string | null;
  email_Id: string | null;
  mobile_No: string | null;
}

// SCHOOL LOGIN
export interface ITeacherAllocationDetails {
  assignedClass: string | null;
  assignedSection: string | null;
  teacher: string;
}

// BLOCK LOGIN
export interface IBlockLoginDetails {
  block_Id: number;
  block_Name: string;
  district_Id: number;
  district_Name: string;
  email_Id: string | null;
  mobile_No: string | null;
  state_Name: string;
}

// Patient

export type ICenterDetails = {
  id: number;
  status: string;
  user_created: string;
  date_created: string;
  user_updated: string;
  date_updated: string;
  user_id: string;
  address: string;
  hospital_no: string;
  contact_no: string;
  whatsapp_no: string;
  landline_no: string;
  state: string;
  center_name: string;
  physician_in_charge: string;
  surgeon: string;
  radiologist: string;
};
export type IPatientInformation = {
  id?: number;
  user_id?: string;
  first_name: string;
  last_name: string;
  primary_contact_no: number | null;
  alternate_contact_no: number | null;
  secondary_alternate_contact_no: number | null;
  email: string;
  age: number | null;
  disease: string[];
  gender: "male" | "female" | "other";
  father_name: string;
  mother_name: string;
  address: string;
  house_no: string;
  locality: string;
  city: string;
  state: string;
  pin_code: number | null;
  center_id?: string;
  whatsapp_no: string;
  hospital_no: string;
  admission_no: string;
  ward_no: string;
  bed_no: string;
  physician_in_charge: string;
  surgeon_in_charge: string;
  radiology_in_charge: string;
  follow_ups: IPatientFollowupsDetails[];
  ultrasound_evidence_of_gs: string;
  ultrasound_evidence_of_gbc: string;
  definitive_diagnosis: string;
  severe_comorbidities: string;
  unwilling_for_fu: string;
  recruitment_date: string;
};

export interface IPatientFollowupsDetails {
  id: number;
  user_created: string;
  date_created: string;
  user_created_name: string;
  remarks: string;
}

export type IUserDataFromNewPatientApi = {
  patient_id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  visitId: string;
};
export type INewPersonalHistory_10YearsOfLife = {
  place_of_residence: string;
  state: string;
  code: number;
  water_resource: string;
};

export type INewPersonalHistory = {
  id?: string;
  user_id: string;
  visit_id: string;
  nearest_health_care_center: {
    name_of_the_tertiary_care_hospital: string;
    ultrasonology_place: string;
    surgery_place: string;
  }[];
  recruitment_method: string;
  state_of_residence: string;
  birth_state: string;
  residence_during_childhood: string;
  lives_in: string;
  marital_status: string;
  religion: string;
  ethnicity: string;
  no_of_adults_family_member: string;
  no_of_child_family_member: string;
  patient_education: string;
  patient_occupation: string;
  Socio_economic_class: string;
  kuppuswamy_scale_education_of_the_head: string;
  kuppuswamy_scale_occupation_of_the_head: string;
  kuppuswamy_scale_updated_monthly_family_income: string;
  vehicle: string;
  water_source: string;
  reproductive_h_per_o: {
    menarche: string;
    menopause: string;
    regular: boolean | null;
    age_at_marriage: string;
    age_at_first_child_birth: string;
    age_at_last_child_birth: string;
    total_no_of_children: string;
    total_no_of_abortions: string;
    breast_feed_months: string;
    gynaecological_surgery: boolean;
    which_surgery: string;
    age_at_which_surgery_done: string;
  }[];
  pregnancies_history: IPregnancy[];
  anthropometric_measurement: {
    height_cm: string;
    weight_kg: string;
    waist_circumference: string;
    hip_circumference_cm: string;
    bmi_kg_per_m_square: string;
    blood_pressure: string;
    tooth_count: string;
    mid_arm_circumference_cm: string;
    weight_height_ratio_kg_m_sqaure: string;
  }[];
};

export type ICancerMember = {
  first_degree_relative: string;
  age_at_diagnosis: number;
  type_of_cancer: string;
  treatment_received: string;
  stage_of_cancer: string;
};

export type IPregnancy = {
  duration_of_pregnancy: string;
  how_did_the_pregnancy_end: string;
  type_of_delivery: string;
  complications: string;
};

export type IGsMember = {
  first_degree_relative: string;
  gs: string;
  at_what_age: number;
  is_symptomatic: string;
  was_surgery_done: boolean;
  complication: string;
};
export type INewClinicalHistory = {
  id?: number;
  user_id: string;
  comorbidities: string;
  drug_history: { drug_name: string; nfc_status: string }[];
  typhoid: {
    typhoid_history: string;
    basis_of_diagnosis: string;
    treatment_received: string;
    years_back_count: number;
  }[];
  smoking: {
    nfc_status: string;
    is_regular: string;
    duration_in_years: number;
    amount_per_day: number;
    type_of_smoke: string;
  }[];
  alcohol: {
    nfc_status: string;
    is_regular: string;
    duration_in_years: number;
    amount_per_day: number;
    no_of_times_a_week: number;
    type_of_alcohol: string;
  }[];
  exposure_to_industry: string;
  pesticide_use: {
    nfc_status: string;
    for_how_long: string;
    which_one: string;
    personal_protective_gear_used: string;
  }[];
  no_of_brothers: string;
  no_of_sisters: string;
  no_of_sons: string;
  no_of_daughters: string;
  family_history: IGsMember[];
  family_history_of_cancer: ICancerMember[];
  is_family_history_of_gbc: boolean;
  is_family_history_of_liver_cancer: boolean;
  is_family_history_of_liver_cirrhosis: boolean;
  visit_id: string;
  visit_remarks: string;
};

export interface ICenterList {
  id: number;
  address: string;
  hospital_no: string;
  contact_no: string;
  whatsapp_no: string;
  landline_no: string;
  state: string;
  center_name: string;
  user_id: {
    email: string;
    id: string;
  };
}

export default interface IStats {
  total_patients: number;
  total_participants: number;
  total_centers: number;
  asymptomatic_gallstone_patients: number;
  symptomatic_gallstone_patients: number;
  gallbladder_cancer_patients: number;
  non_gallstone_control_patients: number;
}

export interface IVisitList {
  id: number;
  date_created: string;
  address: string;
  center_name: string;
  hospital_no: string;
  contact_no: string;
  whatsapp_no: string;
  landline_no: string;
  state: string;
  clinical_history: boolean;
  demographic_information: boolean;
  gallstones_history: boolean;
  anthropometric_measurements: boolean;
  dietary_determinants: boolean;
  investigations: boolean;
  imaging_characteristics_of_the_gallbladder: boolean;
  surgery_gs_patients: boolean;
  histology_gb_tissue: boolean;
  stone_analysis: boolean;
  cost_effective_analysis: boolean;
  radiology: boolean;
}
export interface IQualityOfLife {
  id?: string;
  mobility: string;
  self_care: string;
  usual_activity: string;
  pain_description: string;
  anxiety_depression: string;
  health_scale: string;
}

export interface IEmergencyContactList {
  remarks: string;
  id: number;
  date_created: string;
  audio: string;
  center_feedback: string;
  patient_profile_id: {
    first_name: string;
    last_name: string;
    primary_contact_no: string;
    alternate_contact_no: string;
    secondary_alternate_contact_no: string;
  };
}
