import { Grid, TextInput, Radio, Group, Textarea, Button, Badge, Modal } from '@mantine/core';
import { error } from 'console';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { BsArrowLeft, BsEye, BsFilePdf, BsImage } from 'react-icons/bs';
import { FiAlertCircle, FiCheckCircle, FiDownload, FiUpload } from 'react-icons/fi';
import { statesMaster } from '../../libs/data-access/utility';
import { EPageTitle, EButton, ESectionDivider, ETextInput, ENumberInput, ESelect, ECheckBox, EMultiSelect, EModal } from '../../libs/ui';
import EDateInput from '../../libs/ui/src/DateInput/EDateInput';
import EAgeInput from '../../libs/ui/src/NumberInput/EAgeInput';
import EPhoneInput from '../../libs/ui/src/NumberInput/EPhoneInput';
import PageLayout from '../../PageLayout';
import useEditPatientInformation from './hook/useEditPatientInformation';
import { useNavigate } from 'react-router-dom';
import { MdOutlinePermMedia } from 'react-icons/md';
import EMediaItem from '../../libs/ui/src/EMediaItem';
import { Dropzone, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { HiOutlineArrowUpTray } from 'react-icons/hi2';
import { CiCircleRemove } from 'react-icons/ci';
import { EBadge } from '../../libs/ui/src/EBadge/EBadge';

export default function EditpatientInformation() {

    const navigate = useNavigate();

    const { error,
        setUniqueNessCheck,
        patientDetails,
        handleError,
        checkUniqueNumber,
        uniqueNessCheck,
        centerDetails,
        isBtnLoading,
        isOpenDeleteConfirmModal,
        setIsOpenDeleteConfirmModal,
        handleChangeInfo,
        handleUploadFiles,
        consentFormUrl,
        handleOpenMediaInNewTab,
        handleDeleteImageFromAws,
        uploadFileModalIsOpen,
        setUploadFileModalIsOpen,
        setNewConsentForm,
        isMediaBtnLoading,
        newConsentForm,
        handleDownloadPDF,
        patient_id,
        handleUpdateInclusionStatus,
        inclusionStatus,
        setInclusionStatus,
        handelUpdate,
        setIsOpenInfoModal,
        isOpenInfoModal,
        updateInclusionStatus,
        setUpdateInclusionStatus,
        setColorScheme,
        handleGetPatientInformationDetails,
        getCenterDetails,

    } = useEditPatientInformation();

    const [selectedLanguage, setSelectedLanguage] = useState<string>("English");

    useEffect(() => {
        setColorScheme({ head: "", body: "", text: "", footer: "" });
        getCenterDetails();
        handleGetPatientInformationDetails();
    }, []);


    return (
        <PageLayout
            header={
                <EPageTitle
                    title="Update Patient Information"
                    end={
                        <>
                            <div className='cursor-pointer'
                                onClick={() => {
                                    setUpdateInclusionStatus(inclusionStatus);
                                    setIsOpenInfoModal(true)
                                }}
                            >
                                <Badge

                                    size='xl'
                                    color={inclusionStatus === "included"
                                        ? "green"
                                        : inclusionStatus === "excluded"
                                            ? "yellow"
                                            : "gray"
                                    }
                                //    onClick={() => handleUpdateInclusionStatus()}
                                >
                                    {inclusionStatus === "included" ? "Included" : inclusionStatus === "excluded" ? "Excluded" : "Pending"}

                                </Badge>
                            </div>
                            <EButton
                                className="btn-blue ml-4"
                                size="md"
                                onClick={() => {
                                    navigate(`/index/patient/details?patient_id=${patient_id}`)
                                }}
                            >
                                <BsArrowLeft className="" /> Back
                            </EButton>
                        </>
                    }
                />
            }
            footer={
                <div className="w-full flex justify-end  p-2">
                    <EButton
                        className="btn-blue"
                        size="md"
                        loading={isBtnLoading}
                        onClick={() => handelUpdate()}
                    >
                        Update
                    </EButton>
                </div>
            }
        >
            <Grid>
                <Grid.Col span={8} className="border-r p-5">
                    <ESectionDivider title="Personal Details" />
                    <Grid>
                        <Grid.Col span={6}>
                            <TextInput
                                label="First Name"
                                size="md"
                                required

                                error={error?.name}
                                placeholder="First Name"
                                value={patientDetails?.first_name?.trim()}
                                onChange={(e) => {
                                    handleError("name", "");
                                    handleChangeInfo("first_name", e?.target?.value?.trim());
                                }}
                                disabled
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                label="Last Name"
                                size="md"
                                placeholder="Last Name"

                                value={patientDetails?.last_name}
                                onChange={(e) =>
                                    handleChangeInfo("last_name", e?.target?.value)
                                }
                                disabled
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                label="Email"
                                size="md"
                                placeholder="Email"

                                value={patientDetails?.email}
                                onChange={(e) => {
                                    handleChangeInfo("email", e?.target?.value);
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Radio.Group
                                label="Gender"
                                size="md"
                                required
                                value={patientDetails?.gender}
                                onChange={(e) => handleChangeInfo("gender", e)}
                            >
                                <Group>
                                    <Radio label="Male" value="male" />
                                    <Radio label="Female" value="female" />
                                    <Radio label="Others" value="others" />
                                </Group>
                            </Radio.Group>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                size="md"
                                label="Father Name"

                                placeholder="Father Name"
                                value={patientDetails?.father_name}
                                onChange={(e) =>
                                    handleChangeInfo("father_name", e?.target?.value)
                                }
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                size="md"
                                label="Mother Name"

                                placeholder="Mother Name"
                                value={patientDetails?.mother_name}
                                onChange={(e) =>
                                    handleChangeInfo("mother_name", e?.target?.value)
                                }
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <EAgeInput
                                size="md"
                                label="Age"
                                required
                                min={0}
                                error={error?.age}
                                maxLength={3}
                                placeholder="Age"
                                value={patientDetails?.age || ""}
                                onChange={(e) => {
                                    handleError("age", "");
                                    handleChangeInfo("age", e);
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <EDateInput
                                size="md"
                                label="Date of Recruitment"
                                placeholder="Select Recruitment date"
                                value={
                                    patientDetails?.recruitment_date ? new Date(patientDetails?.recruitment_date) : undefined
                                }
                                onChange={(value) => {
                                    handleChangeInfo(
                                        "recruitment_date",
                                        moment(value).format("YYYY-MM-DD")
                                    );
                                }}
                            />

                        </Grid.Col>
                    </Grid>

                    <ESectionDivider classes="mt-8" title="Address Details" />
                    <Textarea
                        className="my-2"
                        required
                        label="Address"
                        error={error?.address}

                        size="md"
                        minRows={3}
                        value={patientDetails?.address}
                        onChange={(e) => {
                            handleError("address", "");
                            handleChangeInfo("address", e?.target?.value);
                        }}
                    />
                    <Grid>
                        <Grid.Col span={6}>
                            <ETextInput
                                size="md"
                                label="House No."

                                error={error?.houseNo}
                                required
                                placeholder="House No."
                                value={patientDetails?.house_no}
                                onChange={(e) => {
                                    handleError("houseNo", "");
                                    handleChangeInfo("house_no", e?.target?.value);
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                size="md"
                                label="Street Name"

                                placeholder="Locality"
                                error={error?.locality}
                                value={patientDetails?.locality}
                                onChange={(e) => {
                                    handleError("locality", "");
                                    handleChangeInfo("locality", e?.target?.value);
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <ENumberInput
                                size="md"
                                label="PIN Code"

                                required
                                hideControls
                                maxLength={6}
                                error={error?.postalCode}
                                placeholder="PIN Code"
                                value={patientDetails?.pin_code || ""}
                                onChange={(e) => {
                                    handleError("postalCode", "");
                                    handleChangeInfo("pin_code", e);
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                size="md"
                                label="Village/Town/City"

                                required
                                placeholder="City"
                                error={error?.city}
                                value={patientDetails?.city}
                                onChange={(e) => {
                                    handleError("city", "");
                                    handleChangeInfo("city", e?.target?.value);
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <ESelect
                                size="md"
                                label="State/UT"

                                data={statesMaster}
                                required
                                placeholder="State/UT"
                                error={error?.state}
                                value={patientDetails?.state}
                                onChange={(e) => {
                                    handleError("state", "");
                                    handleChangeInfo("state", e);
                                }}
                            />
                        </Grid.Col>
                    </Grid>

                    <ESectionDivider classes="mt-8" title="Contact Details" />
                    <Grid>
                        <Grid.Col span={6}>
                            <EPhoneInput
                                size="md"
                                label="Mobile No."
                                hideControls

                                required
                                className="mt-2"
                                error={error?.mobileNo}
                                placeholder=" Mobile No."
                                value={patientDetails?.primary_contact_no || ""}
                                rightSection={
                                    uniqueNessCheck?.mobileNo == "notUnique" ? (
                                        <FiAlertCircle size={22} color="red" />
                                    ) : uniqueNessCheck?.mobileNo?.toLowerCase() == "unique" ? (
                                        <FiCheckCircle size={22} color="green" />
                                    ) : (
                                        <></>
                                    )
                                }
                                // icon={"+91"}
                                maxLength={10}
                                onChange={(e) => {
                                    handleChangeInfo("primary_contact_no", e);
                                    if (String(e).length == 10) {
                                        checkUniqueNumber(e, "mobileNo");
                                    } else {
                                        setUniqueNessCheck({
                                            ...uniqueNessCheck,
                                            mobileNo: "not_checked",
                                        });
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <EPhoneInput
                                hideControls
                                size="md"
                                label="Alternate Mobile No."

                                required
                                className="mt-2"
                                error={error?.alternateNo}
                                placeholder="Alternate Mobile No."
                                value={patientDetails?.alternate_contact_no || ""}
                                rightSection={
                                    uniqueNessCheck?.alternateNo == "notUnique" ? (
                                        <FiAlertCircle size={22} color="red" />
                                    ) : uniqueNessCheck?.alternateNo?.toLowerCase() ==
                                        "unique" ? (
                                        <FiCheckCircle size={22} color="green" />
                                    ) : (
                                        <></>
                                    )
                                }
                                // icon={"+91"}
                                maxLength={10}
                                onChange={(e) => {
                                    // handleError("alternateNo", "");
                                    handleChangeInfo("alternate_contact_no", e);
                                    if (String(e).length === 10) {
                                        checkUniqueNumber(e, "alternateNo");
                                    } else {
                                        setUniqueNessCheck({
                                            ...uniqueNessCheck,
                                            alternateNo: "not_checked",
                                        });
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <EPhoneInput
                                hideControls
                                // required
                                size="md"
                                label="Secondary Alternate Mobile No."

                                error={error?.secondaryNo}
                                className="mt-2"
                                placeholder=" Mobile No."
                                value={patientDetails?.secondary_alternate_contact_no || ""}
                                rightSection={
                                    uniqueNessCheck?.secondaryNo === "notUnique" ? (
                                        <FiAlertCircle size={22} />
                                    ) : uniqueNessCheck?.secondaryNo?.toLowerCase() ==
                                        "unique" ? (
                                        <FiCheckCircle size={22} color="green" />
                                    ) : (
                                        <></>
                                    )
                                }
                                maxLength={10}
                                onChange={(e) => {
                                    handleError("secondaryNo", "");
                                    handleChangeInfo("secondary_alternate_contact_no", e);
                                    if (String(e).length == 10) {
                                        checkUniqueNumber(e, "secondaryNo");
                                    } else {
                                        setUniqueNessCheck({
                                            ...uniqueNessCheck,
                                            secondaryNo: "not_checked",
                                        });
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <ETextInput
                                size="md"
                                label="Instant Message"

                                maxLength={10}
                                className="mt-2"
                                placeholder="eg. Whatsapp No."
                                value={patientDetails?.whatsapp_no}
                                onChange={(e) =>
                                    handleChangeInfo("whatsapp_no", e.target.value)
                                }
                            />
                        </Grid.Col>
                    </Grid>

                    <ESectionDivider classes="mt-8" title="Additional Details" />
                    <Grid>
                        <Grid.Col span={6}>
                            <TextInput
                                size="md"
                                label="Hospital No."

                                required
                                error={error?.HospitalNo}
                                placeholder="Hospital No."
                                value={patientDetails?.hospital_no}
                                onChange={(e) => {
                                    handleError("HospitalNo", "");
                                    handleChangeInfo("hospital_no", e?.target?.value);
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                size="md"

                                label="Admission No."
                                placeholder="Admission No."
                                value={patientDetails?.admission_no}
                                onChange={(e) =>
                                    handleChangeInfo("admission_no", e?.target?.value)
                                }
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                size="md"
                                label="Ward No."
                                placeholder="Ward No."

                                value={patientDetails?.ward_no}
                                onChange={(e) => handleChangeInfo("ward_no", e?.target?.value)}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                size="md"
                                label="Bed No."
                                placeholder="Bed No."

                                value={patientDetails?.bed_no}
                                onChange={(e) => handleChangeInfo("bed_no", e?.target?.value)}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <ESelect
                                size="md"
                                label="Physician In-charge"
                                placeholder="Select"
                                nothingFoundMessage="No Doctor Found"
                                data={
                                    centerDetails?.physician_in_charge
                                        ? centerDetails?.physician_in_charge.split(",")
                                        : []
                                }

                                value={patientDetails?.physician_in_charge}
                                onChange={(e) => handleChangeInfo("physician_in_charge", e)}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <ESelect
                                size="md"
                                label="Surgeon In-charge"
                                nothingFoundMessage="No Doctor Found"
                                placeholder="Select"
                                data={
                                    centerDetails?.surgeon
                                        ? centerDetails?.surgeon?.split(",")
                                        : []
                                }

                                value={patientDetails?.surgeon_in_charge}
                                onChange={(e) => handleChangeInfo("surgeon_in_charge", e)}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <ESelect
                                size="md"
                                label="Radiology In-charge"
                                nothingFoundMessage="No Doctor Found"
                                placeholder="Select"
                                data={
                                    centerDetails?.radiologist
                                        ? centerDetails?.radiologist?.split(",")
                                        : []
                                }

                                value={patientDetails?.radiology_in_charge}
                                onChange={(e) => handleChangeInfo("radiology_in_charge", e)}
                            />
                        </Grid.Col>
                    </Grid>

                    <ESectionDivider
                        classes="mt-8"
                        title="Screening for Eligibility for GS"
                    />

                    <Grid>
                        <Grid.Col span={6}>
                            <Radio.Group
                                label="Ultrasound evidence of GS"
                                size="md"
                                value={patientDetails?.ultrasound_evidence_of_gs}
                                onChange={(e) =>
                                    handleChangeInfo("ultrasound_evidence_of_gs", e)
                                }
                            >
                                <Group>
                                    <Radio label="Yes" value="yes" />
                                    <Radio label="No" value="no" />
                                </Group>
                            </Radio.Group>
                        </Grid.Col>
                    </Grid>

                    <ESectionDivider
                        classes="mt-8"
                        title="Screening for Eligibility for GBC"
                    />

                    <Grid>
                        <Grid.Col span={6}>
                            <Radio.Group
                                label="Ultrasound evidence of GBC"
                                size="md"
                                value={patientDetails?.ultrasound_evidence_of_gbc}
                                onChange={(e) =>
                                    handleChangeInfo("ultrasound_evidence_of_gbc", e)
                                }
                            >
                                <Group>
                                    <Radio label="Yes" value="yes" />
                                    <Radio label="No" value="no" />
                                </Group>
                            </Radio.Group>
                        </Grid.Col>

                        <Grid.Col span={6}>
                            <Radio.Group
                                label="Definitive Diagnosis"
                                size="md"
                                value={
                                    patientDetails?.definitive_diagnosis === "no"
                                        ? "no"
                                        : patientDetails?.definitive_diagnosis
                                            ? "yes"
                                            : ""
                                }
                                onChange={(e) => {
                                    if (e === "yes") {
                                        if (!patientDetails?.definitive_diagnosis?.includes("yes")) {
                                            handleChangeInfo("definitive_diagnosis", e);
                                        }
                                    } else if (e === "no") {
                                        handleChangeInfo("definitive_diagnosis", e);
                                    }
                                }}
                            >
                                <Group>
                                    <Radio label="Yes" value="yes" />
                                    <Radio label="No" value="no" />
                                </Group>
                            </Radio.Group>
                            {patientDetails?.definitive_diagnosis?.includes("yes") && (
                                <Group className="mt-4">
                                    <ECheckBox
                                        label="Histology"

                                        checked={patientDetails?.definitive_diagnosis?.includes(
                                            "histology"
                                        )}
                                        onChange={(e) => {
                                            // if (e.target.checked) {
                                            if (
                                                !patientDetails?.definitive_diagnosis?.includes(
                                                    "histology"
                                                )
                                            ) {
                                                handleChangeInfo(
                                                    "definitive_diagnosis",
                                                    patientDetails?.definitive_diagnosis?.concat(
                                                        ",histology"
                                                    )
                                                );
                                            } else {
                                                let data =
                                                    patientDetails?.definitive_diagnosis?.split(",");
                                                handleChangeInfo(
                                                    "definitive_diagnosis",
                                                    data
                                                        ?.filter((item) => item !== "histology")
                                                        ?.join(",")
                                                );
                                            }
                                            // }
                                            // else{}
                                        }}
                                    />
                                    <ECheckBox
                                        label="Cytology"

                                        checked={patientDetails?.definitive_diagnosis?.includes(
                                            "cytology"
                                        )}
                                        onChange={(e) => {
                                            if (
                                                !patientDetails?.definitive_diagnosis?.includes("cytology")
                                            ) {
                                                handleChangeInfo(
                                                    "definitive_diagnosis",
                                                    patientDetails?.definitive_diagnosis?.concat(",cytology")
                                                );
                                            } else {
                                                let data =
                                                    patientDetails?.definitive_diagnosis?.split(",");
                                                handleChangeInfo(
                                                    "definitive_diagnosis",
                                                    data?.filter((item) => item !== "cytology")?.join(",")
                                                );
                                            }
                                        }}
                                    />
                                    <ECheckBox
                                        label="PET"

                                        checked={patientDetails?.definitive_diagnosis?.includes("PET")}
                                        onChange={(e) => {
                                            if (!patientDetails?.definitive_diagnosis?.includes("PET")) {
                                                handleChangeInfo(
                                                    "definitive_diagnosis",
                                                    patientDetails?.definitive_diagnosis?.concat(",PET")
                                                );
                                            } else {
                                                let data =
                                                    patientDetails?.definitive_diagnosis?.split(",");
                                                handleChangeInfo(
                                                    "definitive_diagnosis",
                                                    data?.filter((item) => item !== "PET")?.join(",")
                                                );
                                            }
                                        }}
                                    />
                                    <ECheckBox
                                        label="CECT"

                                        checked={patientDetails?.definitive_diagnosis?.includes(
                                            "CECT"
                                        )}
                                        onChange={(e) => {
                                            if (
                                                !patientDetails?.definitive_diagnosis?.includes("CECT")
                                            ) {
                                                handleChangeInfo(
                                                    "definitive_diagnosis",
                                                    patientDetails?.definitive_diagnosis?.concat(",CECT")
                                                );
                                            } else {
                                                let data =
                                                    patientDetails?.definitive_diagnosis?.split(",");
                                                handleChangeInfo(
                                                    "definitive_diagnosis",
                                                    data?.filter((item) => item !== "CECT")?.join(",")
                                                );
                                            }
                                        }}
                                    />
                                    <ECheckBox
                                        label="US"

                                        checked={patientDetails?.definitive_diagnosis?.includes("US")}
                                        onChange={(e) => {
                                            if (!patientDetails?.definitive_diagnosis?.includes("US")) {
                                                handleChangeInfo(
                                                    "definitive_diagnosis",
                                                    patientDetails?.definitive_diagnosis?.concat(",US")
                                                );
                                            } else {
                                                let data =
                                                    patientDetails?.definitive_diagnosis?.split(",");
                                                handleChangeInfo(
                                                    "definitive_diagnosis",
                                                    data?.filter((item) => item !== "US")?.join(",")
                                                );
                                            }
                                        }}
                                    />
                                </Group>
                            )}
                        </Grid.Col>
                    </Grid>

                    <ESectionDivider classes="mt-8" title="Disease Details" />
                    <Grid>
                        <Grid.Col span={6}>
                            <EMultiSelect
                                label="Disease Type"
                                placeholder="Select Disease"
                                required
                                error={error?.disease}
                                size="md"
                                data={[
                                    {
                                        label: "Asymptomatic Gallstone",
                                        value: "asymptomatic_gallstone",
                                    },
                                    {
                                        label: "Symptomatic Gallstone",
                                        value: "symptomatic_gallstone",
                                    },
                                    { label: "Gallbladder Cancer", value: "gallbladder_cancer" },
                                    {
                                        label: "Non Gallstone Control",
                                        value: "non_gallstone_control",
                                    },
                                ]}
                                value={patientDetails?.disease || null}
                                onChange={(e) => {
                                    handleError("disease", "");
                                    handleChangeInfo("disease", e);
                                }}
                            />
                        </Grid.Col>

                        <Grid.Col span={12}>
                            <div className="flex flex-row gap-4 mt-4">
                                <ECheckBox
                                    label="Severe co-morbidities"

                                    checked={patientDetails?.severe_comorbidities === "yes"}
                                    className="mt-2"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            handleChangeInfo("severe_comorbidities", "yes");
                                        } else {
                                            handleChangeInfo("severe_comorbidities", "no");
                                        }
                                    }}
                                />
                                <ECheckBox
                                    label="Unwilling for FU"

                                    className="mt-2"
                                    checked={patientDetails?.unwilling_for_fu === "yes"}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            handleChangeInfo("unwilling_for_fu", "yes");
                                        } else {
                                            handleChangeInfo("unwilling_for_fu", "no");
                                        }
                                    }}
                                />
                            </div>
                        </Grid.Col>
                    </Grid>
                </Grid.Col>
                <Grid.Col span={4} className="p-5">

                    <ESectionDivider title="Consent Form" />
                    <div className="bg-slate-100 m-2 p-3 mt-5 rounded-md">
                        <div className="text-base text-slate-800 font-semibold">
                            {" "}
                            📥 Download Consent Form:
                        </div>
                        <div className="text-sm font-normal text-slate-500 my-2">
                            Tap the button below to instantly download the consent form in
                            your selected language.
                        </div>

                        <ESelect
                            value={selectedLanguage}
                            data={["English", "Hindi", "Urdu", "Bangla", "Malayalam"]}
                            onChange={(e) => {
                                setSelectedLanguage(e ?? "English");
                            }}
                        />
                        <div className="mt-4 w-full flex justify-center">
                            <EButton
                                size="md"
                                onClick={() => {
                                    handleDownloadPDF(selectedLanguage);
                                }}
                            >
                                <FiDownload /> Download
                            </EButton>
                        </div>
                    </div>

                    <div className="">
                        {consentFormUrl ? <></> : <div
                            className="m-2 p-5 w-full flex flex-col items-center justify-center border border-slate-400 border-dashed  rounded-md cursor-pointer"
                            onClick={() => {
                                setUploadFileModalIsOpen(true);
                            }}
                        >
                            <MdOutlinePermMedia size={33} />
                            <div className="mt-2 text-lg font-semibold">
                                Upload Consent Form
                            </div>
                        </div>}

                        <div className="mt-5">
                            {consentFormUrl ? <EMediaItem
                                item={consentFormUrl}
                                handleOpenMediaInNewTab={handleOpenMediaInNewTab}
                                setIsOpenDeleteConfirmModal={setIsOpenDeleteConfirmModal}
                                isOpenDeleteConfirmModal={isOpenDeleteConfirmModal}
                                handleDeleteImageFromAws={handleDeleteImageFromAws}
                            /> : <></>}
                        </div>
                    </div>
                </Grid.Col>
            </Grid>

            <EModal
                isOpen={uploadFileModalIsOpen}
                title={"Upload Consent Form"}
                size="md"
                setIsOpen={() => {
                    setUploadFileModalIsOpen(false);
                }}
            >
                {newConsentForm ?
                    <div className="flex justify-between rounded-lg bg-slate-100 p-4 m-2">
                        <div className="flex gap-1 items-center">
                            {newConsentForm ? <BsFilePdf /> : <BsImage />}
                            <p className="font-semibold">{newConsentForm?.name}</p>
                        </div>
                        <Button
                            variant="light"
                            onClick={() => {
                                window.open(URL.createObjectURL(newConsentForm), '_blank');
                            }}
                        >
                            <BsEye size={16} className="mr-2" /> Preview
                        </Button>
                        {/* <Button
                        className="ml-2"
                        variant="light"
                        onClick={() => {
                            setNewConsentForm(null);
                        }}
                      >
                        <CiCircleRemove size={16} className="mr-2" /> Clear
                      </Button> */}
                    </div> :
                    <Dropzone
                        className="m-2 mt-4"
                        accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                        onDrop={function (files: FileWithPath[]): void {
                            setNewConsentForm(files[0]);
                        }}
                    >
                        <div className="flex justify-center flex-col items-center text-center">
                            <FiUpload size={33} />

                            <p>Upload consent form</p>
                            <p className="text-xs text-slate-500 ">Image / PDF</p>
                        </div>
                    </Dropzone>

                }

                {!newConsentForm ? (
                    <></>
                ) : (
                    <div className=" flex gap-3 justify-around mt-8">
                        <EButton
                            variant="secondary"
                            className="mt-4"
                            onClick={() => {
                                setNewConsentForm(null);
                                setUploadFileModalIsOpen(false);
                            }}
                        >
                            Cancel
                        </EButton>
                        <EButton
                            className="mt-4"
                            loading={isMediaBtnLoading}
                            onClick={() => handleUploadFiles([newConsentForm])}
                        >
                            <HiOutlineArrowUpTray className="mr-2" />
                            Upload
                        </EButton>
                    </div>
                )}
            </EModal>

            <Modal
                title="Eligibility Status"
                opened={isOpenInfoModal}
                size="xl"
                onClose={() => {
                    setIsOpenInfoModal(false);
                }}
                closeOnClickOutside={false}
            >
                <div className="bg-slate-100 p-3 rounded-md">
                    <div className="border-b border-slate-300 mb-4 pb-4">
                        <div className="flex flex-row items-center justify-between mb-4">
                            <div className="text-green-700 font-semibold text-2xl">
                                Screening for Eligibility for GS
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <ECheckBox label="Informed consent" checked={consentFormUrl ? true : false} disabled />
                            <ECheckBox
                                label="Ultrasound evidence of GS"
                                checked={patientDetails?.ultrasound_evidence_of_gs === "yes"}
                                disabled
                            />
                            <ECheckBox
                                label="Age (18 - 80) years"
                                checked={patientDetails?.age ? patientDetails?.age >= 18 && patientDetails?.age <= 80 : false}
                                disabled
                            />
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-row items-center justify-between mb-4">
                            <div className="text-green-700 font-semibold text-2xl">
                                Screening for Eligibility for GBC
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <ECheckBox label="Informed consent" checked={consentFormUrl ? true : false} disabled />
                            <ECheckBox
                                label="Ultrasound evidence of GS"
                                checked={patientDetails?.ultrasound_evidence_of_gs === "yes"}
                                disabled
                            />
                            <ECheckBox
                                label="Age (18 - 80) years"
                                checked={patientDetails?.age ? patientDetails?.age >= 18 && patientDetails?.age <= 80 : false}
                                disabled
                            />
                            <ECheckBox
                                label="Definitive Diagnosis"
                                checked={patientDetails?.definitive_diagnosis?.includes("yes")}
                                disabled
                            />
                            <ECheckBox
                                label="Severe co-morbidities"
                                checked={patientDetails?.severe_comorbidities === "yes"}
                                disabled
                            />
                            <ECheckBox
                                label="Unwilling for FU"
                                checked={patientDetails?.unwilling_for_fu === "yes"}
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className='flex justify-end gap-3 mt-3'>
                    <ECheckBox
                        label="Include this patient in the study"
                        checked={updateInclusionStatus === "included"}
                        onChange={(e) => {
                            setUpdateInclusionStatus(updateInclusionStatus === "included" ? "" : "included");
                        }}
                    />


                    <ECheckBox
                        label="Exclude this patient from the study"
                        checked={updateInclusionStatus === "excluded"}
                        onChange={(e) => {
                            setUpdateInclusionStatus(updateInclusionStatus === "excluded" ? "" : "excluded");
                        }}
                    />
                </div>

                <div className="flex justify-end mt-5 border-t pt-3">
                    <EButton
                        className="btn-blue"
                        onClick={() => {
                            handleUpdateInclusionStatus();
                        }}
                        loading={isBtnLoading}
                    >
                        Continue
                    </EButton>
                </div>
            </Modal>

        </PageLayout>
    )
}
