import { FaHospitalUser } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import { MdOutlineNotificationsActive } from "react-icons/md";
import PatientInformation from "../Pages/NewPatient/PatientInformation";
import Notification from "../Pages/Notification/Notification";
import Patient from "../Pages/Patients/Patients";
import PatientVisit from "../Pages/Visit/PatientsVisit";
import PatientList from "../Pages/Patients/PatientList";
import AddNewCenter from "../Pages/Admin/Centers/AddNewCenter";
import CenterList from "../Pages/Admin/Centers/CenterList";
import AdminPatientList from "../Pages/Admin/Patient/AdminPatientList";
import PatientsDetails from "../Pages/PatientsDetails/PatientsDetails";
import { FiUsers } from "react-icons/fi";
import UserManagement from "../Pages/UserManagement/UserManagement";
import { IoCallOutline } from "react-icons/io5";
import RequestCallback from "../Pages/RequestCallback/RequestCallback";
import EditpatientDetailsrmation from "../Pages/EditPatient/EditPatientInformation";
import EditpatientInformation from "../Pages/EditPatient/EditPatientInformation";
import SupportTeam from "../Pages/SupportTeam/SupportTeam";
import { BiSupport } from "react-icons/bi";
import { RiChatFollowUpLine } from "react-icons/ri";
import PatientsForFollowup from "../Pages/Patients/PatientsForFollowup";



export const ROLES = {
  CENTER: "Center",
  ADMIN: "Administrator",
};
export interface IRoute {
  path: string;
  label: string;
  element: any;
  icon?: any;
  iconSrc?: any;
  isSubmenu?: boolean;
  submenu?: IRoute[];
  rolesAllowed?: string[];
}

export const DEPARTMENT_PATH = {
  questionBank: "/upload-question-bank",
};

export const ROUTES: {
  COMMON_ROUTES: IRoute[];
} = {
  COMMON_ROUTES: [
    {
      path: "/index",
      label: "Patients",
      // element: Dashboard,
      element: PatientList,
      icon: FaHospitalUser,
      rolesAllowed: [ROLES.CENTER, ROLES.ADMIN],
    },
    {
      path: "/index/patient/details",
      label: "Patient Details",
      // element: Dashboard,
      element: PatientsDetails,
      //   icon: FaUserPlus,
      rolesAllowed: [ROLES.CENTER, ROLES.ADMIN],
    },
    {
      path: "/index/new-patient",
      label: "Patient Management",
      // element: Dashboard,
      element: PatientInformation,
      //   icon: FaUserPlus,
      rolesAllowed: [ROLES.CENTER, ROLES.ADMIN],
    },
    {
      path: "/index/patient/visit",
      label: "Patients",
      // element: Dashboard,
      element: Patient,
      rolesAllowed: [ROLES.ADMIN, ROLES.CENTER],
    },
    {
      path: "/index/patient/new-visit",
      label: "Visit",
      // element: Dashboard,
      element: PatientVisit,
      rolesAllowed: [ROLES.ADMIN, ROLES.CENTER],
    },
    {
      path: "/index/patient/edit-patient",
      label: "Edit Patient",
      // element: Dashboard,
      element: EditpatientInformation,
      rolesAllowed: [ROLES.CENTER],
    },

    // {
    //   path: "/report",
    //   label: "Report & Stats",
    //   // element: Dashboard,
    //   element: Report,
    //   icon: TbReportAnalytics,
    //   rolesAllowed: [ROLES.ADMIN, ROLES.CENTER],
    // },
    // {
    //   path: "/dashboard",
    //   label: "Dashboard",
    //   // element: Dashboard,
    //   element: Admin,
    //   icon: TbReportAnalytics,
    //   rolesAllowed: [ROLES.ADMIN, ROLES.CENTER],
    // },
    {
      path: "/center-list/add-new-center",
      label: "Add New Center",
      // element: Dashboard,
      element: AddNewCenter,
      rolesAllowed: [ROLES.ADMIN],
    },
    {
      path: "/center-list",
      label: "Center List",
      // element: Dashboard,
      element: CenterList,
      icon: TbReportAnalytics,
      rolesAllowed: [ROLES.ADMIN],
    },
    {
      path: "/admin-patient-list",
      label: "Patients",
      // element: Dashboard,
      element: AdminPatientList,
      rolesAllowed: [ROLES.ADMIN, ROLES.CENTER],
    },
    // {
    //   path: "/notification",
    //   label: "Notification",
    //   // element: Dashboard,
    //   element: Notification,
    //   icon: MdOutlineNotificationsActive,
    //   rolesAllowed: [ROLES.CENTER, ROLES.ADMIN],
    // },
    {
      path: "/user-management",
      label: "Doctors",
      // element: Dashboard,
      element: UserManagement,
      icon: FiUsers,
      rolesAllowed: [ROLES.CENTER],
    },
    {
      path: "/request-callback",
      label: "Request",
      element: RequestCallback,
      icon: IoCallOutline,
      rolesAllowed: [ROLES.CENTER],
    }
    ,
    // {
    //   path: "/followup-patient",
    //   label: "Followup Patients",
    //   // element: Dashboard,
    //   element: PatientsForFollowup,
    //   icon:  RiChatFollowUpLine,
    //   rolesAllowed: [ROLES.ADMIN, ROLES.CENTER],
    // },
     {
      path: "/support-team",
      label: "Support Team",
      // element: Dashboard,
      element: SupportTeam,
      icon: BiSupport,
      rolesAllowed: [ROLES.ADMIN, ROLES.CENTER],
    },
   
  ],
};
