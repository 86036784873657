import { Textarea, TextareaProps } from '@mantine/core'
import React from 'react'

export function ETextArea(props:TextareaProps) {
  return (
     <Textarea
     label={props.label}
     size={props.size}
     className={props.className}
     minRows={props.minRows}
     maxRows={props.maxRows}
     rows={props.rows}
     description={props.description}
     value={props.value}
     placeholder={props.placeholder}
     error={props.error}
     onChange={props.onChange}
     />
  )
}
