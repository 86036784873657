import React, { useContext, useEffect, useRef } from "react";

import "./App.scss";
import { AppContext } from "./AppContext";

interface IProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  rightPanel?: React.ReactNode;
  leftPanel?: React.ReactNode;
}

export default function PageLayout({
  children,
  header,
  rightPanel,
  leftPanel,
  footer,
}: IProps) {
  const {
    colorScheme,
    activeTab
  } = useContext(AppContext);
  const mainRef = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scrollTo(0,0);  // Scroll to the top
    }
  }, [activeTab]);

  return (
    <div className="page-layout2">
      
      {header ? <div style={{ backgroundColor: colorScheme?.head }} className={`page-layout2--header`}>{header}</div> : <></>}
      
      <div ref={mainRef} style={{ backgroundColor: colorScheme?.body }} className={`page-layout2--body`}>
       
        {leftPanel && (
          <div
            className="left col-span-2"
          >
            {leftPanel}
          </div>
        )}

        <div
          className={`main 
          ${
            rightPanel && !leftPanel
              ? " col-span-10"
              : leftPanel && !rightPanel
              ? "col-span-10"
              : !leftPanel && !rightPanel
              ? "col-span-12"
              : "col-span-8"
          }
          `}
          
        >
          {children}
        </div>

        {rightPanel && (
          <div
            className={`right  ${
              rightPanel ? "col-span-2" : "hidden"
            }`}
          >
            {rightPanel}
          </div>
        )}

      </div>
      
      {footer && <div className="page-layout2--footer">{footer}</div>}
    </div>
  );
}
