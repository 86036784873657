import { useState } from "react";
import { IAddNewCenter } from "../../../../types";
import axios from "axios";
import { API } from "../../../../libs/data-access/constant";
import { showToast } from "../../../../utils/utility";
import { useDirectusApi } from "../../../../libs/data-access";
import { ICenterList } from "../../../../libs/data-access/types";
import { useNavigate } from "react-router-dom";

export default function useCenter() {
  const { fetchDirectData } = useDirectusApi();
  const navigate = useNavigate();

  const [centerDetails, setCenterDetails] = useState<IAddNewCenter>({
    center_name: "",
    last_name:"",
    email:"",
    hospital_no: "",
    address: "",
    contact_no: "",
    whatsapp_no: "",
    landline_no: "",
    state: "",
    password: "",
  });

  const [centerList, setCenterList] = useState<ICenterList[]>([]);
  const [totalCenter,setTotalCenter] = useState<number>(0)
  const [totalPage, setTotalPage] = useState<number>(0);
  const [filterValue,setFilterValue] =useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const handleChangeCenterDetails = (key: string, value: string) => {
    setCenterDetails((prev) => ({ ...prev, [key]: value }));
  };

  const handleAddNewCenter = () => {
    axios
      .post(`${API}custom/add-center`, centerDetails)
      .then((res) => {
        navigate("/center-list");
        showToast({
          type: "success",
          message: "New Center Added",
        });
      })
      .catch((err) => {
        showToast({
          type: "error",
          title: "Please try again.",
          message: err?.response?.data?.error || "Unable to add new center.",
        });
      });
  };

  const handleFetchCenterList = async (cP: number) => {
    setCenterList([]);
    let centerData = await fetchDirectData(
      `items/center_profile?meta=filter_count&limit=10&page=${cP}&filter[status]=published&sort=-date_created&fields=id,address,hospital_no,contact_no,whatsapp_no,landline_no,state,center_name,user_id.email,user_id.id${filterValue ? `&search=${filterValue}` : ""}`
    );

    if (centerData?.data) {
      setCenterList(centerData?.data?.data);
      setTotalCenter(centerData?.data?.meta?.filter_count)
      setTotalPage(
        Math.ceil(centerData?.data?.meta?.filter_count / 10)
      );
      setCurrentPage(cP);
    } else {
      showToast({
        type: "error",
        message: "Unable to get center list",
      });
    }
  };

  return {
    currentPage,
    centerDetails,
    centerList,
    totalPage,
    totalCenter,
    filterValue,
    setFilterValue,
    setCenterDetails,
    handleFetchCenterList,
    handleChangeCenterDetails,
    handleAddNewCenter,
  };
}
