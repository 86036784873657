import { useContext, useEffect, useState } from 'react'
import { useDirectusApi } from '../../../libs/data-access';
import { useSearchParams } from 'react-router-dom';
import { IPatientInformation } from '../../../libs/data-access/types';
import { showToast } from '../../../utils/utility';
import { AppContext } from '../../../AppContext';

const defaultTabsValue = [
    // {label:"personal_history",disable:false, viewOnly:false},
    {label:"clinical_history",disable:false, viewOnly:false},
    {label:"gallstone",disable:false, viewOnly:false},
    {label: "gbc_clinical_presentation", disable: false, viewOnly: false },
    // {label:"dietary_determinate",disable:false, viewOnly:false},
    // {label:"anthropometric",disable:false, viewOnly:false},
    {label:"investigation",disable:false, viewOnly:false},
    {label:"radiology",disable:false, viewOnly:false},
    {label:"cost_effective_analysis",disable:false, viewOnly:false},
    {label:"quality_of_life",disable:false, viewOnly:false},
    // {label:"surgery",disable:false, viewOnly:false},
    // {label:"histology",disable:false, viewOnly:false},
    // {label:"stone_analysis",disable:false, viewOnly:false},
  ]

export default function usePatientVisit() {
  
  const {fetchDirectData} = useDirectusApi()
  const [searchParams] = useSearchParams();
  const  patient_id = searchParams.get("patient_id");
  const {
    setColorScheme,
    activeTab,
    setActiveTab    
} =useContext(AppContext)

    const [isBtnLoading,setIsBtnLoading]=useState<boolean>(false);
    const [isFormUpdating,setIsFormUpdating]=useState<boolean>(false);
    const [isBtnDisable,setIsBtnDisable]=useState<boolean>(true);
    const [isBtnNextClick,setIsNextBtnClick]=useState<boolean>(false);
    const [isBtnBackClick,setIsBackBtnClick]=useState<boolean>(false);
    const [progress,setProgress]=useState<number>(0);
    const [activeSubTab,setActiveSubTab]= useState<number>(1);
    const [tabsValue,setTabValue] = useState<{label:string,disable:boolean,viewOnly:boolean}[]>(defaultTabsValue);
    const [patientDetails, setPatientDetails] = useState<IPatientInformation>(
      {} as IPatientInformation
    );

  useEffect(() => {
    if(!activeTab){
      setActiveTab("clinical_history")
    }
  },[])

    useEffect(()=>{
        const activeIndex = tabsValue.findIndex((each)=>each?.label == activeTab)
          setProgress(activeIndex*25)
          setActiveSubTab(1)
    },[activeTab])

    const fetchPatientDetails = async()=>{
      const res = await fetchDirectData(`items/patiant_profile?filter[user_id][_eq]=${patient_id}`)
      if(res.isSuccess){
        setPatientDetails(res?.data[0])
      }
      else{
        showToast({
          type:"error",
          message:"Invalid user !"
        })
        // navigate(`/index`)
      }
    }

    const handleNextTab =()=>{
                 const activeIndex = tabsValue.findIndex((each)=>each?.label == activeTab)
                 let data = tabsValue;
                 data[activeIndex+1]={label:data[activeIndex+1]?.label,disable:false ,viewOnly:false}
                 data[activeIndex]={label:data[activeIndex]?.label,disable:false ,viewOnly:true}
                 setTabValue(data)
                 setActiveTab(tabsValue[activeIndex+1]?.label)
          setIsBtnDisable(true)
          setIsNextBtnClick(false)
        }

    const handleBackTab =()=>{
        let totalSubTab = activeTab == "clinical_history" ? 3 : activeTab =="investigation" ? 5 : -1   
        if(activeSubTab > totalSubTab) {
         setActiveSubTab(activeSubTab - 1)
        }
        else{
        const activeIndex = tabsValue.findIndex((each)=>each?.label == activeTab)
        setActiveTab(tabsValue[activeIndex-1]?.label)
     }
    }

    return {
     isBtnLoading,
     setIsBtnLoading,
     isFormUpdating,
     setIsFormUpdating,
     isBtnDisable,
     setIsBtnDisable,
     isBtnBackClick,
     setIsBackBtnClick,
     isBtnNextClick,
     setIsNextBtnClick,
     progress,
     setProgress,
     activeTab,
     setActiveTab,
     setColorScheme,
     handleNextTab,
     handleBackTab,
     tabsValue,
     activeSubTab,
     setActiveSubTab,
     patientDetails,
     fetchPatientDetails
  }
}
