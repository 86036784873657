import React from "react";

interface IProps {
  title: string;
  classes?: string;
  titleStyle?: string;
  subTitle?: string;
  end?: React.ReactNode;
}

export function ESectionDivider({ title, classes, titleStyle, end, subTitle }: IProps) {
  return (
<div>
      <div
      className={` flex items-center mt-9 justify-between ${subTitle ? "mb-1" : "mb-4"} ${
        classes && classes
      }`}
    >
      <div
        className={`  ${
          titleStyle ? titleStyle : "text-lg font-bold mr-4"
        }`}
      >
        {title}
      </div>
      <div className="flex-1 h-[1px] bg-slate-200"></div>
      {end && <div className="pl-3">{end}</div>}
    </div>
{
     subTitle && <div
      className={`text-sm text-slate-600 font-normal mb-4`}
    >
      {subTitle}
    </div>
}
</div>
  );
}
