import {
  IFormDataGBEF,
  IGBEF,
  IInvestigationDataBiochemistry,
  IInvestigationDataHaematology,
  IInvestigationDataLft,
  IMealType,
  IRadiology,
} from "./types";

export const DefaultINewDiet: IMealType = {
  meal: "",
  what: "",
  howMuch: "",
  quantity: "",
};

export const bgBodyColor: { [key: string]: string } = {
  personal_history: "#bbf7d0",
  clinical_history: "#bae6fd",
  gallstone: "#bfdbfe",
  gbc_clinical_presentation: "#fde2e2",
  anthropometric: "#fecaca",
  dietary_determinate: "#fbcfe8",
  investigation: "#e3d67b",
  radiology: "#e9d5ff",
  surgery: "#fed7aa",
  histology: "#99f6e4",
  stone_analysis: "#e2e8f0",
  cost_effective_analysis: "#c7d2fe",
  quality_of_life: "#fde68a",
};

export const DefaultInvestigationHematology: IInvestigationDataHaematology[] = [
  { name: "Hemoglobin (g/dl)", key: "hemoglobin", value1: "", value2: "" },
  {
    name: "Total Leukocyte Count (/mm³)",
    key: "total_leukocyte_count",
    value1: "",
    value2: "",
  },
  {
    name: "Differential Leukocyte Count",
    key: "dlc",
    placeholder: "P - L - M - B - E",
    value1: "",
    value2: "",
  } /* use masking to separate value p-l-m-b-e */,
  {
    name: "Platelet Count (*10³,10⁶/mm³)",
    key: "platelet_count",
    value1: "",
    value2: "",
  },
];

export const DefaultInvestigationLft: IInvestigationDataLft[] = [
  {
    name: "Total Bilirubin (mg/dL)",
    key: "total_bilirubin",
    value1: "",
    value2: "",
  },
  {
    name: "Conjugated Bilirubin (mg/dL)",
    key: "conjugated_bilirubin",
    value1: "",
    value2: "",
  },
  { name: "SGOT (AST, units/L)", key: "sgot_ast", value1: "", value2: "" },
  { name: "SGPT (ALT, units/L)", key: "sgpt_alt", value1: "", value2: "" },
  {
    name: "S.Alkaline Phosphatase (ALP, units/L)",
    key: "s_alkaline_phosphatase",
    value1: "",
    value2: "",
  },
];

export const DefaultInvestigationBioChemistry: IInvestigationDataBiochemistry[] =
  [
    {
      name: "Total Proteins (g/dl)",
      key: "total_proteins",
      value1: "",
      value2: "",
    },
    { name: "Albumin (g/dl)", value1: "", key: "albumin", value2: "" },
    { name: "Sodium (mMol/L)", key: "sodium", value1: "", value2: "" },
    { name: "Potassium (mMol/L)", key: "potassium", value1: "", value2: "" },
    { name: "B. Urea (mMol/L)", key: "b_urea", value1: "", value2: "" },
    {
      name: "S. Creatinine (mg/dL)",
      key: "s_creatinine",
      value1: "",
      value2: "",
    },
    {
      name: "Fasting Blood Sugar (mg/dL)",
      key: "fasting_blood_sugar",
      value1: "",
      value2: "",
    },
    {
      name: "HbA1c",
      key: "hba1c",
      value1: "",
      value2: "",
    },
    { name: "Cholesterol (mg/dL)", key: "cholesterol", value1: "", value2: "" },
    {
      name: "Triglyceride (mg/dL)",
      key: "triglyceride",
      value1: "",
      value2: "",
    },
    { name: "LDL (mg/dL)", key: "ldl", value1: "", value2: "" },
    { name: "HDL (mg/dL)", key: "hdl", value1: "", value2: "" },
    { name: "VLDL (mg/dL)", key: "vldl", value1: "", value2: "" },
    { name: "Calcium (mg/dL)", key: "calcium", value1: "", value2: "" },
    { name: "Phosphorous (mg/dL)", key: "phosphorous", value1: "", value2: "" },
    { name: "Magnesium (mg/dL)", key: "magnesium", value1: "", value2: "" },
    { name: "Vitamin D (nmol/L)", key: "vitamin_d", value1: "", value2: "" },
  ];

export const DefaultStandardMealForGBEF: IFormDataGBEF[] = [
  {
    type: "fasting",
    time: "",
    gb_breath: "",
    gb_height: "",
    gb_length: "",
    means_gb_volume: "",
  },
  {
    type: "postprandial_at_30min",
    time: "",
    gb_breath: "",
    gb_height: "",
    gb_length: "",
    means_gb_volume: "",
  },
  {
    type: "postprandial_at_60min",
    time: "",
    gb_breath: "",
    gb_height: "",
    gb_length: "",
    means_gb_volume: "",
  },
];

// export const  DefaultClinicalHistory : INewClinicalHistory = {
//   user_id: "",
//   comorbidities: "",
//   drug_history: [],
//   typhoid: [],
//   smoking: [],
//   alcohol: [],
//   family_history: [],
//   family_history_of_cancer: [],
//   is_family_history_of_gbc: false,
//   is_family_history_of_liver_cancer: false,
//   is_family_history_of_liver_cirrhosis: false,
//   reproductive_h_per_o: [],
//   pregnancies_history: [],
//   visit_id: "",
//   visit_remarks: ""
// }

export const DefaultNewRadiology: IRadiology = {
  user_id: "",
  visit_id: "",
  date_time: "",
  outside_study_centre: "",
  evaluation: [],
  distension: [],
  measurement_of_wall_thickness: [
    {
      fundus: "",
      body: "",
      neck: "",
    },
  ],
  characterization_of_wall_thickness: [
    {
      asymmetrical: undefined,
      layered_appearance: undefined,
      distinct_interface_with_liver: undefined,
      loss_of_interface_with_liver: undefined,
      preserved_interface_with_liver: undefined,
    },
  ],
  sludge: undefined,
  gallstones: undefined,
  number_of_stones: 0,
  stone_size_of_largest_stone_in_cm: "",
  diameter_of_CBD: "",
  focal_gallbladder_thickening: undefined,
  gallbladder_polyp: undefined,
  mirrizi_syndrome: undefined,
  empyema_gallbladder: undefined,
  biliary: undefined,
  vascular: undefined,
  liver_mass: undefined,
  standard_meal_for_GBEF: DefaultStandardMealForGBEF,
  inadequate_evaluation_gb_rads_category: [
    {
      morbid_obesity: undefined,
      wallechoshadow_complex: undefined,
      porcelain_gallbladder: undefined,
      gas_in_the_gallbladder_lumen: undefined,
    },
  ],
  normal_gb_rads_category: [
    {
      adequate_gallbladder_distension: undefined,
      wall_thickness_3mm: undefined,
    },
  ],
  benign_gb_rads_category: [
    {
      layered_appearance: undefined,
      preserved_interface_with_liver: undefined,
      symmetric_circumferential_thickening: undefined,
    },
  ],
  equivocal_gb_rads_category: [
    {
      circumferential_thickening_without_layered_appearance: undefined,
      focal_thickening_without_intramural_features: undefined,
      distinct_interface_with_liver: undefined,
    },
  ],
  malignancy_is_likely_gb_rads_category: [
    {
      circumferential_or_focal_thickening: undefined,
    },
  ],
  malignancy_is_highly_likely: [
    {
      same_as_GBRADS_4: undefined,
      biliary_or_vascular_involvement: undefined,
      liver_mass_in_contiguity: undefined,
    },
  ],
  media_files: "",
};

export const DefaultRadiologyGbRADSCategories = [
  {
    sectionTitle: "Inadequate evaluation due to technical or patient factors",
    sectionKey: "inadequate_evaluation_gb_rads_category",
    findings: [
      { label: "Morbid obesity", key: "morbid_obesity", value: false },
      {
        label: "Wall-echo-shadow complex",
        key: "wallechoshadow_complex",
        value: false,
      },
      {
        label: "Porcelain gallbladder",
        key: "porcelain_gallbladder",
        value: false,
      },
      {
        label: "Gas in the gallbladder lumen",
        key: "gas_in_the_gallbladder_lumen",
        value: false,
      },
    ],
  },
  {
    sectionTitle: "Normal",
    sectionKey: "normal_gb_rads_category",
    findings: [
      {
        label: "Adequate gallbladder distension",
        key: "adequate_gallbladder_distension",
        value: false,
      },
      {
        label: "Wall thickness ≤ 3mm",
        key: "wall_thickness_3mm",
        value: false,
      },
    ],
  },
  {
    sectionTitle: "Benign",
    sectionKey: "benign_gb_rads_category",
    findings: [
      {
        label:
          "Symmetric circumferential thickening (>3mm) with or without intramural changes or focal thickening with intramural changes",
        key: "symmetric_circumferential_thickening",
        value: false,
      },
      { label: "Layered appearance", key: "layered_appearance", value: false },
      {
        label: "Preserved interface with liver",
        key: "preserved_interface_with_liver",
        value: false,
      },
    ],
  },
  {
    sectionTitle: "Equivocal",
    sectionKey: "equivocal_gb_rads_category",
    findings: [
      {
        label: "Circumferential thickening without layered appearance",
        key: "circumferential_thickening_without_layered_appearance",
        value: false,
      },
      {
        label:
          "Focal thickening without intramural features (cysts or echogenic foci) or layered appearance",
        key: "focal_thickening_without_intramural_features",
        value: false,
      },
      {
        label: "Distinct interface with liver",
        key: "distinct_interface_with_liver",
        value: false,
      },
    ],
  },
  {
    sectionTitle: "Malignancy is likely",
    sectionKey: "malignancy_is_likely_gb_rads_category",
    findings: [
      {
        label:
          "Circumferential or focal thickening (>3mm) without layered appearance and with loss of interface with liver",
        key: "circumferential_or_focal_thickening",
        value: false,
      },
    ],
  },
  {
    sectionTitle: "Malignancy is highly likely",
    sectionKey: "malignancy_is_highly_likely",
    findings: [
      {
        label:
          "Same as GB-RADS 4 with definite extramural invasion as suggested by one of the following",
        key: "same_as_GBRADS_4",
        value: false,
      },
      {
        label:
          "Biliary or vascular involvement by direct extension of mural thickening",
        key: "biliary_or_vascular_involvement",
        value: false,
      },
      {
        label: "Liver mass in contiguity with the mural thickening",
        key: "liver_mass_in_contiguity",
        value: false,
      },
    ],
  },
];

export const DefaultValueForGBEF: IGBEF[] = [
  {
    type: "fasting",
    time: "",
    gb_length: "",
    gb_breadth: "",
    gb_height: "",
    mean_gb_volume: "",
    gbef: "",
  },
  {
    type: "postprandial_at_30min",
    time: "",
    gb_length: "",
    gb_breadth: "",
    gb_height: "",
    mean_gb_volume: "",
    gbef: "",
  },
  {
    type: "postprandial_at_60min",
    time: "",
    gb_length: "",
    gb_breadth: "",
    gb_height: "",
    mean_gb_volume: "",
    gbef: "",
  },
];
