import {
  EButton,
  ECheckBox,
  EModal,
  ESectionDivider,
  ETextInput,
  FormSectionDivider,
} from "../../../../../libs/ui";
import { Checkbox, Grid } from "@mantine/core";
import useSurgery from "../hooks/useSurgery";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { MdOutlinePermMedia } from "react-icons/md";
import { HiOutlineArrowUpTray } from "react-icons/hi2";
import moment from "moment";
import EMediaItem from "../../../../../libs/ui/src/EMediaItem";
import EDateInput from "../../../../../libs/ui/src/DateInput/EDateInput";

export default function Surgery() {
  const {
    centerName,
    outsideStudyCenter,
    setOutSideStudyCenter,
    setCenterName,
    payloadForSurgery,
    handleChangeSurgery,
    files,
    mediaFiles,
    setFiles,
    handleUploadFiles,
    uploadFileModalIsOpen,
    setUploadFileModalIsOpen,
    isMediaBtnLoading,
    handleOpenMediaInNewTab,
    handleDeleteImageFromAws,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal,
  } = useSurgery();

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-9 border-r pr-3">
        <div className="flex items-center justify-between">
          <EDateInput
            size="md"
            label="Date"
            placeholder="Select date"
            value={
              payloadForSurgery?.date
                ? new Date(payloadForSurgery?.date)
                : undefined
            }
            onChange={(value) =>
              handleChangeSurgery(
                "date",
                moment(value).format("YYYY-MM-DD HH:mm")
              )
            }
          />
          <ETextInput
            label="Surgery done by"
            size="md"
            placeholder="Enter name"
            value={payloadForSurgery?.surgery_done_by}
            onChange={(e) =>
              handleChangeSurgery("surgery_done_by", e.target.value)
            }
          />
        </div>
        <div className="grid grid-cols-4 mt-5 gap-3">
          <Checkbox
            label="Outside study center"
            checked={outsideStudyCenter}
            onChange={() => {
              setOutSideStudyCenter(!outsideStudyCenter);
            }}
          />
          {outsideStudyCenter ? (
            <ETextInput
              placeholder="Center Name"
              value={centerName}
              onChange={(e) => setCenterName(e.target.value)}
            />
          ) : (
            <></>
          )}
        </div>

        <div>
          <FormSectionDivider title={"Type of Surgery"} classes="mb-3" />
          <Grid>
            {[
              {
                label: "Laparoscopic Cholecystectomy",
                key: "laparoscopic_cholecystectomy",
                value: false,
                name: "type_of_surgery",
              },
              {
                label: "Open Cholecystectomy",
                key: "open_cholecystectomy",
                value: false,
                name: "type_of_surgery",
              },
            ].map((e) => (
              <Grid.Col key={e?.key} span={3} className="flex items-end">
                <Checkbox
                  label={e?.label}
                  checked={
                    !!payloadForSurgery?.type_of_surgery?.includes(e?.key)
                  }
                  onChange={(event) =>
                    handleChangeSurgery(
                      "type_of_surgery",
                      event?.target.checked ? e?.key : ""
                    )
                  }
                />
              </Grid.Col>
            ))}
          </Grid>
        </div>

        <div>
          <FormSectionDivider
            title={"Intra-operative Findings"}
            classes="mb-3"
          />
          <Grid>
            <Grid.Col span={8} className="flex items-end">
              <ETextInput
                className="w-full"
                value={payloadForSurgery?.intraoperative_findings}
                placeholder={"Intraoperative Findings"}
                onChange={(event) =>
                  handleChangeSurgery(
                    "intraoperative_findings",
                    event?.target.value
                  )
                }
              />
            </Grid.Col>
          </Grid>
        </div>
        <div>
          <FormSectionDivider title={"Gall bladder"} classes="mb-3" />
          <Grid>
            {[
              {
                label: "Clearly Visible",
                key: "clearly_visible",
                value: false,
                name: "gallBladder",
              },
              {
                label: "Not Visible",
                key: "not_visible",
                value: false,
                name: "gallBladder",
              },
              {
                label: "Adhered To Omentum",
                key: "adhered_to_omentum",
                value: false,
                name: "gallBladder",
              },
              {
                label: "Adhered To Duodenum",
                key: "adhered_to_duodenum",
                value: false,
                name: "gallBladder",
              },
              {
                label: "Adhered To Colon",
                key: "adhered_to_colon",
                value: false,
                name: "gallBladder",
              },
            ].map((e) => (
              <Grid.Col key={e?.key} span={3} className="flex items-end">
                <Checkbox
                  label={e?.label}
                  checked={!!payloadForSurgery?.gall_bladder?.includes(e?.key)}
                  onChange={(event) =>
                    handleChangeSurgery(
                      "gall_bladder",
                      event?.target.checked ? e?.key : ""
                    )
                  }
                />
              </Grid.Col>
            ))}
          </Grid>
        </div>
        <div>
          <FormSectionDivider
            title={"Gall bladder distension"}
            classes="mb-3"
          />
          <Grid>
            {[
              {
                label: "Contracted",
                key: "contracted",
                value: false,
                name: "gallBladderDistension",
              },
              {
                label: "Partial",
                key: "partial",
                value: false,
                name: "gallBladderDistension",
              },
              {
                label: "Fully distended",
                key: "fully_distended",
                value: false,
                name: "gallBladderDistension",
              },
            ].map((e) => (
              <Grid.Col key={e?.key} span={3} className="flex items-end">
                <Checkbox
                  label={e?.label}
                  checked={
                    !!payloadForSurgery?.gall_bladder_distension?.includes(
                      e?.key
                    )
                  }
                  onChange={(event) =>
                    handleChangeSurgery(
                      "gall_bladder_distension",
                      event?.target.checked ? e?.key : ""
                    )
                  }
                />
              </Grid.Col>
            ))}
          </Grid>
        </div>
        <div>
          <FormSectionDivider
            title={"Any anatomical variation"}
            classes="mb-3"
          />
          <Grid>
            <Grid.Col span={8} className="flex items-end">
              <ETextInput
                className="w-full"
                placeholder="Any anatomical variation"
                value={payloadForSurgery?.any_anatomical_variation}
                onChange={(event) =>
                  handleChangeSurgery(
                    "any_anatomical_variation",
                    event?.target.value || ""
                  )
                }
              />
            </Grid.Col>
          </Grid>
        </div>

        <div>
          <FormSectionDivider title={"Cut section"} classes="mb-3" />
          <Grid>
            {
              [
                {
                  label: "Cholesterosis",
                  value: false,
                  key: "cholesterosis",
                  name: "cutSection",
                },
                {
                  label: "Polyp",
                  value: false,
                  key: "polyp",
                  name: "cutSection",
                },
                {
                  label: "Normal Mucosa",
                  value: false,
                  key: "normal_mucosa",
                  name: "cutSection",
                },
              ].map((e) => (
                <Grid.Col key={e.key} span={3} className="flex items-end">
                  <Checkbox
                    label={e.label}
                    checked={payloadForSurgery?.cut_section?.split(", ")?.includes(e.key)}
                    onChange={(event) => {
                      const currentSelections = payloadForSurgery?.cut_section?.split(", ").filter(Boolean) || [];

                      const updatedSelections = event.target.checked
                        ? [...currentSelections, e.key] // Add the new key if checked
                        : currentSelections?.filter((item) => item !== e.key); // Remove the key if unchecked

                      handleChangeSurgery("cut_section", updatedSelections?.join(", "));
                    }}
                  />
                </Grid.Col>
              ))
            }

            <Grid.Col span={3} className="flex items-end">
              <ETextInput
                value={
                  !payloadForSurgery?.cut_section?.includes("normal_mucosa") &&
                    !payloadForSurgery?.cut_section?.includes("polyp") &&
                    !payloadForSurgery?.cut_section?.includes("cholesterosis")
                    ? payloadForSurgery?.cut_section
                    : ""
                }
                placeholder="Others"
                onChange={(event) =>
                  handleChangeSurgery("cut_section", event?.target?.value)
                }
              />
            </Grid.Col>
          </Grid>
        </div>
        <div>

          <FormSectionDivider title={"Bile spillage"} classes="mb-3" />
          <Grid>
            {[
              {
                label: "Yes",
                key: "yes",
                value: false,
                name: "BileSpillage",
              },
              {
                label: "No",
                key: "no",
                value: false,
                name: "BileSpillage",
              },
            ].map((e) => (
              <Grid.Col
                key={e?.key}
                span={3} className="flex items-end">
                <Checkbox
                  label={e?.label}
                  checked={
                    e?.key === "yes"
                      ? payloadForSurgery?.bile_spillage === true
                      : payloadForSurgery?.bile_spillage === false
                  }
                  onChange={(event) =>
                    handleChangeSurgery(
                      "bile_spillage",
                      e?.key === "yes"
                        ?
                        event.target.checked ? true : null
                        :
                        (event.target.checked ? false : null)
                    )
                  }
                />
              </Grid.Col>
            ))}
          </Grid>
        </div>

        <div>
          <FormSectionDivider title={""} classes="mb-3" />
          <Grid>
            {[
              {
                label: "Duration of procedure",
                key: "duration_of_procedure",
                value: "",
              },
              {
                label: "Difficulty",
                key: "difficulty",
                value: "",
              },
              {
                label: "Complications",
                key: "complications",
                value: "",
              },
            ].map((e) => (
              <Grid.Col span={6} className="flex items-end" key={e?.key}>

                {
                  e?.key === "complications" ?
                    <>


                      <ETextInput
                        className="w-full"
                        label={e?.label}
                        // @ts-ignore
                        value={payloadForSurgery?.[e?.key]}
                        onChange={(event) =>
                          // @ts-ignore
                          handleChangeSurgery(e?.key, event?.target?.value)
                        }
                        disabled={payloadForSurgery?.[e?.key] === "None"}
                      />
                      <ECheckBox
                        className="ml-6 -mt-2"
                        label={"None"}
                        checked={payloadForSurgery?.[e?.key] === "None"}
                        onChange={(event) =>
                          // @ts-ignore
                          handleChangeSurgery(e?.key, event?.target?.checked ? "None" : "")
                        }
                      />
                    </>
                    :
                    <ETextInput
                      className="w-full"
                      label={e?.label}
                      // @ts-ignore
                      value={payloadForSurgery?.[e?.key]}
                      onChange={(event) =>
                        // @ts-ignore
                        handleChangeSurgery(e?.key, event?.target?.value)
                      }
                    />
                }
              </Grid.Col>
            ))}
          </Grid>
        </div>
      </div>
      <div className="col-span-3 px-3">
        <div
          className="mx-5 p-5 w-full flex flex-col items-center justify-center border border-slate-400 border-dashed  rounded-md cursor-pointer"
          onClick={() => {
            setUploadFileModalIsOpen(true);
          }}
        >
          <MdOutlinePermMedia size={33} />
          <div className="mt-2 text-lg font-semibold">Upload Image / PDF</div>
        </div>

        <div className="mt-5">
          {mediaFiles.length > 0 ? (
            <ESectionDivider title="Uploaded Media" />
          ) : (
            <></>
          )}
          {mediaFiles?.map((item, index) => {
            return (
              <EMediaItem
                key={index + "media"}
                item={item}
                handleOpenMediaInNewTab={handleOpenMediaInNewTab}
                setIsOpenDeleteConfirmModal={setIsOpenDeleteConfirmModal}
                isOpenDeleteConfirmModal={isOpenDeleteConfirmModal}
                handleDeleteImageFromAws={handleDeleteImageFromAws}
              />
            );
          })}
        </div>
      </div>

      <EModal
        isOpen={uploadFileModalIsOpen}
        title={"Upload Media"}
        size="sm"
        setIsOpen={() => {
          setUploadFileModalIsOpen(false);
        }}
        onClose={() => {
          setFiles([]);
        }}
      >
        <div className="flex justify-end">
          <div className="flex-row">
            <Dropzone
              className="w-80"
              onDrop={(file) => {
                setFiles(file);
              }}
              accept={[
                MIME_TYPES.png,
                MIME_TYPES.jpeg,
                MIME_TYPES.webp,
                MIME_TYPES.pdf,
                MIME_TYPES.heic
              ]}
            >
              <div className="flex justify-center flex-col items-center text-center">
                <MdOutlinePermMedia size={33} />
                {files?.length > 0 ? (
                  <p>Upload Again Picture / pdf</p>
                ) : (
                  <p>Upload Picture / pdf</p>
                )}
              </div>
            </Dropzone>

            <div className="mt-4 max-h-44 overflow-y-scroll">
              {files?.map((file, index) => {
                return (
                  <div
                    key={index + "files"}
                    className="gap-2 lowercase text-sm font-medium"
                  >
                    {index + 1 + ". " + file.name}
                  </div>
                );
              })}
            </div>

            {!files?.length ? (
              <></>
            ) : (
              <div className=" flex gap-3 justify-around mt-8">
                <EButton
                  variant="secondary"
                  className="mt-4"
                  onClick={() => {
                    setFiles([]);
                    setUploadFileModalIsOpen(false);
                  }}
                >
                  Cancel
                </EButton>
                <EButton
                  className="mt-4"
                  loading={isMediaBtnLoading}
                  onClick={() => handleUploadFiles(files)}
                >
                  <HiOutlineArrowUpTray className="mr-2" />
                  Upload Media
                </EButton>
              </div>
            )}
          </div>
        </div>
      </EModal>
    </div>
  );
}
