import { Button, Tooltip } from "@mantine/core";
import React, { useState } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import { IoImageOutline } from "react-icons/io5";
import { MdOutlineVideoLibrary, MdDeleteOutline } from "react-icons/md";
import { EModal } from "./Modal/EModal";

interface IProps {
  item: string;
  handleOpenMediaInNewTab: (item: string) => void;
  setIsOpenDeleteConfirmModal: (isOpen: boolean) => void;
  isOpenDeleteConfirmModal: boolean;
  handleDeleteImageFromAws: (item: string) => void;
}

export default function EMediaItem({
  item,
  handleOpenMediaInNewTab,
  setIsOpenDeleteConfirmModal,
  isOpenDeleteConfirmModal,
  handleDeleteImageFromAws,
}: IProps) {
  const [isDeleteIconVisible, setIsDeleteIconVisible] = useState(false);
  return (
    <div
      className="w-full px-5 py-2 flex items-center justify-between font-semibold text-base bg-slate-200 rounded-md my-2 hover:bg-slate-300 "
      onMouseEnter={() => setIsDeleteIconVisible(true)}
      onMouseLeave={() => setIsDeleteIconVisible(false)}
    >
      {/* <div>
        {item?.includes("pdf") ? (
          <FaRegFilePdf size={22} />
        ) : item?.includes("mp4") ? (
          <MdOutlineVideoLibrary size={22} />
        ) : (
          <IoImageOutline size={22} />
        )}
      </div> */}
      <div className="w-full">
        <div className="flex flex-row justify-between w-full">
          <Tooltip label={item}>
            <p
              className="text-base text-blue-500 hover:text-blue-700 cursor-pointer w-full"
              onClick={() => {
                // handleDeleteImageFromAws(item)
                handleOpenMediaInNewTab(item);
              }}
            >
              {item?.split(".")[0]?.length > 40
                ? item?.split(".")[0].slice(0, 40) + "..."
                : item?.split(".")[0]}
            </p>
          </Tooltip>
          {isDeleteIconVisible && (
            <MdDeleteOutline
              onClick={() => {
                setIsOpenDeleteConfirmModal(true);
                // handleDeleteImageFromAws(item);
              }}
              size={25}
              className="text-red-500 hover:text-red-600 cursor-pointer"
            />
          )}
        </div>

        <div className="text-sm">
          {item?.includes("pdf") ? (
            <div>Document</div>
          ) : item?.includes("mp4") ? (
            <div>Video</div>
          ) : (
            <div>Image</div>
          )}
        </div>
      </div>

      <EModal
        isOpen={isOpenDeleteConfirmModal}
        title={"Are you sure you want to delete this file?"}
        size="md"
        setIsOpen={() => {}}
        onClose={() => setIsOpenDeleteConfirmModal(false)}
      >
        <div className="flex justify-end gap-4">
          <Button
            className="btn-secondary"
            onClick={() => setIsOpenDeleteConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color="red"
            onClick={() => handleDeleteImageFromAws(item)}
          >
            Yes, Delete
          </Button>
        </div>
      </EModal>
    </div>
  );
}
