import {
  ENumberInput,
  ESectionDivider,
  ETextInput,
  ESelect,
  ELabelView,
  EButton,
  EModal,
  ETable,
} from "../../../../libs/ui";
import { Checkbox, Grid, Space } from "@mantine/core";
import usePersonalHistory from "./hook/usePersonalHistory";
import {
  statesMaster,
  waterResource,
  vehicleMaster,
  recruitmentMethod,
} from "../../../../libs/data-access/utility";
import EAgeInput from "../../../../libs/ui/src/NumberInput/EAgeInput";
import { BsPlus } from "react-icons/bs";
import { useEffect } from "react";
import { EMultiSelectToString } from "../../../../libs/ui/src/MultiSelect/EMultiSelectToString";
import { FaEdit } from "react-icons/fa";
import { MdClose } from "react-icons/md";

type IProps = {
  viewOnly?: boolean;
};
export default function PersonalHistory({ viewOnly }: IProps) {
  viewOnly = false;
  const {
    personalHistory,
    handelChangeHistory,
    isOpenModalPregnancies,
    setIsOpenModalPregnancies,
    newPregnancies,
    handleChangePregnancies,
    calculateBMI,
    calculateWeightToHeightRatio,
    anthropometricUnits,
    handleChangeUnits,
    calculateValueByUnit,
    selectedPregnancyIndex,
    setSelectedPregnancyIndex
  } = usePersonalHistory();

  useEffect(() => {
    if (
      personalHistory?.anthropometric_measurement[0]?.height_cm &&
      personalHistory?.anthropometric_measurement[0]?.weight_kg
    ) {
      let heightArray =
        personalHistory?.anthropometric_measurement[0]?.height_cm
          ?.toString()
          .split("-");
      let height =
        heightArray[1] === "cm"
          ? Number(heightArray[0])
          : Number(heightArray[0]) * 2.54;
      calculateBMI(
        Number(personalHistory?.anthropometric_measurement[0]?.weight_kg),
        height
      );
    }
  }, [
    personalHistory?.anthropometric_measurement[0]?.height_cm,
    personalHistory?.anthropometric_measurement[0]?.weight_kg,
  ]);

  useEffect(() => {
    if (
      personalHistory?.anthropometric_measurement[0]?.waist_circumference &&
      personalHistory?.anthropometric_measurement[0]?.hip_circumference_cm
    ) {
      let hipArray =
        personalHistory?.anthropometric_measurement[0]?.hip_circumference_cm
          ?.toString()
          .split("-");
      let hip =
        hipArray[1] === "cm" ? Number(hipArray[0]) : Number(hipArray[0]) * 2.54;

      let waistArray =
        personalHistory?.anthropometric_measurement[0]?.waist_circumference
          ?.toString()
          .split("-");
      let waist =
        waistArray[1] === "cm"
          ? Number(waistArray[0])
          : Number(waistArray[0]) * 2.54;

      calculateWeightToHeightRatio(waist, hip);
    }
  }, [
    personalHistory?.anthropometric_measurement[0]?.waist_circumference,
    personalHistory?.anthropometric_measurement[0]?.hip_circumference_cm,
  ]);

  return (
    <>
      {/* <h3 className="mb-4">Personal History</h3> */}

      <ESectionDivider title="Nearest Health Care Center" />
      <div className="grid grid-cols-3 gap-4">
        <ETextInput
          label="Name of the tertiary care hospital"
          placeholder="Enter name"
          size="md"
          value={
            personalHistory?.nearest_health_care_center
              ? personalHistory?.nearest_health_care_center[0]
                ?.name_of_the_tertiary_care_hospital ?? ""
              : ""
          }
          onChange={(e) =>
            handelChangeHistory(
              "nearest_health_care_center",
              e.target.value ?? "",
              "name_of_the_tertiary_care_hospital"
            )
          }
        />
        <ETextInput
          label="Ultrasonology place"
          placeholder="Enter place"
          size="md"
          value={
            personalHistory?.nearest_health_care_center
              ? personalHistory?.nearest_health_care_center[0]
                ?.ultrasonology_place ?? ""
              : ""
          }
          onChange={(e) =>
            handelChangeHistory(
              "nearest_health_care_center",
              e.target.value ?? "",
              "ultrasonology_place"
            )
          }
        />
        <ETextInput
          label="Surgery place"
          placeholder="Enter place"
          size="md"
          value={
            personalHistory?.nearest_health_care_center
              ? personalHistory?.nearest_health_care_center[0]?.surgery_place ??
              ""
              : ""
          }
          onChange={(e) =>
            handelChangeHistory(
              "nearest_health_care_center",
              e.target.value ?? "",
              "surgery_place"
            )
          }
        />
      </div>

      <ESectionDivider title="Recruitment method" />
      <div className="grid grid-cols-4 gap-4">
        {recruitmentMethod.map((each, index) => (
          <Checkbox
            key={index + "each"}
            size="md"
            checked={personalHistory?.recruitment_method?.includes(each)}
            readOnly={viewOnly}
            label={each}
            onChange={(e) => {
              if (e?.target?.checked) {
                if (each === "None") {
                  handelChangeHistory("recruitment_method", "None");
                } else {
                  const UpdatedRecruitment =
                    personalHistory?.recruitment_method?.split(",");
                  handelChangeHistory(
                    "recruitment_method",
                    UpdatedRecruitment?.filter((e) => e?.trim() != "None") +
                    "," +
                    each
                  );
                }
              } else {
                const UpdatedRecruitment = personalHistory?.recruitment_method
                  ?.split(",")
                  ?.filter((e) => e?.trim() != each);
                handelChangeHistory(
                  "recruitment_method",
                  UpdatedRecruitment?.join(",")
                );
              }
            }}
          />
        ))}
      </div>

      <ESectionDivider title="Personal History" />
      <Grid>
        <Grid.Col span={4}>
          <ESelect
            label="State of residence (last 10 years)"
            required
            size="md"
            readOnly={viewOnly}
            data={statesMaster}
            placeholder="Current living state"
            value={personalHistory?.state_of_residence}
            onChange={(e) => handelChangeHistory("state_of_residence", e ?? "")}
          />
        </Grid.Col>
        {/* <Grid.Col span={4}>
            <ESelect
              label="State from which you belong"
              required
              size="md"
              data={statesMaster}
              readOnly={viewOnly}
              placeholder=" State from which you belong"
              value={personalHistory?.birth_state}
              onChange={(e) =>
                handelChangeHistory("birth_state", e ?? "")
              }
            /></Grid.Col> */}
        <Grid.Col span={4}>
          <ESelect
            label="Residence during (first 10 years)"
            required
            size="md"
            data={statesMaster}
            readOnly={viewOnly}
            placeholder=" Residence during (first 10 years)"
            value={personalHistory?.residence_during_childhood}
            onChange={(e) =>
              handelChangeHistory("residence_during_childhood", e ?? "")
            }
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={3}>
          <ESelect
            label="Lives In"
            size="md"
            readOnly={viewOnly}
            data={["Rural", "Urban", "Slum", "Semi-urban"]}
            placeholder="Select"
            value={personalHistory?.lives_in}
            onChange={(e) => (handelChangeHistory("lives_in", e))}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <ESelect
            label="Marital Status"
            size="md"
            data={["Married", "Unmarried", "Others"]}
            placeholder="Select"
            readOnly={viewOnly}
            value={personalHistory?.marital_status}
            onChange={(e) =>
              handelChangeHistory("marital_status", e)
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <ESelect
            label="Religion"
            size="md"
            data={["Hindu", "Muslim", "Christian", "Sikh", "Other"]}
            placeholder="Select"
            value={personalHistory?.religion}
            onChange={(e) => handelChangeHistory("religion", e)}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <ETextInput
            size="md"
            label="Ethnicity"
            placeholder="Enter ethnicity"
            value={personalHistory?.ethnicity}
            onChange={(e) => handelChangeHistory("ethnicity", e?.target.value)}
          />
        </Grid.Col>
      </Grid>

      <ESectionDivider title="Total Family Members living with you now" />
      <Grid>
        <Grid.Col span={2}>
          <ENumberInput
            size="md"
            label="Adults (>18 yrs age)"
            placeholder="Member count"
            min={0}
            maxLength={2}
            readOnly={viewOnly}
            value={personalHistory?.no_of_adults_family_member}
            onChange={(e) =>
              handelChangeHistory("no_of_adults_family_member", e ?? 0)
            }
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <ENumberInput
            size="md"
            label="Children (<18 yrs age)"
            placeholder="Member count"
            min={0}
            maxLength={2}
            readOnly={viewOnly}
            value={personalHistory?.no_of_child_family_member}
            onChange={(e) =>
              handelChangeHistory("no_of_child_family_member", e ?? 0)
            }
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col span={3}>
          <ETextInput
            size="md"
            label="Patient Education"
            placeholder="Enter Education"
            value={personalHistory?.patient_education}
            onChange={(e) =>
              handelChangeHistory("patient_education", e.target.value)
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <ETextInput
            size="md"
            label="Patient occupation"
            placeholder="Enter occupation"
            value={personalHistory?.patient_occupation}
            onChange={(e) =>
              handelChangeHistory("patient_occupation", e.target.value)
            }
          />
        </Grid.Col>
      </Grid>

      <ESectionDivider
        title="Kuppuswamy Scale"
        end={
          <p className="text-slate-500 text-sm">
            Socio-economic Class :
            <span className="text-slate-600 text-base">
              {personalHistory?.Socio_economic_class
                ? +personalHistory?.Socio_economic_class < 4
                  ? " Lower"
                  : +personalHistory?.Socio_economic_class < 11
                    ? " Upper Lower"
                    : +personalHistory?.Socio_economic_class < 16
                      ? " Lower Middle"
                      : +personalHistory?.Socio_economic_class < 25
                        ? " Upper Middle"
                        : +personalHistory?.Socio_economic_class < 30
                          ? " Upper"
                          : " ----"
                : " ----"}
            </span>
          </p>
        }
      />

      <div>

        {/* Occupation of the Head */}
        <div className="flex gap-3">
          <ESelect
            size="md"
            className="w-1/3"
            label="Occupation of the Head of family"
            placeholder="Select"
            clearable
            readOnly={viewOnly}
            data={[
              {
                label: "Legislators, Senior, Officials & Managers",
                value: "Legislators, Senior, Officials & Managers + 10",
              },
              {
                label: "Professionals",
                value: "Professionals + 9",
              },
              {
                label: "Technicians and Associate Professionals",
                value: "Technicians and Associate Professionals + 8",
              },
              {
                label: "Clerks",
                value: "Clerks + 7",
              },
              {
                label: "Skilled Workers and Shop & Market Sales Workers",
                value: "Skilled Workers and Shop & Market Sales Workers + 6",
              },
              {
                label: "Skilled Agricultural & Fishery Workers",
                value: "Skilled Agricultural & Fishery Workers + 5",
              },
              {
                label: "Craft& Related Trade Workers",
                value: "Craft& Related Trade Workers + 4",
              },
              {
                label: "Plant & Machine Operators and Assemblers",
                value: "Plant & Machine Operators and Assemblers + 3",
              },
              {
                label: "Elementary Occupation",
                value: "Elementary Occupation + 2",
              },
              {
                label: "Unemployed",
                value: "Unemployed + 1",
              },
              {
                label: "Others",
                value: "others",
              },
            ]}
            value={
              personalHistory?.kuppuswamy_scale_occupation_of_the_head &&
                personalHistory?.kuppuswamy_scale_occupation_of_the_head.includes(
                  "others"
                )
                ? "others"
                : personalHistory?.kuppuswamy_scale_occupation_of_the_head
            }
            onChange={(e) => {
              handelChangeHistory(
                "kuppuswamy_scale_occupation_of_the_head",
                e || ""
              );
            }}
          />

          {personalHistory?.kuppuswamy_scale_occupation_of_the_head.includes(
            "others"
          ) ? (
            <ETextInput
              label="Other"
              size="md"
              placeholder="Enter Other Occupation"
              value={
                personalHistory?.kuppuswamy_scale_occupation_of_the_head &&
                (personalHistory?.kuppuswamy_scale_occupation_of_the_head
                  .split("+")[0]
                  .replace("others", "") ??
                  "")
              }
              onChange={(e) =>
                handelChangeHistory(
                  "kuppuswamy_scale_occupation_of_the_head",
                  "others" + e.target.value
                )
              }
            />
          ) : (
            <></>
          )}

          <ENumberInput
            label="Score"
            placeholder="Enter Score"
            size="md"
            disabled={
              !personalHistory?.kuppuswamy_scale_occupation_of_the_head.includes(
                "others"
              )
            }
            maxLength={5}
            min={0}
            value={
              personalHistory?.kuppuswamy_scale_occupation_of_the_head &&
              (personalHistory?.kuppuswamy_scale_occupation_of_the_head.split(
                "+"
              )[1] ||
                "")
            }
            onChange={(e) =>
              handelChangeHistory(
                "kuppuswamy_scale_occupation_of_the_head",
                (personalHistory?.kuppuswamy_scale_occupation_of_the_head.split(
                  "+"
                )[0] ?? "") +
                " + " +
                e
              )
            }
          />
        </div>

        {/* Education */}
        <div className="flex gap-3">
          <ESelect
            size="md"
            className="w-1/3"
            label="Education"
            placeholder="Select"
            clearable
            readOnly={viewOnly}
            data={[
              {
                label: "Profession or Honors",
                value: "Profession or Honors + 7",
              },
              {
                label: "Graduate/ Post Graduate",
                value: "Graduate/ Post Graduate + 6",
              },
              {
                label: "Intermediate or diploma",
                value: "Intermediate or diploma + 5",
              },
              { label: "High school", value: "High school + 4" },
              { label: "Middle school", value: "Middle school + 3" },
              { label: "Primary school", value: "Primary school + 2" },
              { label: "Illiterate", value: "Illiterate + 1" },
              { label: "Others", value: "others" },
            ]}
            value={personalHistory?.kuppuswamy_scale_education_of_the_head}
            onChange={(e) => {
              handelChangeHistory(
                "kuppuswamy_scale_education_of_the_head",
                e || ""
              );
            }}
          />

          {personalHistory?.kuppuswamy_scale_education_of_the_head.includes(
            "others"
          ) ? (
            <ETextInput
              label="Other"
              size="md"
              placeholder="Enter Other Eduction"
              value={
                personalHistory?.kuppuswamy_scale_education_of_the_head &&
                (personalHistory?.kuppuswamy_scale_education_of_the_head
                  .split("+")[0]
                  .replace("others", "") ??
                  "")
              }
              onChange={(e) =>
                handelChangeHistory(
                  "kuppuswamy_scale_education_of_the_head",
                  "others" + e.target.value
                )
              }
            />
          ) : (
            <></>
          )}

          <ENumberInput
            label="Score"
            placeholder="Enter Score"
            size="md"
            disabled={
              !personalHistory?.kuppuswamy_scale_education_of_the_head.includes(
                "others"
              )
            }
            maxLength={5}
            min={0}
            value={
              personalHistory?.kuppuswamy_scale_education_of_the_head &&
              (personalHistory?.kuppuswamy_scale_education_of_the_head.split(
                "+"
              )[1] ||
                "")
            }
            onChange={(e) =>
              handelChangeHistory(
                "kuppuswamy_scale_education_of_the_head",
                (personalHistory?.kuppuswamy_scale_education_of_the_head.split(
                  "+"
                )[0] ?? "") +
                " + " +
                e
              )
            }
          />
        </div>

        {/* Monthly Family Income */}
        <div className="flex gap-3">
          <ESelect
            size="md"
            className="w-1/3"
            label="Updated Monthly Family Income in Rs."
            placeholder="Select"
            clearable
            readOnly={viewOnly}
            data={[
              { label: "> 1,26,360 Rs", value: "> 1,26,360 Rs + 12" },
              {
                label: "63,182 Rs - 1,26,356 Rs",
                value: "63,182 Rs - 1,26,356 Rs + 10",
              },
              {
                label: "47,266 Rs - 63,178 Rs",
                value: "47,266 Rs - 63,178 Rs + 6",
              },
              {
                label: "31,591 Rs - 47,262 Rs",
                value: "31,591 Rs - 47,262 Rs + 4",
              },
              {
                label: "18,953 Rs - 31,589 Rs",
                value: "18,953 Rs - 31,589 Rs + 3",
              },
              {
                label: "6327 Rs - 18,949 Rs",
                value: "6327 Rs - 18,949 Rs + 2",
              },
              { label: "<= 6323", value: "<= 6323 + 1" },
              { label: "Others", value: "others" },
            ]}
            value={
              personalHistory?.kuppuswamy_scale_updated_monthly_family_income
            }
            onChange={(e) => {
              handelChangeHistory(
                "kuppuswamy_scale_updated_monthly_family_income",
                e || ""
              );
            }}
          />

          {personalHistory?.kuppuswamy_scale_updated_monthly_family_income.includes(
            "others"
          ) ? (
            <ETextInput
              label="Other"
              size="md"
              placeholder="Enter Income"
              value={
                personalHistory?.kuppuswamy_scale_updated_monthly_family_income &&
                (personalHistory?.kuppuswamy_scale_updated_monthly_family_income
                  .split("+")[0]
                  .replace("others", "") ??
                  "")
              }
              onChange={(e) =>
                handelChangeHistory(
                  "kuppuswamy_scale_updated_monthly_family_income",
                  "others" + e.target.value
                )
              }
            />
          ) : (
            <></>
          )}

          <ENumberInput
            label="Score"
            placeholder="Enter Score"
            size="md"
            disabled={
              !personalHistory?.kuppuswamy_scale_updated_monthly_family_income.includes(
                "others"
              )
            }
            maxLength={5}
            min={0}
            value={
              personalHistory?.kuppuswamy_scale_updated_monthly_family_income &&
              (personalHistory?.kuppuswamy_scale_updated_monthly_family_income.split(
                "+"
              )[1] ||
                "")
            }
            onChange={(e) =>
              handelChangeHistory(
                "kuppuswamy_scale_updated_monthly_family_income",
                (personalHistory?.kuppuswamy_scale_updated_monthly_family_income.split(
                  "+"
                )[0] ?? "") +
                " + " +
                e
              )
            }
          />
        </div>

      </div>

      <ESectionDivider title="Vehicle" />
      <div className="flex gap-4">
        <EMultiSelectToString
          label="Vehicle"
          placeholder="Select"
          size="md"
          readOnly={viewOnly}
          data={vehicleMaster}
          value={personalHistory?.vehicle}
          onChange={(e) => {
            handelChangeHistory("vehicle", e ?? "");
          }}
        />
      </div>

      <ESectionDivider title="Water Source" />
      <div className="flex flex-wrap items-end">
        {waterResource.map((each, index) => (
          <Checkbox
            size="md"
            className="w-1/6 mb-2"
            checked={personalHistory?.water_source?.includes(each)}
            readOnly={viewOnly}
            label={each}
            onChange={(e) => {
              if (e?.target?.checked) {
                handelChangeHistory(
                  "water_source",
                  personalHistory?.water_source + "," + each
                );
              } else {
                const UpdatedWaterSource = personalHistory?.water_source
                  ?.split(",")
                  ?.filter((e) => e != each);
                handelChangeHistory(
                  "water_source",
                  UpdatedWaterSource.join(",")
                );
              }
            }}
          />
        ))}
      </div>

      <ESectionDivider title="Reproductive History" />
      <div className="flex gap-2 mb-3">
        <Checkbox
          label="Regular"
          checked={
            personalHistory?.reproductive_h_per_o
              ? personalHistory?.reproductive_h_per_o[0]?.regular === true
              : false
          }
          onChange={(e) =>
            handelChangeHistory(
              "reproductive_h_per_o",
              e.target.checked ? true : null,
              "regular"
            )
          }
        />
        <Checkbox
          label="Irregular"
          checked={
            personalHistory?.reproductive_h_per_o
              ? personalHistory?.reproductive_h_per_o[0]?.regular === false
              : false
          }
          onChange={(e) =>
            handelChangeHistory(
              "reproductive_h_per_o",
              e.target.checked ? false : null,
              "regular"
            )
          }
        />
      </div>
      <Grid align="end">
        <Grid.Col span={3}>
          <ENumberInput
            size="md"
            label="Menarche"
            min={0}
            maxLength={5}
            placeholder="Menarche age"
            value={
              personalHistory?.reproductive_h_per_o
                ? personalHistory?.reproductive_h_per_o[0]?.menarche
                : ""
            }
            onChange={(e) =>
              handelChangeHistory("reproductive_h_per_o", e ?? 0, "menarche")
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <ENumberInput
            size="md"
            min={0}
            maxLength={5}
            label="Menopause"
            placeholder="Menopause age"
            value={
              personalHistory?.reproductive_h_per_o
                ? personalHistory?.reproductive_h_per_o[0]?.menopause
                : ""
            }
            onChange={(e) =>
              handelChangeHistory("reproductive_h_per_o", e ?? 0, "menopause")
            }
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <EAgeInput
            size="md"
            label="Age at marriage"
            hideControls
            maxLength={5}
            min={0}
            placeholder="Age"
            value={
              personalHistory?.reproductive_h_per_o
                ? personalHistory?.reproductive_h_per_o[0]?.age_at_marriage
                : ""
            }
            onChange={(e) =>
              handelChangeHistory(
                "reproductive_h_per_o",
                e ?? 0,
                "age_at_marriage"
              )
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <ENumberInput
            size="md"
            label="Breast feed (Cumulative breast feeding months)"
            placeholder="Total months"
            maxLength={5}
            hideControls
            min={0}
            value={
              personalHistory?.reproductive_h_per_o
                ? personalHistory?.reproductive_h_per_o[0]?.breast_feed_months
                : ""
            }
            onChange={(e) =>
              handelChangeHistory(
                "reproductive_h_per_o",
                e,
                "breast_feed_months"
              )
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <EAgeInput
            size="md"
            label="Age at first child birth (years)"
            min={0}
            hideControls
            maxLength={5}
            placeholder="Age"
            value={
              personalHistory?.reproductive_h_per_o
                ? personalHistory?.reproductive_h_per_o[0]
                  ?.age_at_first_child_birth
                : ""
            }
            onChange={(e) =>
              handelChangeHistory(
                "reproductive_h_per_o",
                e ?? 0,
                "age_at_first_child_birth"
              )
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <EAgeInput
            size="md"
            label="Age at last child birth (years)"
            min={0}
            hideControls
            maxLength={5}
            placeholder="Age"
            value={
              personalHistory?.reproductive_h_per_o
                ? personalHistory?.reproductive_h_per_o[0]
                  ?.age_at_last_child_birth
                : ""
            }
            onChange={(e) =>
              handelChangeHistory(
                "reproductive_h_per_o",
                e ?? 0,
                "age_at_last_child_birth"
              )
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <ENumberInput
            size="md"
            label="Total no. of Children"
            hideControls
            maxLength={5}
            min={0}
            placeholder="No. of children"
            value={
              personalHistory?.reproductive_h_per_o
                ? personalHistory?.reproductive_h_per_o[0]?.total_no_of_children
                : ""
            }
            onChange={(e) =>
              handelChangeHistory(
                "reproductive_h_per_o",
                e ?? 0,
                "total_no_of_children"
              )
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <ENumberInput
            size="md"
            label="Total no. of Abortions"
            hideControls
            maxLength={5}
            min={0}
            placeholder="No. of abortions"
            value={
              personalHistory?.reproductive_h_per_o
                ? personalHistory?.reproductive_h_per_o[0]
                  ?.total_no_of_abortions
                : ""
            }
            onChange={(e) =>
              handelChangeHistory(
                "reproductive_h_per_o",
                e ?? 0,
                "total_no_of_abortions"
              )
            }
          />
        </Grid.Col>
      </Grid>

      <div className="flex justify-between gap-6 mt-5">
        <ELabelView
          label="Gynaecological surgery"
          value={
            <div className="flex gap-4">
              <Checkbox
                checked={
                  personalHistory?.reproductive_h_per_o
                    ? personalHistory?.reproductive_h_per_o[0]
                      ?.gynaecological_surgery
                    : false
                }
                label="Yes"
                onChange={(e) =>
                  handelChangeHistory(
                    "reproductive_h_per_o",
                    e?.target.checked ? true : null,
                    "gynaecological_surgery"
                  )
                }
              />
              <Checkbox
                checked={
                  personalHistory?.reproductive_h_per_o
                    ? personalHistory?.reproductive_h_per_o[0]
                      ?.gynaecological_surgery == false
                      ? true
                      : false
                    : false
                }
                label="No"
                onChange={(e) =>
                  handelChangeHistory(
                    "reproductive_h_per_o",
                    e?.target.checked ? false : null,
                    "gynaecological_surgery"
                  )
                }
              />
            </div>
          }
        />
        <ELabelView
          label="Which Surgery"
          value={
            <>
              <div className="flex gap-4">
                {[
                  "Hysterectomy",
                  "Hysterectomy with Salpingo-oophorectomy",
                  "Tubal-ligation",
                  "Others",
                ].map((each) => (
                  <Checkbox
                    label={each}
                    checked={
                      personalHistory?.reproductive_h_per_o
                        ? each === "Others"
                          ? personalHistory?.reproductive_h_per_o[0]?.which_surgery?.includes(
                            "Others"
                          )
                          : personalHistory?.reproductive_h_per_o[0]
                            ?.which_surgery == each
                            ? true
                            : false
                        : false
                    }
                    onChange={(event) => {
                      if (event.target.checked) {
                        if (each == "Others") {
                          const UpdatedIndustries =
                            personalHistory?.reproductive_h_per_o
                              ? personalHistory?.reproductive_h_per_o[0]?.which_surgery?.split(
                                ","
                              )
                              : [","];
                          handelChangeHistory(
                            "reproductive_h_per_o",
                            UpdatedIndustries.join(",") + ",Others-",
                            "which_surgery"
                          );
                        } else {
                          const UpdatedIndustries =
                            personalHistory?.reproductive_h_per_o
                              ? personalHistory?.reproductive_h_per_o[0]?.which_surgery?.split(
                                ","
                              )
                              : [""];
                          handelChangeHistory(
                            "reproductive_h_per_o",
                            each,
                            "which_surgery"
                          );
                        }
                      } else {
                        const UpdatedRecruitment =
                          personalHistory?.reproductive_h_per_o
                            ? personalHistory?.reproductive_h_per_o[0]?.which_surgery
                              ?.split(",")
                              .filter((e) => !e.includes(each))
                            : [];
                        handelChangeHistory(
                          "reproductive_h_per_o",
                          UpdatedRecruitment.join(","),
                          "which_surgery"
                        );
                      }
                    }}
                  />
                ))}
              </div>
              <div className="flex justify-end">
                {personalHistory?.reproductive_h_per_o &&
                  personalHistory?.reproductive_h_per_o[0]?.which_surgery?.includes(
                    "Others"
                  ) ? (
                  <ETextInput
                    placeholder="Enter others"
                    className="mt-2 w-1/6"
                    value={
                      personalHistory?.reproductive_h_per_o
                        ? personalHistory?.reproductive_h_per_o[0]?.which_surgery
                          ?.split(",")
                          ?.filter((e) => e?.includes("Others-"))[0]
                          ?.split("Others-")[1] ?? ""
                        : ""
                    }
                    onChange={(event) => {
                      const UpdatedRecruitment =
                        personalHistory?.reproductive_h_per_o
                          ? personalHistory?.reproductive_h_per_o[0]?.which_surgery?.split(
                            ","
                          )
                          : [];
                      const index = UpdatedRecruitment?.findIndex((e) =>
                        e?.includes("Others-")
                      );
                      UpdatedRecruitment?.splice(
                        index,
                        1,
                        "Others-" + event?.target?.value
                      );
                      handelChangeHistory(
                        "reproductive_h_per_o",
                        UpdatedRecruitment.join(","),
                        "which_surgery"
                      );
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </>
          }
        />
      </div>

      <div className="mt-4"></div>
      {/* <ELabelView value="" label={"Gynaecological surgery"}></ELabelView> */}
      <div className="grid grid-cols-4 gap-4">
        <ENumberInput
          size="md"
          min={0}
          maxLength={5}
          label="Age at which surgery done"
          placeholder="Age"
          value={
            personalHistory?.reproductive_h_per_o
              ? personalHistory?.reproductive_h_per_o[0]
                ?.age_at_which_surgery_done
              : ""
          }
          onChange={(e) =>
            handelChangeHistory(
              "reproductive_h_per_o",
              e ?? 0,
              "age_at_which_surgery_done"
            )
          }
        />
      </div>

      <ESectionDivider
        title="Pregnancies Details"
        classes="mb-12"
        end={
          <EButton
            className="btn-blue"
            onClick={() => setIsOpenModalPregnancies(true)}
          >
            Add Pregnancies
          </EButton>
        }
      />
      {!personalHistory?.pregnancies_history?.length ? (
        <></>
      ) : (
        <div className="mt-8 mb-8">
          <ETable>
            <thead>
              <tr>
                <th>No. of pregnancies</th>
                <th>Duration of pregnancy</th>
                <th>How did the pregnancy end?</th>
                <th>Type of delivery</th>
                <th>Complications</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {personalHistory?.pregnancies_history?.map((item, index) => (
                selectedPregnancyIndex === index ?
                  <tr className="text-center">
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      <ENumberInput
                        size="md"
                        maxLength={5}
                        placeholder="Months"
                        value={
                          item?.duration_of_pregnancy || undefined
                        }
                        onChange={(e) =>
                          handelChangeHistory(
                            "pregnancies_history",
                            e ?? null,
                            "duration_of_pregnancy",
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <ESelect
                        size="md"
                        // readOnly={viewOnly}
                        data={[
                          "Baby was born alive",
                          "Miscarriage",
                          "Induced abortion",
                          "Still Birth",
                          "Ongoing",
                          " Single/Twin",
                        ]}
                        placeholder="Select"
                        value={item?.how_did_the_pregnancy_end || undefined}
                        onChange={(e) =>
                          handelChangeHistory(
                            "pregnancies_history",
                            e ?? null,
                            "how_did_the_pregnancy_end",
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <ESelect
                        size="md"
                        // readOnly={viewOnly}
                        data={[" Vaginal birth", "Cesarean delivery"]}
                        placeholder="Select"
                        value={item?.type_of_delivery || undefined}
                        onChange={(e) =>
                          handelChangeHistory(
                            "pregnancies_history",
                            e ?? null,
                            "type_of_delivery",
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <ESelect
                        size="md"
                        data={[
                          "Gestational Diabetes",
                          "Gall stones during/ after pregnancy",
                          "Pregnancy induced hypertension",
                          "IUGR",
                          "Others",
                          "None",
                        ]}
                        placeholder="Select"
                        value={item?.complications.split("-")[0] ?? undefined}
                        onChange={(e) =>
                          handelChangeHistory(
                            "pregnancies_history",
                            e ?? null,
                            "complications",
                            index
                          )
                        }
                      />
                    </td>
                    <td>
                      <div
                        className="text-red-600 cursor-pointer p-2 flex flex-1 items-center justify-center"
                        onClick={() => setSelectedPregnancyIndex(undefined)}
                      >
                        <MdClose size={24} />
                      </div>
                    </td>
                  </tr> :
                  <tr className="text-center">
                    <td>{index + 1}</td>
                    <td>{item?.duration_of_pregnancy}</td>
                    <td>{item?.how_did_the_pregnancy_end}</td>
                    <td>{item?.type_of_delivery}</td>
                    <td>{item?.complications}</td>
                    <td>
                      <div
                        className="text-green-600 cursor-pointer p-2 flex flex-1 items-center justify-center"
                        onClick={() => setSelectedPregnancyIndex(index)}
                      >
                        <FaEdit size={18} />
                      </div>
                    </td>
                  </tr>
              ))}
            </tbody>
          </ETable>
        </div>
      )}

      {/* Anthropic Measurement */}
      <ESectionDivider title="Anthropometric Measurement" />
      <div className="grid grid-cols-3 gap-4">
        <ENumberInput
          label={`Height (cm/inch)`}
          size="md"
          min={0}
          maxLength={20}
          placeholder="Height"
          rightSectionWidth={"auto"}
          rightSection={
            <select
              value={
                personalHistory?.anthropometric_measurement[0]?.height_cm
                  ?.toString()
                  .split("-")
                  ? personalHistory?.anthropometric_measurement[0]?.height_cm
                    ?.toString()
                    .split("-")[1]
                  : "cm"
              }
              style={{ height: "100%", padding: "10px", borderRadius: "4px" }}
              onChange={(e) =>
                handelChangeHistory(
                  "anthropometric_measurement",
                  `${personalHistory?.anthropometric_measurement[0]?.height_cm?.split(
                    "-"
                  )
                    ? personalHistory?.anthropometric_measurement[0]?.height_cm?.split(
                      "-"
                    )[0]
                    : ""
                  }-${e.target.value}`,
                  "height_cm"
                )
              }
            >
              <option value="cm">
                <p className="p-2">cm</p>
              </option>
              <option value="inches">
                <p className="p-2">inch</p>
              </option>
            </select>
          }
          value={
            personalHistory?.anthropometric_measurement[0]?.height_cm
              ?.toString()
              .split("-")
              ? personalHistory?.anthropometric_measurement[0]?.height_cm
                ?.toString()
                .split("-")[0]
              : ""
          }
          onChange={(e) => {
            handelChangeHistory(
              "anthropometric_measurement",
              `${personalHistory?.anthropometric_measurement[0]?.height_cm
                ?.toString()
                ?.split("-")
                ? `${e}-${personalHistory?.anthropometric_measurement[0]?.height_cm
                  ?.toString()
                  ?.split("-")[1] ?? "cm"
                }`
                : e + "-cm"
              }`,
              "height_cm"
            );
          }}
        />

        <ENumberInput
          label="Weight (kg)"
          size="md"
          min={0}
          placeholder="Weight (kg)"
          value={
            personalHistory?.anthropometric_measurement[0]?.weight_kg
          }
          onChange={(e) =>
            handelChangeHistory("anthropometric_measurement", e, "weight_kg")
          }
        />

        <ENumberInput
          label="BMI (kg/m²)"
          size="md"
          min={0}
          placeholder="BMI (kg/m²)"
          value={
            personalHistory?.anthropometric_measurement[0]
              ?.bmi_kg_per_m_square
          }
          readOnly
        />

        <ENumberInput
          label="Waist Circumference (cm / inch)"
          size="md"
          min={0}
          maxLength={5}
          placeholder="Waist Circumference (cm)"
          rightSectionWidth={"auto"}
          rightSection={
            <select
              value={
                personalHistory?.anthropometric_measurement[0]?.waist_circumference
                  ?.toString()
                  .split("-")
                  ? personalHistory?.anthropometric_measurement[0]?.waist_circumference
                    ?.toString()
                    .split("-")[1]
                  : "cm"
              }
              style={{ height: "100%", padding: "10px", borderRadius: "4px" }}
              onChange={(e) =>
                handelChangeHistory(
                  "anthropometric_measurement",
                  `${personalHistory?.anthropometric_measurement[0]?.waist_circumference?.split(
                    "-"
                  )
                    ? personalHistory?.anthropometric_measurement[0]?.waist_circumference?.split(
                      "-"
                    )[0]
                    : ""
                  }-${e?.target.value}`,
                  "waist_circumference"
                )
              }
            >
              <option value="cm">
                <p className="p-2">cm</p>
              </option>
              <option value="inches">
                <p className="p-2 ">inch</p>
              </option>
            </select>
          }
          value={
            personalHistory?.anthropometric_measurement[0]?.waist_circumference
              ?.toString()
              .split("-")
              ? personalHistory?.anthropometric_measurement[0]?.waist_circumference
                ?.toString()
                .split("-")[0]
              : ""
          }
          onChange={(e) => {
            handelChangeHistory(
              "anthropometric_measurement",
              `${personalHistory?.anthropometric_measurement[0]?.waist_circumference
                ?.toString()
                ?.split("-")
                ? `${e}-${personalHistory?.anthropometric_measurement[0]?.waist_circumference
                  ?.toString()
                  ?.split("-")[1] ?? "cm"
                }`
                : e + "-cm"
              }`,
              "waist_circumference"
            );
          }}
        />
        <ENumberInput
          size="md"
          label="Hip Circumference (cm / inch)"
          min={0}
          maxLength={5}
          placeholder="Hip Circumference (cm)"
          rightSectionWidth={"auto"}
          rightSection={
            <select
              value={
                personalHistory?.anthropometric_measurement[0]?.hip_circumference_cm
                  ?.toString()
                  .split("-")
                  ? personalHistory?.anthropometric_measurement[0]?.hip_circumference_cm
                    ?.toString()
                    .split("-")[1]
                  : "cm"
              }
              style={{ height: "100%", padding: "10px", borderRadius: "4px" }}
              onChange={(e) =>
                handelChangeHistory(
                  "anthropometric_measurement",
                  `${personalHistory?.anthropometric_measurement[0]?.hip_circumference_cm?.split(
                    "-"
                  )
                    ? personalHistory?.anthropometric_measurement[0]?.hip_circumference_cm?.split(
                      "-"
                    )[0]
                    : ""
                  }-${e.target.value}`,
                  "hip_circumference_cm"
                )
              }
            >
              <option value="cm">
                <p className="p-2">cm</p>
              </option>
              <option value="inches">
                <p className="p-2 rounde">inch</p>
              </option>
            </select>
          }
          value={
            personalHistory?.anthropometric_measurement[0]?.hip_circumference_cm
              ?.toString()
              .split("-")
              ? personalHistory?.anthropometric_measurement[0]?.hip_circumference_cm
                ?.toString()
                .split("-")[0]
              : ""
          }
          onChange={(e) => {
            handelChangeHistory(
              "anthropometric_measurement",
              `${personalHistory?.anthropometric_measurement[0]?.hip_circumference_cm
                ?.toString()
                ?.split("-")
                ? `${e}-${personalHistory?.anthropometric_measurement[0]?.hip_circumference_cm
                  ?.toString()
                  ?.split("-")[1] ?? "cm"
                }`
                : e + "-cm"
              }`,
              "hip_circumference_cm"
            );
          }}
        />



        <ENumberInput
          label="W/H ratio"
          size="md"
          min={0}
          placeholder="W/H ratio"
          value={Number(
            personalHistory?.anthropometric_measurement[0]
              ?.weight_height_ratio_kg_m_sqaure || 0
          )}
          readOnly
        />


        <ENumberInput
          label="Blood Pressure (mmHg)"
          size="md"
          min={0}
          placeholder="Blood Pressure (mmHg)"
          value={
            personalHistory?.anthropometric_measurement[0]?.blood_pressure
          }
          onChange={(e) =>
            handelChangeHistory(
              "anthropometric_measurement",
              e,
              "blood_pressure"
            )
          }
        />
      </div>

      {/* Add Pregnancies */}
      <EModal
        isOpen={isOpenModalPregnancies}
        title="Pregnancies Details"
        size="xl"
        setIsOpen={() => {
          handleChangePregnancies("default", "");
          setIsOpenModalPregnancies(false);
        }}
      >
        <Grid>
          <Grid.Col span={6}>
            <ESelect
              label="How did the pregnancy end?"
              size="md"
              // readOnly={viewOnly}
              data={[
                "Baby was born alive",
                "Miscarriage",
                "Induced abortion",
                "Still Birth",
                "Ongoing",
                " Single/Twin",
              ]}
              placeholder="Select"
              value={newPregnancies?.how_did_the_pregnancy_end}
              onChange={(e) =>
                handleChangePregnancies("how_did_the_pregnancy_end", e ?? "")
              }
            />
            <ESelect
              label="Complications"
              size="md"
              className="mt-2"
              // readOnly={viewOnly}
              data={[
                "Gestational Diabetes",
                "Gall stones during/ after pregnancy",
                "Pregnancy induced hypertension",
                "IUGR",
                "Others",
                "None",
              ]}
              placeholder="Select"
              value={newPregnancies?.complications.split("-")[0] ?? undefined}
              onChange={(e) =>
                handleChangePregnancies("complications", e ?? "")
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <ENumberInput
              size="md"
              // className="mt-2"
              label="Duration of pregnancy"
              maxLength={5}
              placeholder="Months"
              value={
                newPregnancies?.duration_of_pregnancy
                  ? +newPregnancies?.duration_of_pregnancy
                  : undefined
              }
              onChange={(e) => handleChangePregnancies("duration_of_pregnancy", String(e))}
            />
            <ESelect
              label="Type of delivery"
              size="md"
              className="mt-2"
              // readOnly={viewOnly}
              data={[" Vaginal birth", "Cesarean delivery"]}
              placeholder="Select"
              value={newPregnancies?.type_of_delivery}
              onChange={(e) =>
                handleChangePregnancies("type_of_delivery", e ?? "")
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            {newPregnancies?.complications?.includes("Others") ? (
              <ETextInput
                size="md"
                // className="mt-2"
                label="Other Complications"
                placeholder="Remarks"
                value={
                  newPregnancies?.complications?.split("-")[1] ?? undefined
                }
                onChange={(e) =>
                  handleChangePregnancies(
                    "complications",
                    "Others-" + e?.target?.value
                  )
                }
              />
            ) : (
              <></>
            )}
          </Grid.Col>
        </Grid>
        <div className="flex justify-end mt-5 p-2">
          <EButton
            className="btn-blue"
            onClick={() => {
              if (personalHistory?.pregnancies_history?.length) {
                handelChangeHistory("pregnancies_history", [
                  ...personalHistory?.pregnancies_history,
                  newPregnancies,
                ]);
              } else {
                handelChangeHistory("pregnancies_history", [newPregnancies]);
              }
              setIsOpenModalPregnancies(false);
              handleChangePregnancies("default", "");
            }}
          >
            <BsPlus className="mr-1" size={20} /> Add
          </EButton>
        </div>
      </EModal>

    </>
  );
}
