import { useContext, useEffect, useState } from "react";
import { IPayloadForHistology } from "../../../../../types";
import { useSearchParams } from "react-router-dom";
import { PatientContext } from "../../../PatientsContext";
import { useDirectusApi } from "../../../../../libs/data-access";
import { showToast } from "../../../../../utils/utility";
import { FileWithPath } from "@mantine/dropzone";
import useUploadFiles from "../../../../Hooks/useUploadFiles";

type ISectionBody = {
  label: string;
  key: string;
  value: string | boolean;
  name?: string;
  placeholder?: string;
};
type ISection = {
  sectionTitle: string;
  sectionKey: keyof IPayloadForHistology;
  body: ISectionBody[];
  isMultiple?: boolean;
};

export function useHistology() {
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");

  const { postDirectData, fetchDirectData, patchDirectData } = useDirectusApi();

  const { handleUploadMedia, handleGetPreviewUrl, handleDeleteAwsImage } =
    useUploadFiles();

  const {
    activeTab,
    isBtnNextClick,
    setIsBtnLoading,
    handleNextTab,
    setIsBtnDisable,
    isFormUpdating,
    setIsFormUpdating,
  } = useContext(PatientContext);

  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
    useState<boolean>(false);

  // const [histology, setHistology] = useState<ISection[]>();

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [isMediaBtnLoading, setIsMediaBtnLoading] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<string[]>([]);
  const [uploadFileModalIsOpen, setUploadFileModalIsOpen] = useState(false);
  const [previousId, setPreviousId] = useState<number | undefined>(undefined);

  const [newPayloadForHistology, setNewPayloadForHistology] =
    useState<IPayloadForHistology>({} as IPayloadForHistology);
  const [growthPolypUlcer, setGrowthPolypUlcer] = useState<string>("");

  useEffect(() => {
    setIsBtnDisable(false);
    if (isBtnNextClick && activeTab == "histology") {
      postHistologyData();
    }
  }, [isBtnNextClick]);

  useEffect(() => {
    if (activeTab == "histology") {
      getDetailsByVisitId();
    }
  }, [activeTab]);

  //  handler

  const handleUploadFiles = async (files: FileWithPath[]) => {
    setIsMediaBtnLoading(true);
    const uploadedFiles = await handleUploadMedia(
      visit_id + "",
      patient_id + "",
      "histology",
      files
    );
    if (uploadedFiles?.length > 0) {
      handleUploadFileNamesInDb(uploadedFiles);
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: "Error uploading media files",
      });
    }
  };

  const handleUploadFileNamesInDb = async (uploadedFiles: string[]) => {
    const uniqueSet = new Set([...uploadedFiles, ...mediaFiles]);
    if (patient_id && visit_id) {
      let payload: IPayloadForHistology = {
        ...newPayloadForHistology,
        growth_polyp_ulcer:
          newPayloadForHistology?.growth_polyp_ulcer + "," + growthPolypUlcer,
        status: "published",
        user_id: patient_id,
        visit_id: +visit_id,
        media_files: JSON.stringify(Array.from(uniqueSet)),
      };
      if (isFormUpdating) {
        const res = await patchDirectData(
          `items/histology_gb_tissue/${previousId}`,
          payload
        );
        if (res?.isSuccess) {
          showToast({
            type: "success",
            title: "Successfully",
            message: "Media uploaded successfully",
          });
          getDetailsByVisitId();
        } else {
          showToast({
            type: "error",
            title: "error",
            message: res.message || "Error encountered while adding media",
          });
        }
      } else {
        const res = await postDirectData(`items/histology_gb_tissue`, payload);
        if (res?.isSuccess) {
          getDetailsByVisitId();
          showToast({
            type: "success",
            title: "Successfully",
            message: "Media uploaded successfully",
          });
        } else {
          showToast({
            type: "error",
            title: "error",
            message: res.message || "Error encountered while adding media",
          });
        }
      }
    } else {
      showToast({
        type: "error",
        message: "Undefined user",
      });
    }
    setIsMediaBtnLoading(false);
  };

  const getDetailsByVisitId = async () => {
    const res = await fetchDirectData(
      `items/histology_gb_tissue?filter[visit_id][_eq]=${visit_id}`
    );
    if (res.isSuccess) {
      if (res?.data?.length) {
        setIsFormUpdating(true);
        const data: IPayloadForHistology = res.data[0];
        setNewPayloadForHistology({
          ...data,
          growth_polyp_ulcer: data.growth_polyp_ulcer.split(",")[0],
        });
        setGrowthPolypUlcer(
          data.growth_polyp_ulcer.split(",")?.slice(1)?.join(" ") || ""
        );
        setPreviousId(data?.id);
        setMediaFiles(JSON.parse(data?.media_files) || []);
      } else {
        setIsFormUpdating(false);
      }
    }
    setUploadFileModalIsOpen(false);
  };

  const handleDeleteImageFromAws = async (fileName: string) => {
    const status = await handleDeleteAwsImage(
      fileName,
      visit_id + "",
      patient_id + "",
      "histology"
    );
    if (status) {
      setIsOpenDeleteConfirmModal(false);
      handleDeleteMediaFromDb(fileName);
    } else {
      showToast({
        type: "error",
        title: "error",
        message: "Error encountered while deleting media",
      });
    }
  };

  const handleOpenMediaInNewTab = (fileName: string) => {
    let url = handleGetPreviewUrl(
      fileName,
      visit_id + "",
      patient_id + "",
      "histology"
    );
    window.open(url, "_blank");
  };

  const handleDeleteMediaFromDb = async (fileName: string) => {
    let payload = {
      media_files: JSON.stringify(mediaFiles.filter((e) => e != fileName)),
    };
    const res = await patchDirectData(
      `items/histology_gb_tissue/${previousId}`,
      payload
    );
    if (res?.isSuccess) {
      showToast({
        type: "success",
        title: "Successfully",
        message: "Media deleted successfully",
      });
      getDetailsByVisitId();
    } else {
      showToast({
        type: "error",
        title: "error",
        message: res.message || "Error encountered while deleting media",
      });
    }
  };

  const postHistologyData = async () => {
    if (patient_id && visit_id) {
      let payload: IPayloadForHistology = {
        ...newPayloadForHistology,
        growth_polyp_ulcer:
          newPayloadForHistology?.growth_polyp_ulcer + "," + growthPolypUlcer,
        status: "published",
        user_id: patient_id,
        visit_id: +visit_id,
      };
      setIsBtnLoading(true);

      if (isFormUpdating) {
        const res = await patchDirectData(
          `items/histology_gb_tissue/${previousId}`,
          payload
        );
        if (res?.isSuccess) {
          showToast({
            type: "success",
            title: "Successfully",
            message: "Histology details updated successfully",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            title: "error",
            message:
              res.message || "Error encountered while updating Histology.",
          });
        }
      } else {
        const res = await postDirectData(`items/histology_gb_tissue`, payload);
        if (res?.isSuccess) {
          showToast({
            type: "success",
            title: "Successfully",
            message: "Histology details added successfully",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            title: "error",
            message:
              res.message || "Error encountered while updating stone analysis.",
          });
        }
      }

      setIsBtnLoading(false);
    } else {
      showToast({
        type: "error",
        message: "Undefined user",
      });
    }
  };

  return {
    isMediaBtnLoading,
    files,
    setFiles,
    newPayloadForHistology,
    setNewPayloadForHistology,
    mediaFiles,
    uploadFileModalIsOpen,
    getDetailsByVisitId,
    setUploadFileModalIsOpen,
    handleOpenMediaInNewTab,
    handleDeleteImageFromAws,
    handleUploadFiles,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal,
    setGrowthPolypUlcer,
    postHistologyData,
    growthPolypUlcer,
  };
}
