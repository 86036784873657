import { Image, PasswordInput} from "@mantine/core";
import "./Login.scss";
import { EButton, ETextInput } from "../../../../libs/ui";
import useAuthentication from "../../Hooks/useAuthentication";
import { AiOutlineReload } from "react-icons/ai";

export default function Login() {
  const {
    loginParams,
    isLoggingIn,
    handleLoginPayloadChange,
    handleUserLogin,
  } = useAuthentication();

  return (
    <div className="page-login">
       
      <div className="inner">

     

        <div className="left">
          <div className="flex justify-around w-full items-center">
            <Image
              maw={160}
              mx="auto"
              radius="md"
              src={require("../../../../assets/joininghands.jpg")}
              alt="galstone_image"
            />
            <div className="flex justify-around w-full items-center absolute -right-0">
            <Image
              maw={160}
              mx="auto"
              radius="md"
              src={require("../../../../assets/ribbon.png")}
              alt="galstone_image"
            />
          </div>
          </div>
          
          <h4 className="font-medium mt-8 opacity-60 w-full flex items-center justify-center">
          INDIA STONE STUDY
          </h4>
          {/* <h3 className=" w-full flex items-center justify-center">
            INDIA STONE STUDY
          </h3> */}
          <div className="text-center w-full font-medium text-2xl">
          To prevent Gall Bladder Cancer
          </div>
        </div>

        <div className="right">
          <h3>Sign in to your account</h3>
          <p className="text-sm opacity-50">
            Please enter your user credentials below to login
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUserLogin();
            }}
            className="signin-form mt-3"
          >
            <ETextInput
              required
              size="md"
              placeholder={"Enter Username"}
              value={loginParams.email}
              onChange={(e) => {
                const { value } = e.target;
                handleLoginPayloadChange("email", value);
              }}
            />

            <PasswordInput
              required
              size="md"
              placeholder={"Enter Password (########)"}
              value={loginParams.password}
              onChange={(e) => {
                const { value } = e.target;
                handleLoginPayloadChange("password", value);
              }}
            />
            <EButton
              className="btn-blue"
              rightIcon={<AiOutlineReload />}
              loading={isLoggingIn}
              onClick={() => handleUserLogin()}
            >
              Sign in
            </EButton>
             <button
              type="submit"
             >
             </button>
          </form>
        </div>
      </div>
      
    </div>
  );
}
