import { FileWithPath } from "@mantine/dropzone";
import aws from "aws-sdk";
import axios from "axios";
import { Buffer } from "buffer";

export default function useUploadFiles() {
  const s3 = new aws.S3({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    signatureVersion: "v4",
  });

  const handleGetKey = (
    type: string,
    formName: string,
    fileName: string,
    patientId: string,
    visitId: string
  ) => {
    let definedKey = "";
    switch (type) {
      case "video":
        definedKey = `videos/${formName}/${patientId}/${visitId}/${fileName}`;
        break;
      case "pdf":
        definedKey = `pdf/${formName}/${patientId}/${visitId}/${fileName}`;
        break;
      case "image":
        definedKey = `images/${formName}/${patientId}/${visitId}/${fileName}`;
        break;
    }

    return definedKey;
  };

  const handleGetPresignedUrl = async (
    visitId: string,
    patientId: string,
    type: string,
    formName: string,
    fileName: string
  ) => {
    let definedKey = handleGetKey(
      fileName.includes(".pdf")
        ? "pdf"
        : fileName.includes(".mp4")
        ? "video"
        : "image",
      formName,
      fileName,
      patientId,
      visitId
    );
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: definedKey,
      Expires: 1000,
    };
    const presignedUrl = await s3.getSignedUrlPromise("putObject", params);
    return presignedUrl;
  };
  const handleUploadMedia = async (
    visitId: string,
    patientId: string,
    formName: string,
    files: FileWithPath[]
  ) => {
    const successfulUploads: string[] = [];

    console.log("handle upload Media -----71")
    try {
      console.log("handle upload Media -----73")
      const uploadPromises = files.map(async (file) => {
        const fileData = Buffer.from(await file.arrayBuffer());
        const presignedUrl = await handleGetPresignedUrl(
          visitId,
          patientId,
          file.name.includes(".pdf")
            ? "pdf"
            : file.name.includes(".mp4")
            ? "video"
            : "image",
            formName,
          file.name
        );
        
        await axios.put(presignedUrl, fileData, {
          headers: { "Content-Type": file.type },
          maxBodyLength: Infinity,
          maxContentLength: Infinity,
          responseType: "json",
        });
        console.log("handle upload Media -----94")
        successfulUploads.push(file.name);
      });
      
      await Promise.all(uploadPromises);
      
      console.log("handle upload Media -----100",successfulUploads)
      return successfulUploads;
      // showToast({
        //   type: "success",
        //   title: "Success",
        //   message: "Videos uploaded successfully",
        // });
      } catch (error) {
      console.log("handle upload Media -error----108",error)
      return [];
      // showToast({
      //   type: "error",
      //   title: "Error",
      //   message: "Error uploading media files",
      // });
    }
  };

  const handleDeleteAwsImage = async (
    fileName: string,
    visitId: string,
    patientId: string,
    formName: string
  ) => {
    const imageKey = handleGetKey(
      fileName.includes(".pdf")
        ? "pdf"
        : fileName.includes(".mp4")
        ? "video"
        : "image",
      formName,
      fileName,
      patientId,
      visitId
    );

    try {
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME as string,
        Key: imageKey,
      };
      await s3.deleteObject(params).promise();

      return true;
    } catch (error) {
      console.error(`Error deleting image with key ${imageKey}:`, error);
      return false;
    }
  };

  const handleGetPreviewUrl = (
    fileName: string,
    visitId: string,
    patientId: string,
    formName: string
  ) => {
    let baseURl = "https://pgi-videos.s3.amazonaws.com/";
    let definedKey = handleGetKey(
      fileName.includes(".pdf")
        ? "pdf"
        : fileName.includes(".mp4")
        ? "video"
        : "image",
      formName,
      fileName,
      patientId,
      visitId
    );
    return baseURl + definedKey;
  };

  return {
    handleUploadMedia,
    handleGetPreviewUrl,
    handleDeleteAwsImage,
    handleGetPresignedUrl,
  };
}
