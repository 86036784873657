import { useContext, useEffect, useReducer, useState } from "react";
import { IPayloadForStoneAnalysis } from "../../../../../types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { showToast } from "../../../../../utils/utility";
import { PatientContext } from "../../../PatientsContext";
import { useDirectusApi } from "../../../../../libs/data-access";
import { FileWithPath } from "@mantine/dropzone";
import useUploadFiles from "../../../../Hooks/useUploadFiles";

type ISectionBody = {
  label: string;
  value: string | boolean | number;
  name?: string;
  placeholder?: string;
  key: string;
  type: "number" | "text" | "boolean" | "select";
  data?: any[];
};
type ISection = {
  sectionTitle: string;
  sectionKey: keyof IPayloadForStoneAnalysis;
  body: ISectionBody[];
  isMultiple?: boolean;
};

export default function useStoneAnalysis() {
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const visit_id = searchParams.get("visit_id");

  const navigate = useNavigate();
  const { postDirectData, patchDirectData, fetchDirectData } = useDirectusApi();
  const {
    activeTab,
    activeSubTab,
    setActiveSubTab,
    isBtnNextClick,
    handleBackTab,
    setIsBtnLoading,
    setIsBtnDisable,
    handleNextTab,
    isFormUpdating,
    setIsFormUpdating,
  } = useContext(PatientContext);

  const { handleUploadMedia, handleGetPreviewUrl, handleDeleteAwsImage } =
    useUploadFiles();

  const [video, setVideo] = useState<any>();
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
    useState<boolean>(false);

  const [payloadForStoneAnalysis, setPayloadForStoneAnalysis] =
    useState<IPayloadForStoneAnalysis>({} as IPayloadForStoneAnalysis);
  const [isMediaBtnLoading, setIsMediaBtnLoading] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<string[]>([]);
  const [uploadFileModalIsOpen, setUploadFileModalIsOpen] = useState(false);
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [previousId, setPreviousId] = useState<number | undefined>(undefined);

  const handleUpdateData = (state: ISection[], reducerPayload: any) => {
    let stateCopy = [...state];
    let data = reducerPayload.value;
    Object.keys(data).map((variable, index) => {
      let sectionIndex = stateCopy.findIndex(
        (each) => each.sectionKey === variable
      );
      if (data[variable] !== null && sectionIndex !== -1) {
        stateCopy[sectionIndex].body[0].value = data[variable];
        // switch (variable) {
        //   case "number_of_stones":
        //     stateCopy[sectionIndex].body[0].value = data[variable];
        //     break;
        //   case "size_of_stones":
        //     if (data[variable]) {
        //       stateCopy[sectionIndex].body[0].value = true;
        //     } else {
        //       stateCopy[sectionIndex].body[1].value = true;
        //     }
        //     break;
        //   case "color_of_stones":
        //     stateCopy[sectionIndex].body[
        //       stateCopy[sectionIndex].body.findIndex(
        //         (each) => each.key === data[variable]
        //       )
        //     ].value = true;
        //     break;
        //   case "shape_of_stones":
        //     if (data[variable]) {
        //       stateCopy[sectionIndex].body[0].value = true;
        //     } else {
        //       stateCopy[sectionIndex].body[1].value = true;
        //     }
        //     break;
        //   case "weight_of_stone":
        //     stateCopy[sectionIndex].body[
        //       stateCopy[sectionIndex].body.findIndex(
        //         (each) => each.key === data[variable]
        //       )
        //     ].value = true;
        //     break;
        //   case "stone_cs":
        //     if (data[variable]) {
        //       stateCopy[sectionIndex].body[0].value = true;
        //     } else {
        //       stateCopy[sectionIndex].body[1].value = true;
        //     }
        //     break;
        //   case "ftir":
        //     break;
        //   case "total_volume":
        //     break;
        //   default:
        //     break;
        // }
      } else {
      }
    });

    return stateCopy;
  };

  const stoneAnalysisReducer = (
    state: ISection[],
    action: { type: string; reducerPayload: any }
  ) => {
    switch (action.type) {
      case "UPDATE_SECTION":
        let val;
        const newState = [...state];
        if (newState[action.reducerPayload.sectionIndex].isMultiple) {
          newState[action.reducerPayload.sectionIndex].body[
            action.reducerPayload.subIndex
          ].value = action.reducerPayload.value;
        } else {
          newState[action.reducerPayload.sectionIndex].body.map(
            (eachItem, innerIndex) => {
              newState[action.reducerPayload.sectionIndex].body[
                innerIndex
              ].value = typeof eachItem.value == "boolean" ? false : "";
            }
          );
          newState[action.reducerPayload.sectionIndex].body[
            action.reducerPayload.subIndex
          ].value = action.reducerPayload.value;
        }

        // Payload handle condition

        // single
        if (!newState[action.reducerPayload.sectionIndex].isMultiple) {
          // input
          if (
            typeof newState[action.reducerPayload.sectionIndex].body[
              action.reducerPayload.subIndex
            ].value == "string" &&
            newState[action.reducerPayload.sectionIndex].body[
              action.reducerPayload.subIndex
            ]?.label
          ) {
            let key =
              newState[action.reducerPayload.sectionIndex]?.body[
                action.reducerPayload.subIndex
              ].key;
            setPayloadForStoneAnalysis({
              ...payloadForStoneAnalysis,
              [key]: action.reducerPayload.value,
            });
          }
          // single select
          else {
            if (
              newState[action.reducerPayload.sectionIndex].body[
                action.reducerPayload.subIndex
              ]?.label
            ) {
              setPayloadForStoneAnalysis({
                ...payloadForStoneAnalysis,
                [newState[action.reducerPayload.sectionIndex].sectionKey]:
                  newState[action.reducerPayload.sectionIndex].body?.filter(
                    (item, index) => item?.value
                  )[0]?.key || "",
              });
            } else {
              if (
                newState[action.reducerPayload.sectionIndex].body[
                  action.reducerPayload.subIndex
                ]?.key
              ) {
                setPayloadForStoneAnalysis({
                  ...payloadForStoneAnalysis,
                  [newState[action.reducerPayload.sectionIndex].sectionKey]:
                    newState[action.reducerPayload.sectionIndex].body?.filter(
                      (item, index) => item?.value
                    )[0]?.key +
                      "-" +
                      newState[action.reducerPayload.sectionIndex].body?.filter(
                        (item, index) => item?.value
                      )[0]?.value || "",
                });
              } else {
                setPayloadForStoneAnalysis({
                  ...payloadForStoneAnalysis,
                  [newState[action.reducerPayload.sectionIndex].sectionKey]:
                    newState[action.reducerPayload.sectionIndex].body?.filter(
                      (item, index) => item?.value
                    )[0]?.value || "",
                });
              }
            }
          }
        }
        // multiple
        else if (newState[action.reducerPayload.sectionIndex].isMultiple) {
          setPayloadForStoneAnalysis({
            ...payloadForStoneAnalysis,
            [newState[action.reducerPayload.sectionIndex].body[
              action.reducerPayload.subIndex
            ].key]: newState[action.reducerPayload.sectionIndex].body
              ?.filter((item, index) => {
                if (item?.value) {
                  return item?.key;
                }
              })
              .map((item) => item?.key),
          });
        }
        return newState;

      case "FIRST_TIME_UPDATE":
        let updaterData = handleUpdateData(state, action.reducerPayload);
        return updaterData;
      default:
        return state;
    }
  };

  const [stoneAnalysis, dispatchStoneAnalysis] = useReducer(
    stoneAnalysisReducer,
    [
      {
        sectionTitle: "Number of stones",
        sectionKey: "number_of_stones",
        body: [
          {
            label: "",
            placeholder: "Number of stones",
            key: "",
            value: "",
            type: "number",
          },
        ],
      },
      {
        sectionTitle: "Size of stones (mm / cm / inch)",
        sectionKey: "size_of_stones",
        body: [
          {
            label: "",
            placeholder: "Size of stones",
            key: "",
            value: "",
            type: "number",
          },
        ],
      },
      {
        sectionTitle: "Color of stones",
        sectionKey: "color_of_stones",
        body: [
          {
            label: "",
            value: "",
            key: "",
            placeholder: "Color of stones",
            type: "select",
            data: ["White", "Yellow", "Light Brown", "Dark Brown", "Black"],
          },
        ],
      },
      {
        sectionTitle: "Shape of stones",
        sectionKey: "shape_of_stones",
        body: [
          {
            label: "",
            key: "",
            placeholder: "Shape of stones",
            value: "",
            type: "text",
          },
        ],
      },
      {
        sectionTitle: "Weight of stone",
        sectionKey: "weight_of_stone",
        body: [
          {
            label: "",
            placeholder: "Weight of stone",
            key: "",
            value: "",
            type: "number",
          },
        ],
      },
      {
        sectionTitle: "Total Volume",
        sectionKey: "total_volume",
        body: [
          {
            label: "",
            placeholder: "Total Volume",
            key: "",
            value: "",
            type: "number",
          },
        ],
      },
      {
        sectionTitle: "FTIR",
        sectionKey: "ftir",
        body: [
          {
            label: "",
            placeholder: "FTIR",
            key: "",
            value: "",
            type: "text",
          },
        ],
      },
      {
        sectionTitle: "Stone C/S",
        sectionKey: "stone_cs",
        body: [
          {
            label: "",
            placeholder: "Stone C/S",
            key: "",
            value: "",
            type: "text",
          },
        ],
      },
      {
        sectionTitle: "Cholesterol composition",
        sectionKey: "cholesterol_composition",
        body: [
          {
            label: "",
            key: "",
            placeholder: "Cholesterol composition",
            value: "",
            type: "text",
          },
        ],
      },
    ]
  );

  useEffect(() => {
    setIsBtnDisable(false);
    if (isBtnNextClick && activeTab == "stone_analysis") {
      handleSaveStoneAnalysis();
    }
  }, [isBtnNextClick]);

  useEffect(() => {
    if (activeTab == "stone_analysis") {
      getDetailsByVisitId();
    }
  }, [activeTab]);

  const handleChangeForm = (
    section: string,
    key: string,
    value: string | boolean | number,
    sectionIndex: number,
    subIndex: number,
    name: string
  ) => {
    dispatchStoneAnalysis({
      type: "UPDATE_SECTION",
      reducerPayload: { section, key, value, sectionIndex, subIndex, name },
    });
  };

  const handleSaveStoneAnalysis = async () => {
    if (patient_id && visit_id) {
      let payload = {
        ...payloadForStoneAnalysis,
        status: "published",
        user_id: patient_id,
        visit_id: visit_id,
        media_files: JSON.stringify([...mediaFiles]),
      };
      setIsBtnLoading(true);

      if (isFormUpdating) {
        const res = await patchDirectData(
          `items/stone_analysis/${previousId}`,
          payload
        );
        if (res?.isSuccess) {
          showToast({
            type: "success",
            title: "Successfully",
            message: "Stone analysis added successfully",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            title: "error",
            message:
              res.message || "Error encountered while updating stone analysis.",
          });
        }
      } else {
        const res = await postDirectData(`items/stone_analysis`, payload);
        if (res?.isSuccess) {
          showToast({
            type: "success",
            title: "Successfully",
            message: "Stone analysis added successfully",
          });
          handleNextTab();
        } else {
          showToast({
            type: "error",
            title: "error",
            message:
              res.message || "Error encountered while updating stone analysis.",
          });
        }
      }

      setIsBtnLoading(false);
    } else {
      showToast({
        type: "error",
        message: "User not found",
      });
    }
  };

  const handleUploadFiles = async (files: FileWithPath[]) => {
    setIsMediaBtnLoading(true);
    const uploadedFiles = await handleUploadMedia(
      visit_id + "",
      patient_id + "",
      "stone_analysis",
      files
    );
    if (uploadedFiles?.length > 0) {
      handleUploadFileNamesInDb(uploadedFiles);
    } else {
      showToast({
        type: "error",
        title: "Error",
        message: "Error uploading media files",
      });
    }
  };

  const handleUploadFileNamesInDb = async (uploadedFiles: string[]) => {
    if (patient_id && visit_id) {
      const uniqueSet = new Set([...uploadedFiles, ...mediaFiles]);
      let payload: IPayloadForStoneAnalysis = {
        ...payloadForStoneAnalysis,
        status: "published",
        user_id: patient_id,
        visit_id: +visit_id,
        media_files: JSON.stringify(Array.from(uniqueSet)),
      };
      if (isFormUpdating) {
        // payload.id = previousId;

        const res = await patchDirectData(
          `items/stone_analysis/${previousId}`,
          payload
        );
        if (res?.isSuccess) {
          showToast({
            type: "success",
            title: "Successfully",
            message: "Media uploaded successfully",
          });
          getDetailsByVisitId();
        } else {
          showToast({
            type: "error",
            title: "error",
            message: res.message || "Error encountered while adding media",
          });
        }
      } else {
        const res = await postDirectData(`items/stone_analysis`, payload);
        if (res?.isSuccess) {
          getDetailsByVisitId();
          showToast({
            type: "success",
            title: "Successfully",
            message: "Media uploaded successfully",
          });
        } else {
          showToast({
            type: "error",
            title: "error",
            message: res.message || "Error encountered while adding media",
          });
        }
      }
    } else {
      showToast({
        type: "error",
        message: "Undefined user",
      });
    }
    setIsMediaBtnLoading(false);
  };

  const getDetailsByVisitId = async () => {
    const res = await fetchDirectData(
      `items/stone_analysis?filter[visit_id][_eq]=${visit_id}`
    );
    if (res.isSuccess) {
      if (res?.data?.length) {
        setIsFormUpdating(true);
        const data: IPayloadForStoneAnalysis = res.data[0];
        setPayloadForStoneAnalysis(data);
        setPreviousId(data?.id);
        setMediaFiles(JSON.parse(data?.media_files) || []);
        dispatchStoneAnalysis({
          type: "FIRST_TIME_UPDATE",
          reducerPayload: {
            value: data,
          },
        });
      } else {
        setIsFormUpdating(false);
      }
    } else {
    }
    setUploadFileModalIsOpen(false);
  };

  const handleDeleteImageFromAws = async (fileName: string) => {
    const status = await handleDeleteAwsImage(
      fileName,
      visit_id + "",
      patient_id + "",
      "histology"
    );
    if (status) {
      setIsOpenDeleteConfirmModal(false);
      handleDeleteMediaFromDb(fileName);
    } else {
      showToast({
        type: "error",
        title: "error",
        message: "Error encountered while deleting media",
      });
    }
  };

  const handleOpenMediaInNewTab = (fileName: string) => {
    let url = handleGetPreviewUrl(
      fileName,
      visit_id + "",
      patient_id + "",
      "histology"
    );
    window.open(url, "_blank");
  };

  const handleDeleteMediaFromDb = async (fileName: string) => {
    let payload = {
      media_files: JSON.stringify(mediaFiles.filter((e) => e != fileName)),
    };
    const res = await patchDirectData(
      `items/stone_analysis/${previousId}`,
      payload
    );
    if (res?.isSuccess) {
      showToast({
        type: "success",
        title: "Successfully",
        message: "Media deleted successfully",
      });
      getDetailsByVisitId();
    } else {
      showToast({
        type: "error",
        title: "error",
        message: res.message || "Error encountered while deleting media",
      });
    }
  };

  return {
    mediaFiles,
    stoneAnalysis,
    isMediaBtnLoading,
    uploadFileModalIsOpen,
    files,
    setFiles,
    handleOpenMediaInNewTab,
    handleDeleteImageFromAws,
    setUploadFileModalIsOpen,
    setIsMediaBtnLoading,
    handleUploadFiles,
    handleChangeForm,
    payloadForStoneAnalysis,
    setPayloadForStoneAnalysis,
    isOpenDeleteConfirmModal,
    setIsOpenDeleteConfirmModal,
  };
}
