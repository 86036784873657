import { createContext, Dispatch, SetStateAction } from "react";

import { IColorScheme, IDirectusUser, IUserDetails } from "./types";
import { initialUser } from "./initialStates";
import {IRoute } from "./Routes/routes";

export interface IAuthContext {
    user: IDirectusUser;
    handleUser: (value: IDirectusUser) => void;
    handleUserDetails: (key: keyof IUserDetails, value: any | undefined) => void;
    isSidebarCollapsed: boolean | undefined,
    setIsSidebarCollapsed: Dispatch<SetStateAction<boolean | undefined>>;
    isLoggedIn: boolean | undefined;
    setIsLoggedIn: Dispatch<SetStateAction<boolean | undefined>>;
    token: string | undefined;
    setToken: Dispatch<SetStateAction<undefined | string>>;
    role: string[] | undefined;
    setRole: Dispatch<SetStateAction<string[] | undefined>>;
    handleLogout: () => void;
    currentRotes: IRoute[] | undefined;
    setCurrentRoutes: Dispatch<SetStateAction<IRoute[]>>;
    colorScheme: IColorScheme;
    setColorScheme: Dispatch<SetStateAction<IColorScheme>>;
    activeTab: string | undefined;
    setActiveTab: Dispatch<SetStateAction<string>>;
};

export const AppContext = createContext<IAuthContext>({
    user: initialUser,
    handleUser: () => { },
    handleUserDetails: () => { },
    isSidebarCollapsed: true,
    setIsSidebarCollapsed: () => { },
    isLoggedIn: true,
    setIsLoggedIn: () => { },
    token: undefined,
    setToken: () => { },
    role: undefined,
    setRole: () => { },
    handleLogout: () => { },
    currentRotes : undefined,
    setCurrentRoutes : ()=> { },
    colorScheme: {} as IColorScheme,
    setColorScheme: () => { },
    activeTab: undefined,
    setActiveTab: () => { }
});