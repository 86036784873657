import { MultiSelect, MultiSelectProps } from '@mantine/core'
import React from 'react'

export function EMultiSelect(props: MultiSelectProps) {
    return (
        <MultiSelect
            label={props.label}
            size={props.size}
            className={props.className}
            type={props.type}
            data={props.data}
            required={props.required}
            description={props.description}
            value={props.value}
            placeholder={props.placeholder}
            error={props.error}
            onChange={props.onChange}
        />
    )
}
