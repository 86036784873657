import PageLayout from "../../../PageLayout";
import { EPageTitle, EButton, ESectionDivider, ETextInput, ESelect, ETextArea } from "../../../libs/ui";
import { Grid} from "@mantine/core";
import useCenter from "./Hooks/useCenter";
import { statesMaster } from "../../../libs/data-access/utility";
import EPhoneInput from "../../../libs/ui/src/NumberInput/EPhoneInput";

export default function AddNewCenter() {
  const { centerDetails, handleAddNewCenter, handleChangeCenterDetails } =
    useCenter();

  return (
    <PageLayout
      header={<EPageTitle title="Create New Center" />}
      footer={
        <div className="w-full flex justify-end  p-2">
          <EButton
            className="btn-blue"
            size="md"
            onClick={() => handleAddNewCenter()}
          >
            Submit
          </EButton>
        </div>
      }
    >
      <ESectionDivider title="Center Details" />
      <Grid className="mt-10">
        <Grid.Col span={4}>
          <ETextInput
            size="md"
            label="Center Name"
            required
            placeholder="Center name"
            value={centerDetails?.center_name}
            onChange={(e) =>
              handleChangeCenterDetails("center_name", e?.target?.value)
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <ETextInput
            size="md"
            label="Center Last Name"
            required
            placeholder="Last name"
            value={centerDetails?.last_name}
            onChange={(e) =>
              handleChangeCenterDetails("last_name", e?.target?.value)
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <ETextInput
            required
            size="md"
            label="Hospital No."
            placeholder="Hospital No."
            value={centerDetails?.hospital_no}
            onChange={(e) =>
              handleChangeCenterDetails("hospital_no", e?.target?.value)
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <ETextInput
            size="md"
            label="Email"
            required
            placeholder="Enter e-mail"
            value={centerDetails?.email}
            onChange={(e) =>
              handleChangeCenterDetails("email", e?.target?.value)
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <ETextInput
            size="md"
            label="Password"
            required
            placeholder="Password"
            value={centerDetails?.password}
            onChange={(e) =>
              handleChangeCenterDetails("password", e?.target?.value)
            }
            />
        </Grid.Col>
        <Grid.Col span={4}>
          <ETextInput
            
            size="md"
            label="Designation"
            placeholder="Enter designation"
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <ETextInput
            
            size="md"
            label="Affiliation"
            placeholder="Affiliation"
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <ESelect
            
            size="md"
            label="Organization / Institution"
            data={["University teaching hospital", "Corporate hospital", "Personal practice", "Others"]}
            placeholder="Select"
          />
        </Grid.Col>
        {/* <Grid.Col span={4}>
          <ETextInput
            
            size="md"
            label="Address"
            placeholder="Address"
            value={centerDetails?.address}
            onChange={(e) =>
              handleChangeCenterDetails("address", e?.target?.value)
            }
          />
        </Grid.Col> */}
        <Grid.Col span={4}>
          <ETextInput
           required
            size="md"
            label="City"
            placeholder="Enter city"
            value={centerDetails?.address}
            onChange={(e) =>
              handleChangeCenterDetails("address", e?.target?.value)
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <ESelect
            required
            size="md"
            label="State"
            placeholder="Select state"
            value={centerDetails?.state}
            data={statesMaster}
            onChange={(e) =>
              handleChangeCenterDetails("state", e ?? "")
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <EPhoneInput
            required
            size="md"
            label="Contact No."
            placeholder="Contact No."
            maxLength={10}
            value={centerDetails?.contact_no}
            onChange={(e) =>
              handleChangeCenterDetails("contact_no", String(e))
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <EPhoneInput
            required
            size="md"
            label="Whatsapp No."
            placeholder="Whatsapp No."
            maxLength={10}
            value={centerDetails?.whatsapp_no}
            onChange={(e) =>
              handleChangeCenterDetails("whatsapp_no", String(e))
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <EPhoneInput
            required
            size="md"
            label="Landline No."
            placeholder="Landline No."
            maxLength={10}
            value={centerDetails?.landline_no}
            onChange={(e) =>
              handleChangeCenterDetails("landline_no", String(e))
            }
          />
        </Grid.Col>
      </Grid>
      {/* <ESelect
        size="md"
        className="w-1/2 mt-3"
        data={["Yes", "No"]}
        label="Let us know if you can contribute the clinical data of patients with GS/ GBC?"
        placeholder="Select "

      />
      <ETextArea        
        className="w-1/2 mt-3 pb-6"
        size="md"
        label="Comments"
        placeholder="Enter your answer"

      /> */}
    </PageLayout>
  );
}
