import React from "react";
import SecondaryPageLayout from "../../../../libs/ui/src/SecondaryPageLayout/SecondaryPageLayout";
import { ECheckBox, ESectionDivider, ETextInput } from "../../../../libs/ui";
import useGBCClinicalPresentation from "./hooks/useGBCClinicalPresentation";

export default function GBCClinicalPresentation() {
  const { payloadForGBCClinicalPresentation, handleChangePayload } =
    useGBCClinicalPresentation();

  return (
    <SecondaryPageLayout>
      <div>
        <h3 className="mb-3">GBC clinical presentation</h3>
        <ESectionDivider title="History / Examination" />
        <div>
          <div className="grid grid-cols-3 gap-4">
            <div className="">Pain</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.pain_x_value == "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("pain_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.pain_x_value == "No"
                }
                onChange={(e) => {
                  handleChangePayload("pain_x_value",e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.pain_x_value == "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.pain_desc}
                onChange={(e) => {
                  handleChangePayload("pain_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Jaundice</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.jaundice_x_value == "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("jaundice_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.jaundice_x_value == "No"
                }
                onChange={(e) => {
                  handleChangePayload("jaundice_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.jaundice_x_value == "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.jaundice_desc}
                onChange={(e) => {
                  handleChangePayload("jaundice_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Pruritis</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.pruritis_x_value == "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("pruritis_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.pruritis_x_value == "No"
                }
                onChange={(e) => {
                  handleChangePayload("pruritis_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.pruritis_x_value == "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.pruritis_desc}
                onChange={(e) => {
                  handleChangePayload("pruritis_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Fever</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.fever_x_value == "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("fever_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.fever_x_value == "No"
                }
                onChange={(e) => {
                  handleChangePayload("fever_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.fever_x_value == "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.fever_desc}
                onChange={(e) => {
                  handleChangePayload("fever_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Cholangitis</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.cholangitis_x_value ==
                  "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("cholangitis_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.cholangitis_x_value == "No"
                }
                onChange={(e) => {
                  handleChangePayload("cholangitis_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.cholangitis_x_value == "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.cholangitis_desc}
                onChange={(e) => {
                  handleChangePayload("cholangitis_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Abd. Distension</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.abd_distension_x_value ==
                  "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("abd_distension_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.abd_distension_x_value ==
                  "No"
                }
                onChange={(e) => {
                  handleChangePayload("abd_distension_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.abd_distension_x_value ==
            "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.abd_distension_desc}
                onChange={(e) => {
                  handleChangePayload("abd_distension_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}
            <div className="">Features of G.O.O.</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.features_of_goo_x_value ==
                  "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("features_of_goo_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.features_of_goo_x_value ==
                  "No"
                }
                onChange={(e) => {
                  handleChangePayload("features_of_goo_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.features_of_goo_x_value ==
            "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.features_of_goo_desc}
                onChange={(e) => {
                  handleChangePayload("features_of_goo_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Anorexia</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.anorexia_x_value == "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("anorexia_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.anorexia_x_value == "No"
                }
                onChange={(e) => {
                  handleChangePayload("anorexia_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.anorexia_x_value == "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.anorexia_desc}
                onChange={(e) => {
                  handleChangePayload("anorexia_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}
            <div className="">H/o Lump</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.ho_lump_x_value == "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("ho_lump_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.ho_lump_x_value == "No"
                }
                onChange={(e) => {
                  handleChangePayload("ho_lump_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.ho_lump_x_value == "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.ho_lump_desc}
                onChange={(e) => {
                  handleChangePayload("ho_lump_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Wt. Loss</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.wt_loss_x_value == "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("wt_loss_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.wt_loss_x_value == "No"
                }
                onChange={(e) => {
                  handleChangePayload("wt_loss_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.wt_loss_x_value == "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.wt_loss_desc}
                onChange={(e) => {
                  handleChangePayload("wt_loss_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>

        <div>
          <ESectionDivider title="History of Gall stones" />
         <div className="grid grid-cols-3 gap-4">
         <div className="">Past history of GSD</div>
          <div className="flex flex-row gap-5">
            <ECheckBox
              label="Yes"
              checked={
                payloadForGBCClinicalPresentation?.past_history_of_gsd_x_value ==
                "Yes"
              }
              onChange={(e) => {
                handleChangePayload("past_history_of_gsd_x_value", e.target.checked ? "Yes" : "");
              }}
            />
            <ECheckBox
              label="No"
              checked={
                payloadForGBCClinicalPresentation?.past_history_of_gsd_x_value ==
                "No"
              }
              onChange={(e) => {
                handleChangePayload("past_history_of_gsd_x_value", e.target.checked ? "No" : "");
              }}
            />
          </div>
          {payloadForGBCClinicalPresentation?.past_history_of_gsd_x_value ==
          "Yes" ? (
            <ETextInput
              value={
                payloadForGBCClinicalPresentation?.past_history_of_gsd_desc
              }
              onChange={(e) => {
                handleChangePayload("past_history_of_gsd_desc", e.target.value);
              }}
              placeholder="Duration/ Description"
            />
          ) : (
            <div></div>
          )}

          <div className="">Duration of GSD</div>
          <div className="flex flex-row gap-5">
            <ECheckBox
              label="Yes"
              checked={
                payloadForGBCClinicalPresentation?.duration_of_gsd_x_value ==
                "Yes"
              }
              onChange={(e) => {
                handleChangePayload("duration_of_gsd_x_value", e.target.checked ? "Yes" : "");
              }}
            />
            <ECheckBox
              label="No"
              checked={
                payloadForGBCClinicalPresentation?.duration_of_gsd_x_value ==
                "No"
              }
              onChange={(e) => {
                handleChangePayload("duration_of_gsd_x_value", e.target.checked ? "No" : "");
              }}
            />
          </div>
          {payloadForGBCClinicalPresentation?.duration_of_gsd_x_value ==
          "Yes" ? (
            <ETextInput
              value={payloadForGBCClinicalPresentation?.duration_of_gsd_desc}
              onChange={(e) => {
                handleChangePayload("duration_of_gsd_desc", e.target.value);
              }}
              placeholder="Duration/ Description"
            />
          ) : (
            <div></div>
          )}

          <div className="">Past history of biliary colic</div>
          <div className="flex flex-row gap-5">
            <ECheckBox
              label="Yes"
              checked={
                payloadForGBCClinicalPresentation?.past_history_of_biliary_colic_x_value ==
                "Yes"
              }
              onChange={(e) => {
                handleChangePayload(
                  "past_history_of_biliary_colic_x_value",
                  e.target.checked ? "Yes" : ""
                );
              }}
            />
            <ECheckBox
              label="No"
              checked={
                payloadForGBCClinicalPresentation?.past_history_of_biliary_colic_x_value ==
                "No"
              }
              onChange={(e) => {
                handleChangePayload(
                  "past_history_of_biliary_colic_x_value",
                  e.target.checked ? "No" : ""
                );
              }}
            />
          </div>
          {payloadForGBCClinicalPresentation?.past_history_of_biliary_colic_x_value ==
          "Yes" ? (
            <ETextInput
              value={
                payloadForGBCClinicalPresentation?.past_history_of_biliary_colic_desc
              }
              onChange={(e) => {
                handleChangePayload(
                  "past_history_of_biliary_colic_desc",
                  e.target.value
                );
              }}
              placeholder="Duration/ Description"
            />
          ) : (
            <div></div>
          )}
         </div>
        </div>

        <div>
          <ESectionDivider title="Examination" />
          <div className="grid grid-cols-3 gap-4">
            <div className="">GB Lump (size)</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.gb_lump_size_x_value ==
                  "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("gb_lump_size_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.gb_lump_size_x_value ==
                  "No"
                }
                onChange={(e) => {
                  handleChangePayload("gb_lump_size_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.gb_lump_size_x_value ==
            "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.gb_lump_size_desc}
                onChange={(e) => {
                  handleChangePayload("gb_lump_size_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Hepatomegaly</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.hepatomegaly_x_value ==
                  "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("hepatomegaly_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.hepatomegaly_x_value ==
                  "No"
                }
                onChange={(e) => {
                  handleChangePayload("hepatomegaly_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.hepatomegaly_x_value ==
            "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.hepatomegaly_desc}
                onChange={(e) => {
                  handleChangePayload("hepatomegaly_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Post cholecystectomy scar</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.post_cholecystectomy_scar_x_value ==
                  "Yes"
                }
                onChange={(e) => {
                  handleChangePayload(
                    "post_cholecystectomy_scar_x_value",
                    e.target.checked ? "Yes" : ""
                  );
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.post_cholecystectomy_scar_x_value ==
                  "No"
                }
                onChange={(e) => {
                  handleChangePayload(
                    "post_cholecystectomy_scar_x_value",
                    e.target.checked ? "No" : ""
                  );
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.post_cholecystectomy_scar_x_value ==
            "Yes" ? (
              <ETextInput
                value={
                  payloadForGBCClinicalPresentation?.post_cholecystectomy_scar_desc
                }
                onChange={(e) => {
                  handleChangePayload(
                    "post_cholecystectomy_scar_desc",
                    e.target.value
                  );
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Supraclavicular LN (size, Rt /Lt)</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.supraclavicular_ln_x_value ==
                  "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("supraclavicular_ln_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.supraclavicular_ln_x_value ==
                  "No"
                }
                onChange={(e) => {
                  handleChangePayload("supraclavicular_ln_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.supraclavicular_ln_x_value ==
            "Yes" ? (
              <ETextInput
                value={
                  payloadForGBCClinicalPresentation?.supraclavicular_ln_desc
                }
                onChange={(e) => {
                  handleChangePayload(
                    "supraclavicular_ln_desc",
                    e.target.value
                  );
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Ascites</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.ascites_x_value == "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("ascites_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.ascites_x_value == "No"
                }
                onChange={(e) => {
                  handleChangePayload("ascites_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.ascites_x_value == "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.ascites_desc}
                onChange={(e) => {
                  handleChangePayload("ascites_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}

            <div className="">Tenderness</div>
            <div className="flex flex-row gap-5">
              <ECheckBox
                label="Yes"
                checked={
                  payloadForGBCClinicalPresentation?.tenderness_x_value == "Yes"
                }
                onChange={(e) => {
                  handleChangePayload("tenderness_x_value", e.target.checked ? "Yes" : "");
                }}
              />
              <ECheckBox
                label="No"
                checked={
                  payloadForGBCClinicalPresentation?.tenderness_x_value == "No"
                }
                onChange={(e) => {
                  handleChangePayload("tenderness_x_value", e.target.checked ? "No" : "");
                }}
              />
            </div>
            {payloadForGBCClinicalPresentation?.tenderness_x_value == "Yes" ? (
              <ETextInput
                value={payloadForGBCClinicalPresentation?.tenderness_desc}
                onChange={(e) => {
                  handleChangePayload("tenderness_desc", e.target.value);
                }}
                placeholder="Duration/ Description"
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </SecondaryPageLayout>
  );
}
