import { useContext, useState } from "react";

import { IDecodedToken, ILoginParams } from "../../../types";
import { AppContext } from "../../../AppContext";
import { showToast } from "./../../../utils/utility";
import axios from "axios";
import { API } from "../../../libs/data-access/constant";
import { useDirectusApi } from "../../../libs/data-access";
import { decodeToken } from "react-jwt";

export default function useAuthentication() {
  const { setRole, setToken, setIsLoggedIn, handleUser, handleUserDetails } =
    useContext(AppContext);

  const { fetchDirectData } = useDirectusApi();
  const [loginParams, setLoginParams] = useState<ILoginParams>({
    email: "",
    password: "",
  });

  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  /* HANDLER FUNCTIONS */
  const handleLoginPayloadChange = (key: keyof ILoginParams, value: string) => {
    setLoginParams((prev) => ({ ...prev, [key]: value }));
  };

  /* API CALLS */
  const handleUserLogin = async () => {
    setIsLoggingIn(true);
    const payload: ILoginParams = {
      email: loginParams.email.trim(),
      password: loginParams.password.split("/").join(""),
    };
    axios
      .post(`${API}auth/login`, payload)
      .then((response) => {
        const decodedToken: IDecodedToken = decodeToken(
          response?.data?.data?.access_token
        ) as IDecodedToken;
        // setRole(["Admin"]);
        // setRole([ADMIN_ROLE_ID === decodedToken?.role ? "Admin" : "Center"]);
        setToken(response?.data?.data?.access_token);
        localStorage.setItem("token", response?.data?.data?.access_token);
        getUserDetails();
        // window.location.reload() reload the page for handle sub route
      })
      .catch((err) => {
        showToast({
          type: "error",
          message: "Invalid user and password",
          title: "Something went wrong",
        });
        setIsLoggingIn(false);
      });
  };

  const getUserDetails = async () => {
    const res = await fetchDirectData(
      `users/me?fields=id,first_name,last_name,email,role.id,role.name,physician_in_charge,surgeon,radiologist`
    );
    if (res.isSuccess) {
      setRole([res?.data?.role?.name]);
      handleUser(res?.data);
      setIsLoggedIn(true);
    } else {
      showToast({
        type: "error",
        message: res?.message || "",
        title: "Something went wrong",
      });
      setIsLoggingIn(false);
    }
  };

  return {
    loginParams,
    isLoggingIn,
    handleLoginPayloadChange,
    handleUserLogin,
  };
}
