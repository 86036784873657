import { Checkbox, Grid, Table } from "@mantine/core";
import {
  EButton,
  EModal,
  ENumberInput,
  ESectionDivider,
  ESelect,
  EMultiSelect,
  ELabelView,
  ECheckBox,
} from "../../../../../libs/ui";
import { BsPlus } from "react-icons/bs";
import useClinicalHistoryForm from "../hooks/useClinicalHistoryForm";
import {
  alcoholMaster,
  familyRelativeMember,
} from "../../../../../libs/data-access/utility";
import { ETextInput } from "../../../../../libs/ui/src/TextInput/ETextInput";
import EAgeInput from "../../../../../libs/ui/src/NumberInput/EAgeInput";
import { truncate } from "fs";
import { useEffect } from "react";
import { FaEdit } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { EMultiSelectToString } from "../../../../../libs/ui/src/MultiSelect/EMultiSelectToString";

export default function ClinicalHistoryForm() {
  const {
    comorbidities,
    setComorbidities,
    drugsHistory,
    setDrugsHistory,
    isOpenModalCancerMember,
    setIsOpenModalCancerMember,
    isOpenModalGsMember,
    setIsOpenModalGsMember,
    newGsMember,
    handleChangeGsMember,
    newCancerMember,
    handleChangeCancerMember,
    newClinicalHistory,
    handleChangeClinicalHistory,
    industries,
    selectedFamilyHistoryCancerIndex,
    setSelectedFamilyHistoryCancerIndex,
    selectedFamilyHistoryGsIndex,
    setSelectedFamilyHistoryGsIndex
  } = useClinicalHistoryForm();

  return (
    <>
      <h3 className="mb-5 sticky">Clinical History</h3>

      <ESectionDivider title="Comorbidities" />
      <div className="flex flex-wrap">
        {comorbidities?.map((each, index) => (
          <Checkbox
            className="w-1/5 mt-2"
            size="md"
            checked={each?.value}
            label={each?.label}
            onChange={(e) => {
              if (each?.label == "Others") {
                const updatedComorbidities = [...comorbidities];
                updatedComorbidities[index].value =
                  !updatedComorbidities[index].value;
                updatedComorbidities[index].name = "";
                updatedComorbidities[comorbidities?.length - 2].value = false;
                setComorbidities(updatedComorbidities);
              } else if (each?.label == "None") {
                const updatedComorbidities = comorbidities?.map((e) => {
                  if (e?.label == "None") {
                    return {
                      label: e?.label,
                      value: true,
                      name: e?.name,
                    };
                  } else {
                    return {
                      label: e?.label,
                      value: false,
                      name: e?.name,
                    };
                  }
                });
                setComorbidities(updatedComorbidities);
              } else {
                const updatedComorbidities = [...comorbidities];
                updatedComorbidities[index].value =
                  !updatedComorbidities[index].value;
                updatedComorbidities[comorbidities?.length - 2].value = false;
                setComorbidities(updatedComorbidities);
              }
            }}
          />
        ))}
        {comorbidities?.find((e) => e?.label == "Others")?.value ? (
          <ETextInput
            placeholder="Enter others"
            className="mt-2"
            value={comorbidities?.find((e) => e?.label == "Others")?.name || ""}
            onChange={(event) => {
              const updatedComorbidities = [...comorbidities];
              const _index = comorbidities.findIndex(
                (e) => e?.label == "Others"
              );
              updatedComorbidities[_index].name = event?.target.value;
              setComorbidities(updatedComorbidities);
            }}
          />
        ) : (
          <></>
        )}
      </div>

      <ESectionDivider title="Drug History" />
      <div>
        <div className="flex flex-wrap">
          {drugsHistory.map((each, index) => (
            <div className="flex items-end justify-between px-3 w-1/2 gap-3">
              <Checkbox
                size="md"
                className="mt-4"
                checked={each?.value}
                label={each?.label}
                onChange={(e) => {
                  let updatedDrugHistory = [...drugsHistory];
                  let NoneIndex = updatedDrugHistory.findIndex(
                    (e) => e?.label == "None"
                  );
                  if (each?.label == "None") {
                    const newData = updatedDrugHistory;
                    newData.forEach((e, index) => {
                      updatedDrugHistory[index] = {
                        label: e?.label,
                        value: false,
                        name: "",
                      }
                    });
                    newData[NoneIndex].value = true;
                    setDrugsHistory(newData);
                  } else {
                    updatedDrugHistory[index] = {
                      label: each?.label,
                      value: !updatedDrugHistory[index].value,
                      name: "",
                    };
                    updatedDrugHistory[NoneIndex].value = false;
                    setDrugsHistory(updatedDrugHistory);
                  }
                }}
              />
              {each?.value && each?.label == "Others" ? (
                <ETextInput
                  size="xs"
                  placeholder="Enter others"
                  value={each?.name ?? ""}
                  onChange={(event) => {
                    let updatedDrugHistory = [...drugsHistory];
                    updatedDrugHistory[index].name = event?.target.value;
                    setDrugsHistory(updatedDrugHistory);
                  }}
                />
              ) : (
                <></>
              )}
              {each?.value && !(each?.label == "None") ? (
                <ESelect
                  size="xs"
                  className="pt-1"
                  data={["Never", "Former", "Current"]}
                  value={each?.nfc ?? ""}
                  onChange={(e: any) => {
                    const updatedDrugHistory = [...drugsHistory];
                    updatedDrugHistory[index].nfc = e || "Never";
                    setDrugsHistory(updatedDrugHistory);
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </div>

      <ESectionDivider title="Typhoid History" />

      <div className="flex flex-row gap-5 mb-4">
        <ECheckBox
          size="md"
          checked={newClinicalHistory?.typhoid[0]?.typhoid_history == "Yes"}
          label={"Yes"}
          onChange={(e) => {
            handleChangeClinicalHistory("typhoid", e?.target?.checked ? "Yes" : "", "typhoid_history")
          }}
        />
        <ECheckBox
          size="md"
          checked={newClinicalHistory?.typhoid[0]?.typhoid_history == "No"}
          label={"No"}
          onChange={(e) => {
            handleChangeClinicalHistory("typhoid", e?.target?.checked ? "No" : "", "typhoid_history")
          }}
        />
      </div>

      <Grid>
        <Grid.Col span={2}>
          <ENumberInput
            size="md"
            label="How many years back"
            min={0}
            maxLength={3}
            placeholder="Enter years"
            value={
              newClinicalHistory?.typhoid
                ? newClinicalHistory?.typhoid[0]?.years_back_count
                : ""
            }
            onChange={(e) =>
              handleChangeClinicalHistory("typhoid", e ?? 0, "years_back_count")
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <ESelect
            size="md"
            label="Bases of Diagnosis"
            placeholder="Select"
            data={[
              "Culture Positive",
              "WIDAL",
              // "Clinical",
              "Clinical Diagnosis",
            ]}
            value={
              newClinicalHistory?.typhoid
                ? newClinicalHistory?.typhoid[0]?.basis_of_diagnosis
                : null
            }
            onChange={(e) =>
              handleChangeClinicalHistory(
                "typhoid",
                e ?? "",
                "basis_of_diagnosis"
              )
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <ETextInput
            size="md"
            label="Treatment Received"
            placeholder="Enter treatment received"
            value={
              newClinicalHistory?.typhoid
                ? newClinicalHistory?.typhoid[0]?.treatment_received
                : ""
            }
            onChange={(e) =>
              handleChangeClinicalHistory(
                "typhoid",
                e?.target?.value,
                "treatment_received"
              )
            }
          />
        </Grid.Col>
      </Grid>

      <ESectionDivider title="Smoking" />
      <>
        <div className="grid grid-cols-4 gap-3 mb-2">
          {[
            { label: "Never", desc: "(<100 sticks over lifetime)" },
            {
              label: "Former",
              desc: "(>100 sticks over lifetime, not currently smoking)",
            },
            {
              label: "Current",
              desc: "(>100 sticks over lifetime, currently smoking)",
            },
          ].map((each) => (
            <Checkbox
              size="md"
              checked={
                newClinicalHistory.smoking
                  ? newClinicalHistory?.smoking[0]?.nfc_status == each?.label
                  : false
              }
              label={each?.label}
              description={each?.desc}
              onChange={(e) =>
                handleChangeClinicalHistory(
                  "smoking",
                   e?.target?.checked ? each?.label : "",
                  "nfc_status"
                )
              }
            />
          ))}
        </div>

        <div className="grid grid-cols-4 gap-3 my-2">
          <Checkbox
            size="md"
            checked={newClinicalHistory?.smoking[0]?.is_regular === "Regular"}
            label={"Regular"}
            onChange={(e) => {
              handleChangeClinicalHistory("smoking", e.target.checked ?"Regular" : "", "is_regular");
            }}
          />

          <Checkbox
            size="md"
            checked={
              newClinicalHistory?.smoking[0]?.is_regular === "Occasional"
            }
            label={"Occasional"}
            onChange={(e) => {
              handleChangeClinicalHistory(
                "smoking",
                e.target.checked ? "Occasional" : "",
                "is_regular"
              );
            }}
          />
        </div>

        <Grid>
          <Grid.Col span={4}>
            <ENumberInput
              size="md"
              label="Duration (years)"
              min={0}
              maxLength={3}
              placeholder="Enter year"
              value={
                newClinicalHistory.smoking
                  ? newClinicalHistory?.smoking[0]?.duration_in_years
                  : ""
              }
              onChange={(e) =>
                handleChangeClinicalHistory(
                  "smoking",
                  e ?? 0,
                  "duration_in_years"
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <ETextInput
              size="md"
              label="Amount per Day (sticks / gram)"
              maxLength={25}
              min={0}
              placeholder="Count of stick"
              value={
                newClinicalHistory.smoking
                  ? newClinicalHistory?.smoking[0]?.amount_per_day
                  : ""
              }
              onChange={(e) =>
                handleChangeClinicalHistory("smoking", e.target.value ?? "", "amount_per_day")
              }
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <EMultiSelect
              label="Type of Smoking"
              placeholder="Select"
              size="md"
              data={[
                "Bidi",
                "Hukka",
                "Cigarettes",
                "Chelum",
                "Tobacco Chewing",
                "Cigar",
              ]}
              value={
                newClinicalHistory.smoking
                  ? newClinicalHistory?.smoking[0]?.type_of_smoke?.length ?
                    newClinicalHistory?.smoking[0]?.type_of_smoke?.split(",")
                    : []
                  : []
              }
              onChange={(e) =>
                handleChangeClinicalHistory("smoking", e?.length ? e?.join(", ") : "", "type_of_smoke")
              }
            />
          </Grid.Col>
        </Grid>
      </>

      <ESectionDivider title="Alcohol" />
      <>
        <div className="grid grid-cols-4 gap-3 mb-2">
          {[
            { label: "Never", desc: "(<100 sticks over lifetime)" },
            {
              label: "Former",
              desc: "(>100 sticks over lifetime, not currently smoking)",
            },
            {
              label: "Current",
              desc: "(>100 sticks over lifetime, currently smoking)",
            },
          ].map((each) => (
            <Checkbox
              size="md"
              checked={
                newClinicalHistory.alcohol
                  ? newClinicalHistory?.alcohol[0]?.nfc_status == each?.label
                    ? true
                    : false
                  : false
              }
              label={each?.label}
              // description={each?.desc}
              onChange={(e) =>
                handleChangeClinicalHistory(
                  "alcohol",
                  e?.target.checked ? each?.label : "",
                  "nfc_status"
                )
              }
            />
          ))}
        </div>
        <div className="grid grid-cols-4 gap-3 my-2">
          <Checkbox
            size="md"
            checked={newClinicalHistory.alcohol[0]?.is_regular === "Regular"}
            label={"Regular"}
            onChange={(e) =>
              handleChangeClinicalHistory("alcohol", e?.target.checked ? "Regular" : "", "is_regular")
            }
          />
          <Checkbox
            size="md"
            checked={newClinicalHistory.alcohol[0]?.is_regular === "Occasional"}
            label={"Occasional"}
            onChange={(e) =>
              handleChangeClinicalHistory("alcohol", e?.target.checked ? "Occasional" : "", "is_regular")
            }
          />

          {/* {["Regular", "Occasional"].map((each) => (
            <Checkbox
              size="md"
              checked={
                newClinicalHistory.alcohol
                  ? each === "Regular"
                    ? newClinicalHistory?.alcohol[0]?.is_regular
                    : !newClinicalHistory?.alcohol[0]?.is_regular
                  : false
              }
              label={each}
              onChange={(e) =>
                handleChangeClinicalHistory(
                  "alcohol",
                  each === "Regular" ? true : false,
                  "is_regular"
                )
              }
            />
          ))} */}
        </div>
        <Grid>
          <Grid.Col span={3}>
            <ENumberInput
              size="md"
              label="Duration (years)"
              min={0}
              maxLength={3}
              placeholder="Enter year"
              value={
                newClinicalHistory.alcohol
                  ? newClinicalHistory?.alcohol[0]?.duration_in_years
                  : ""
              }
              onChange={(e) =>
                handleChangeClinicalHistory(
                  "alcohol",
                  e ?? 0,
                  "duration_in_years"
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <ENumberInput
              size="md"
              label="Amount per Day (ml)"
              min={0}
              maxLength={5}
              placeholder="eg (250 ml)"
              value={
                newClinicalHistory.alcohol
                  ? newClinicalHistory?.alcohol[0]?.amount_per_day
                  : ""
              }
              onChange={(e) =>
                handleChangeClinicalHistory("alcohol", e ?? 0, "amount_per_day")
              }
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <ENumberInput
              size="md"
              label="No. of times a week"
              min={0}
              maxLength={5}
              placeholder="Enter no. of times"
              value={
                newClinicalHistory.alcohol
                  ? newClinicalHistory?.alcohol[0]?.no_of_times_a_week
                  : ""
              }
              onChange={(e) =>
                handleChangeClinicalHistory(
                  "alcohol",
                  e ?? 0,
                  "no_of_times_a_week"
                )
              }
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <EMultiSelect
              label="Alcohol Type"
              placeholder="Select Alcohol Type"
              required
              size="md"
              data={alcoholMaster}
              value={
                newClinicalHistory?.alcohol
                  ? newClinicalHistory?.alcohol[0]?.type_of_alcohol?.split(",")
                  : []
              }
              onChange={(e) =>
                handleChangeClinicalHistory("alcohol", e?.join(","), "type_of_alcohol")
              }
            />

          </Grid.Col>
        </Grid>
      </>

      <ESectionDivider title="Exposure to industry" />
      <div className="flex flex-wrap items-end">
        {industries?.map((each, index) => (
          <Checkbox
            size="md"
            className="w-1/6 mb-2"
            checked={
              newClinicalHistory?.exposure_to_industry?.includes(each?.label) ??
              false
            }
            label={each?.label}
            onChange={(event) => {
              if (event.target.checked) {
                if (each?.label === "None") {
                  handleChangeClinicalHistory("exposure_to_industry", "None");
                } else if (each?.label === "Others") {
                  const UpdatedIndustries =
                    newClinicalHistory?.exposure_to_industry?.split(",");
                  handleChangeClinicalHistory(
                    "exposure_to_industry",
                    UpdatedIndustries?.filter((e) => e?.trim() != "None")?.join(
                      ","
                    ) + ",Others-"
                  );
                } else {
                  const UpdatedIndustries =
                    newClinicalHistory?.exposure_to_industry?.split(",");
                  handleChangeClinicalHistory(
                    "exposure_to_industry",
                    UpdatedIndustries?.filter((e) => e?.trim() != "None") +
                    "," +
                    each?.label
                  );
                }
              } else {
                const UpdatedRecruitment =
                  newClinicalHistory?.exposure_to_industry
                    ?.split(",")
                    .filter((e) => !e?.includes(each?.label));
                handleChangeClinicalHistory(
                  "exposure_to_industry",
                  UpdatedRecruitment?.join(",")
                );
              }
            }}
          />
        ))}
        {newClinicalHistory?.exposure_to_industry?.includes("Others") ? (
          <ETextInput
            placeholder="Enter others"
            className="mt-2 w-1/6"
            value={
              newClinicalHistory?.exposure_to_industry
                ?.split(",")
                ?.filter((e) => e?.includes("Others-"))[0]
                ?.split("Others-")[1] ?? ""
            }
            onChange={(event) => {
              const UpdatedRecruitment =
                newClinicalHistory?.exposure_to_industry?.split(",");
              const index = UpdatedRecruitment?.findIndex((e) =>
                e?.includes("Others-")
              );
              UpdatedRecruitment?.splice(
                index,
                1,
                "Others-" + event?.target?.value
              );
              handleChangeClinicalHistory(
                "exposure_to_industry",
                UpdatedRecruitment?.join(",")
              );
            }}
          />
        ) : (
          <></>
        )}
      </div>

      <ESectionDivider title="Pesticide use" />
      <ESelect
        label="NFC Status"
        className="w-1/3 pl-0 p-2"
        size="md"
        data={["Never", "Former", "Current"]}
        value={
          newClinicalHistory?.pesticide_use
            ? newClinicalHistory?.pesticide_use[0]?.nfc_status
            : ""
        }
        onChange={(e) => handleChangeClinicalHistory("nfc_status", e ?? "")}
      />
      <Grid>
        <Grid.Col span={4}>
          <ENumberInput
            label="For How Long (Years)"
            size="md"
            maxLength={3}
            placeholder="Enter years"
            value={
              newClinicalHistory?.pesticide_use
                ? newClinicalHistory?.pesticide_use[0]?.for_how_long
                : ""
            }
            onChange={(e) =>
              handleChangeClinicalHistory(
                "pesticide_use_for_how_long",
                String(e)
              )
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <ETextInput
            label="Which One"
            size="md"
            placeholder="Enter pesticide name"
            value={
              newClinicalHistory?.pesticide_use
                ? newClinicalHistory?.pesticide_use[0]?.which_one
                : ""
            }
            onChange={(e) =>
              handleChangeClinicalHistory(
                "pesticide_use_which_one",
                e?.target?.value
              )
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <EMultiSelect
            label="Personal protective gear used"
            placeholder={
              newClinicalHistory?.pesticide_use
                ? newClinicalHistory?.pesticide_use[0]?.personal_protective_gear_used
                  ? ""
                  : "Select gear"
                : "Select gear"
            }
            size="md"
            data={["Gloves", "Mask", "Apron", "All", "None"]}
            value={
              newClinicalHistory?.pesticide_use
                ? newClinicalHistory?.pesticide_use[0]?.personal_protective_gear_used
                  ? newClinicalHistory?.pesticide_use[0]?.personal_protective_gear_used.split(",")
                  : []
                : []
            }
            onChange={(e) => {
              if (e[e?.length - 1] === "None") {
                handleChangeClinicalHistory("pesticide_use_gear_used", "None");
              } else if (e?.includes("None")) {
                let filteredData = e?.filter((item) => item !== "None");
                handleChangeClinicalHistory("pesticide_use_gear_used", filteredData?.join(","));
              } else {
                handleChangeClinicalHistory("pesticide_use_gear_used", e?.join(","));
              }
            }}
          />

        </Grid.Col>
      </Grid>

      <ESectionDivider title="Total no. of family members" />

      <div className="grid grid-cols-4 gap-4">
        <ENumberInput
          label="Total no. of Brothers"
          placeholder="Enter no. of counts"
          size="md"
          maxLength={5}
          value={newClinicalHistory?.no_of_brothers}
          onChange={(e) => handleChangeClinicalHistory("no_of_brothers", e)}
        />
        <ENumberInput
          label="Total no. of Sisters"
          placeholder="Enter no. of counts"
          size="md"
          maxLength={5}
          value={newClinicalHistory?.no_of_sisters}
          onChange={(e) => handleChangeClinicalHistory("no_of_sisters", e)}
        />
        <ENumberInput
          label="Total no. of Sons"
          placeholder="Enter no. of counts"
          size="md"
          maxLength={5}
          value={newClinicalHistory?.no_of_sons}
          onChange={(e) => handleChangeClinicalHistory("no_of_sons", e)}
        />
        <ENumberInput
          label="Total no. of Daughters"
          placeholder="Enter no. of counts"
          size="md"
          maxLength={3}
          value={newClinicalHistory?.no_of_daughters}
          onChange={(e) => handleChangeClinicalHistory("no_of_daughters", e)}
        />
      </div>

      <ESectionDivider
        title="Family History of GS"
        end={
          <EButton
            className="btn-blue"
            onClick={() => setIsOpenModalGsMember(true)}
          >
            Add Member
          </EButton>
        }
      />
      {!newClinicalHistory?.family_history?.length ? (
        <></>
      ) : (
        <div className="mt-8 p-2 ">
          <Table className="e-table">
            <thead>
              <tr>
                <th>First degree relative</th>
                {/* <th>Gall-Stone</th> */}
                <th>Age</th>
                <th>Complication</th>
                <th>Symptomatic / Asymptomatic</th>
                <th>Was Surgery done</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {newClinicalHistory?.family_history?.map((each, index) => (
                selectedFamilyHistoryGsIndex === index
                  ?
                  <tr>
                    <td>
                      <ESelect
                        // size="md"
                        className="mt-2"
                        // label="First degree relative"
                        placeholder="Select relative"
                        data={familyRelativeMember}
                        value={each?.first_degree_relative}
                        onChange={(e) =>
                          handleChangeClinicalHistory("family_history", e ?? "", "first_degree_relative", index)
                        }
                      />
                    </td>
                    <td>
                      <EAgeInput
                        // size="md"
                        // label="Age at Gallstone(years)"
                        className="mt-2"
                        maxLength={3}
                        min={0}
                        placeholder="Age"
                        value={each?.at_what_age}
                        onChange={(e) =>
                          handleChangeClinicalHistory("family_history", e ?? "", "at_what_age", index)
                        }
                      />
                    </td>
                    <td>
                      <ETextInput
                        // size="md"
                        className="mt-2"
                        // label="Complication"
                        placeholder="Complication"
                        value={each?.complication}
                        onChange={(e) =>
                          handleChangeClinicalHistory("family_history", e?.target.value, "complication", index)
                        }
                      />
                    </td>
                    <td>
                      <ESelect
                        className="mt-2"
                        data={["Symptomatic", "Asymptomatic"]}
                        value={each?.is_symptomatic}
                        onChange={(e) => handleChangeClinicalHistory("family_history", e ?? "", "is_symptomatic", index)
                        }
                      />
                    </td>
                    <td>
                      <ESelect
                        className="mt-2"
                        data={["Yes", "No"]}
                        value={each?.was_surgery_done ? "Yes" : "No"}
                        onChange={(e) => handleChangeClinicalHistory("family_history", e === "Yes" ? true : false, "was_surgery_done", index)}
                      />
                    </td>
                    <td>
                      <div
                        className="text-red-600 cursor-pointer p-2 flex flex-1 items-center justify-center"
                        onClick={() => setSelectedFamilyHistoryGsIndex(undefined)}
                      >
                        <MdClose size={24} />
                      </div>
                    </td>
                  </tr>
                  :
                  <tr className="text-center">
                    <td>{each?.first_degree_relative}</td>
                    {/* <td>{each?.gs}</td> */}
                    <td>{each?.at_what_age}</td>
                    <td>{each?.complication ?? "-"}</td>
                    <td>{each?.is_symptomatic ? "Symptomatic" : "a-Symptomatic"}</td>
                    <td>{each?.was_surgery_done ? "YES" : "No"}</td>
                    <td>
                      <div
                        className="text-green-600 cursor-pointer p-2 flex flex-1 items-center justify-center"
                        onClick={() => setSelectedFamilyHistoryGsIndex(index)}
                      >
                        <FaEdit size={18} />
                      </div>
                    </td>
                  </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      <ESectionDivider
        title="Family History of Cancer"
        end={
          <EButton
            className="btn-blue"
            onClick={() => setIsOpenModalCancerMember(true)}
          >
            Add Member
          </EButton>
        }
      />
      {!newClinicalHistory?.family_history_of_cancer?.length ? (
        <></>
      ) : (
        <div className="mt-8 p-2 ">
          <Table className="e-table">
            <thead>
              <tr>
                <th>First degree relative</th>
                <th>Age at diagnosis (years)</th>
                <th>type of cancer</th>
                <th>Stage of cancer</th>
                <th>Treatment Received</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {newClinicalHistory?.family_history_of_cancer?.map((each, index) => (
                selectedFamilyHistoryCancerIndex === index ?
                  <tr>
                    <td>
                      <ESelect
                          className="mt-2"
                        // label="First degree relative"
                        placeholder="Select relative"
                        data={familyRelativeMember}
                        value={each?.first_degree_relative}
                        onChange={(e) =>
                          handleChangeClinicalHistory("family_history_of_cancer", e ?? "", "first_degree_relative", index)
                        }
                      />
                    </td>
                    <td>
                      <EAgeInput
                        // label="Age at diagnosis (years)"
                        min={0}
                        className="mt-2"
                        placeholder="Age"
                        value={each?.age_at_diagnosis}
                        onChange={(e) =>
                          handleChangeClinicalHistory("family_history_of_cancer", e ?? "", "age_at_diagnosis", index)
                        }
                      />
                    </td>
                    <td>
                      <ETextInput
                        className="mt-2"
                        // label="Type of cancer"
                        placeholder="Type of cancer"
                        value={each?.type_of_cancer}
                        onChange={(e) =>                         
                           handleChangeClinicalHistory("family_history_of_cancer", e.target.value ?? "", "type_of_cancer", index)
                        }
                      />
                    </td>
                    <td>
                      <ETextInput
                        className="mt-2"
                        // label="Stage of cancer"
                        placeholder="Stage of cancer"
                        value={each?.stage_of_cancer}
                        onChange={(e) =>
                          handleChangeClinicalHistory("family_history_of_cancer", e.target.value ?? "", "stage_of_cancer", index)
                        }
                      />

                    </td>
                    <td>
                      <EMultiSelectToString
                        className="mt-2"
                        // label="Treatment Received"
                        placeholder="Treatment Received"
                        data={["Chemotherapy", "Radiotherapy", "Surgery", "No Treatment"]}
                        value={each?.treatment_received}
                        onChange={(e) =>
                          handleChangeClinicalHistory("family_history_of_cancer", e ?? "", "treatment_received", index)

                        }
                      />
                    </td>
                    <td>
                      <div
                        className="text-red-600 cursor-pointer p-2 flex flex-1 items-center justify-center"
                        onClick={() => setSelectedFamilyHistoryCancerIndex(undefined)}
                      >
                        <MdClose size={24} />
                      </div>
                    </td>
                  </tr> :
                  <tr className="text-center">
                    <td>{each?.first_degree_relative}</td>
                    <td>{each?.age_at_diagnosis}</td>
                    <td>{each?.type_of_cancer}</td>
                    <td>{each?.stage_of_cancer}</td>
                    <td>{each?.treatment_received?.replaceAll(",",", ")}</td>
                    <td>
                      <div
                        className="text-green-600 cursor-pointer p-2 flex flex-1 items-center justify-center"
                        onClick={() => setSelectedFamilyHistoryCancerIndex(index)}
                      >
                        <FaEdit size={18} />
                      </div>
                    </td>
                  </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      <ESectionDivider title=" " />
      <div className="flex flex-wrap justify-between px-2">
        <div className="flex gap-3 items-end ">
          <p className="text-slate-400">Family history of GBC : </p>
          <div className="flex gap-1">
            <Checkbox
              checked={newClinicalHistory?.is_family_history_of_gbc}
              name="FamilyHistoryOfGBC"
              label="Yes"
              onChange={(e) =>
                handleChangeClinicalHistory("is_family_history_of_gbc", e.target.checked ?  true : null)
              }
            />
            <Checkbox
              checked={newClinicalHistory?.is_family_history_of_gbc === false}
              name="FamilyHistoryOfGBC"
              label="No"
              onChange={(e) =>
                handleChangeClinicalHistory("is_family_history_of_gbc", e.target.checked ? false : null)
              }
            />
          </div>
        </div>
        <div className="flex gap-3 items-end">
          <p className="text-slate-400">Family history of Liver Cancer : </p>
          <div className="flex gap-1">
            <Checkbox
              checked={newClinicalHistory?.is_family_history_of_liver_cancer}
              name="liverCancer"
              label="Yes"
              onChange={(e) =>
                handleChangeClinicalHistory(
                  "is_family_history_of_liver_cancer",
                  e.target.checked ? true  :null
                )
              }
            />
            <Checkbox
              checked={newClinicalHistory?.is_family_history_of_liver_cancer === false}
              name="liverCancer"
              label="No"
              onChange={(e) =>
                handleChangeClinicalHistory(
                  "is_family_history_of_liver_cancer",
                  e?.target?.checked ? false : null
                )
              }
            />
          </div>
        </div>
        <div className="flex gap-3 items-end">
          <p className="text-slate-400">Family history of Liver Cirrhosis : </p>
          <div className="flex gap-1">
            <Checkbox
              checked={newClinicalHistory?.is_family_history_of_liver_cirrhosis}
              name="liverCirrhosis"
              label="Yes"
              onChange={(e) =>
                handleChangeClinicalHistory(
                  "is_family_history_of_liver_cirrhosis",
                  e?.target.checked ? true : null
                )
              }
            />
            <Checkbox
              checked={
                newClinicalHistory?.is_family_history_of_liver_cirrhosis === false
              }
              name="liverCirrhosis"
              label="No"
              onChange={(e) =>
                handleChangeClinicalHistory(
                  "is_family_history_of_liver_cirrhosis",
                  e?.target.checked ? false : null
                )
              }
            />
          </div>
        </div>
      </div>

      {/* Add GS Family Member Modal */}
      <EModal
        isOpen={isOpenModalGsMember}
        title="Family History of GS"
        size="xl"
        setIsOpen={() => {
          handleChangeGsMember("default", "");
          setIsOpenModalGsMember(false);
        }}
      >
        <div className="flex justify-between mb-2 items-end">
          <Checkbox
            size="sm"
            label="Was surgery done"
            checked={newGsMember?.was_surgery_done}
            onChange={(e) =>
              handleChangeGsMember("was_surgery_done", e.target.checked)
            }
          />
          <div className="flex justify-end gap-4 items-end">
            <Checkbox
              // size="md"
              label="Symptomatic"
              name="Symptomatic"
              checked={newGsMember?.is_symptomatic === "symptomatic"}
              onChange={(e) =>
                
                handleChangeGsMember("is_symptomatic", e?.target.checked ? "symptomatic" : "")
              }
            />
            <Checkbox
              // size="md"
              name="Symptomatic"
              label="Asymptomatic"
              checked={newGsMember?.is_symptomatic === "asymptomatic"}
              onChange={(e) =>
                handleChangeGsMember("is_symptomatic", e?.target.checked ? "asymptomatic" : "")
              }
            />
          </div>
        </div>
        <Grid>
          <Grid.Col span={6}>
            <ESelect
              size="md"
              className="mt-2"
              label="First degree relative"
              placeholder="Select relative"
              data={familyRelativeMember}
              value={newGsMember?.first_degree_relative}
              onChange={(e) =>
                handleChangeGsMember("first_degree_relative", e ?? "")
              }
            />
            <EAgeInput
              size="md"
              label="Age at Gallstone(years)"
              className="mt-2"
              maxLength={3}
              min={0}
              placeholder="Age"
              value={newGsMember?.at_what_age}
              onChange={(e) =>
               handleChangeGsMember("at_what_age", e)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            {/* <ETextInput
              size="md"
              label="Gall Stone"
              className="mt-2"
              placeholder="Gs"
              value={newGsMember?.gs}
              onChange={(e) => handleChangeGsMember("gs", e?.target?.value)}
            /> */}
            <ETextInput
              size="md"
              className="mt-2"
              label="Complication"
              placeholder="Complication"
              value={newGsMember?.complication}
              onChange={(e) =>
                handleChangeGsMember("complication", e?.target?.value)
              }
            />
          </Grid.Col>
        </Grid>
        <div className="flex justify-end mt-5 p-2">
          <EButton
            className="btn-blue"
            onClick={() => {
              if (newClinicalHistory?.family_history) {
                handleChangeClinicalHistory("family_history", [
                  ...newClinicalHistory?.family_history,
                  newGsMember,
                ]);
              } else {
                handleChangeClinicalHistory("family_history", [newGsMember]);
              }
              setIsOpenModalGsMember(false);
              handleChangeGsMember("default", "");
            }}
          >
            <BsPlus className="mr-1" size={20} /> Add
          </EButton>
        </div>
      </EModal>

      {/* Add Cancer Member Modal */}
      <EModal
        isOpen={isOpenModalCancerMember}
        title="Family History of Cancer"
        size="xl"
        setIsOpen={() => {
          handleChangeCancerMember("default", "");
          setIsOpenModalCancerMember(false);
        }}
      >
        <Grid>
          <Grid.Col span={6}>
            <ESelect
              size="md"
              //   className="mt-2"
              label="First degree relative"
              placeholder="Select relative"
              data={familyRelativeMember}
              value={newCancerMember?.first_degree_relative}
              onChange={(e) =>
                handleChangeCancerMember("first_degree_relative", e ?? "")
              }
            />
            <ETextInput
              size="md"
              className="mt-2"
              label="Type of cancer"
              placeholder="Type of cancer"
              value={newCancerMember?.type_of_cancer}
              onChange={(e) =>
                handleChangeCancerMember("type_of_cancer", e?.target?.value)
              }
            />
            <ETextInput
              size="md"
              className="mt-2"
              label="Stage of cancer"
              placeholder="Stage of cancer"
              value={newCancerMember?.stage_of_cancer}
              onChange={(e) =>
                handleChangeCancerMember("stage_of_cancer", e?.target?.value)
              }
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <EAgeInput
              size="md"
              label="Age at diagnosis (years)"
              min={0}
              placeholder="Age"
              value={newCancerMember?.age_at_diagnosis}
              onChange={(e) =>handleChangeCancerMember("age_at_diagnosis", e)
              }
            />
            <EMultiSelectToString
              size="md"
              className="mt-2"
              label="Treatment Received"
              placeholder="Treatment Received"
              data={["Chemotherapy", "Radiotherapy", "Surgery", "No Treatment"]}
              value={
                newCancerMember?.treatment_received
              }
              onChange={(e) =>
                handleChangeCancerMember("treatment_received", e)
              }
            />
          </Grid.Col>
        </Grid>
        <div className="flex justify-end mt-5 p-2">
          <EButton
            className="btn-blue"
            onClick={() => {
              if (newClinicalHistory?.family_history_of_cancer) {
                handleChangeClinicalHistory("family_history_of_cancer", [
                  ...newClinicalHistory?.family_history_of_cancer,
                  newCancerMember,
                ]);
              } else {
                handleChangeClinicalHistory("family_history_of_cancer", [
                  newCancerMember,
                ]);
              }
              setIsOpenModalCancerMember(false);
              handleChangeCancerMember("default", "");
            }}
          >
            <BsPlus className="mr-1" size={20} /> Add
          </EButton>
        </div>
      </EModal>
    </>
  );
}
